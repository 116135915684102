import React, { useState } from 'react';
import ReactTooltip from "react-tooltip"
import { FaQuestionCircle } from "react-icons/fa";

// styles
import { WrapForm, WrapPromoValue } from './style';

// components
import Input from '~/components/form/Input';
import Textarea from '~/components/form/Textarea';
import EditorWYSIWYG from '~/components/form/EditorWYSIWYG';
import Label from '~/components/form/Label';
import MsgResponse from '~/components/MsgResponse';
import SubLabel from '~/components/form/SubLabel';
import Button from '~/components/form/Button';
import Video from '~/components/Video';
import InputFileVideo from '~/components/form/InputFileVideo'
import ButtonDelete from '~/components/form/ButtonDelete'
import ButtonNext from '~/components/form/ButtonNext'
import LightboxAlertConfirm from '~/components/LightboxAlertConfirm'

// confs
import { BASE_ALIAS_TEACHER } from '~/services/conf';
import InputCheckbox from '~/components/form/InputCheckbox';
import { useGet } from '~/services/rest';

const FormCourse = (props) => {

  const {
    handleFormFill,
    handleChangeCurrency,
    handleChangeCategoria,
    handleSend,
    handleDeleteVideo,
    handleDeleteCourse,
    deleteVideoStatus,
    data,
    dataCategorias,
    refetchData,
    idCourse,
    error,
    success,
    type
  } = props

  // pega categorias do segmento
  const categorias = useGet(`categorias/segmentos/${data.segmentos_id}/true`) // true para nao listar categ default

  // Alert Confirm config
  const [alertConfirm, setAlertConfirm] = useState(false)
  const msgAlertConfirm = 'Deseja realmente excluir este curso? Esta ação é irreversível e irá excluir todos os dados do mesmo!';
  const confirmAlertFunc = () => {
    setAlertConfirm(false)
    handleDeleteCourse()
  }
  const cancelAlertFunc = () => setAlertConfirm(false)

  return (
    <WrapForm>

      <Label htmlFor='fm_titulo'>Título do curso / Nome do seu curso:</Label>
      <Input value={data.titulo} onChange={handleFormFill('titulo')} id='fm_titulo' />

      <Label htmlFor='fm_subtitulo'>Subtítulo:</Label>
      <SubLabel>Opcional</SubLabel>
      <Input value={data.subtitulo} onChange={handleFormFill('subtitulo')} id='fm_subtitulo' />

      <Label htmlFor='chamada_publicitaria'>Chamada publicitária:</Label>
      <SubLabel>Opcional</SubLabel>
      <Textarea value={data.chamada_publicitaria} onChange={handleFormFill('chamada_publicitaria')} maxLength='100' rows='2' id='fm_chamada_publicitaria' />

      <Label htmlFor='fm_valor'>Qual o preço de venda do curso?</Label>
      <SubLabel>Valor mínimo: R$ 19,90</SubLabel>
      <Input currencyInput value={data.valor} onChange={handleChangeCurrency} name="valor" id='fm_valor' />

      <WrapPromoValue>

        <h4>Promoção</h4>

        <FaQuestionCircle data-tip={`Se inserido, passará a ser o valor cobrado`} />
        <ReactTooltip place="top" type="info" effect="solid" multiline />

        <Label htmlFor='fm_valor_promocional'>Preço promocional do curso:</Label>
        <SubLabel>Obrigatório para promoção</SubLabel>
        <Input currencyInput value={data.valor_promocional} onChange={handleChangeCurrency} name="valor_promocional" id='fm_valor_promocional' />

        <div className="row">
          <div className="col-sm-6">
            <Label htmlFor="fm_dt_inicio">Data inicial:</Label>
            <SubLabel>Opcional</SubLabel>
            <Input
              value={data.inicio_promo}
              id="fm_dt_inicio"
              onChange={handleFormFill("inicio_promo")}
              mask="99/99/9999"
            />
          </div>
          <div className="col-sm-6">
            <Label htmlFor="fm_dt_final">Data final:</Label>
            <SubLabel>Opcional</SubLabel>
            <Input
              value={data.fim_promo}
              id="fm_dt_final"
              onChange={handleFormFill("fim_promo")}
              mask="99/99/9999"
            />
          </div>
        </div>

        {/* <p className='explanation'>
          O campo <b>"Qual o preço de venda do curso?"</b> será sempre o preço final de venda, portanto o valor promocional deverá ser inserido neste campo. <br />
          O campo <b>"Preço original do curso"</b> aparecerá com um corte na listagem, <span className="nowrap">ex: <s>R$ 50,00</s></span>
        </p> */}
      </WrapPromoValue>


      <Label htmlFor='fm_carga_horaria'>Carga horária:</Label>
      <SubLabel>Informe apenas a quantia de horas</SubLabel>
      <Input type="number" min="1" value={data.carga_horaria} onChange={handleFormFill('carga_horaria')} id='fm_carga_horaria' placeholder='Ex: 20' />

      <Label htmlFor='fm_informacoes_adicionais'>O que vou aprender aqui:</Label>
      {/* <SubLabel>Um item por linha</SubLabel> */}
      <EditorWYSIWYG theme="bubble" value={data.informacoes_adicionais} onChange={handleFormFill('informacoes_adicionais')} />

      <Label htmlFor='fm_descricao'>O curso:</Label>
      <SubLabel>Apresente de forma resumida seu curso</SubLabel>
      <EditorWYSIWYG theme="bubble" value={data.descricao} onChange={handleFormFill('descricao')} />

      <Label htmlFor='fm_categorias'>Categorias:</Label>
      <SubLabel>Selecione no máximo 3</SubLabel>
      <div className="row fs-0">
        {
          !categorias.loading && categorias.data.length > 0 &&
          categorias.data.map((item, index) => {
            return (
              <div className="col-xs-12 col-lg-6 col-in-block" key={index}>
                <InputCheckbox
                  label={item.titulo}
                  value={item.idcategorias}
                  onChange={handleChangeCategoria}
                  // disabled={dataCategorias.length === 3 && !dataCategorias.includes(item.idcategorias)}
                  checked={dataCategorias.includes(item.idcategorias)} />
              </div>
            )
          })
        }
      </div>

      {
        type === 'update'
          ?
          data.video_id && !data?.vimeo_upload?.erro
            ?
            <>
              <Label>Vídeo de apresentação:</Label>
              <SubLabel>Vídeo que aparecerá na tela de venda</SubLabel>
              {
                data?.vimeo_upload?.disponivel
                  ?
                  deleteVideoStatus
                    ?
                    <div className="form-group">
                      <ButtonDelete type="button">Excluindo...</ButtonDelete>
                    </div>
                    :
                    <div className="form-group">
                      <Video idVideo={data.video_id} />
                      <ButtonDelete type="button" onClick={() => handleDeleteVideo()}>Excluir vídeo</ButtonDelete>
                    </div>
                  :
                  <MsgResponse msg='Seu vídeo foi enviado e esta sendo processado! Em breve ele estará disponível nesta área!' theme="success" />
              }
            </>
            :
            <>
              <InputFileVideo
                id='fm_video'
                label="Vídeo de apresentação:"
                sublabel="Vídeo que aparecerá na tela de venda"
                rota={`cursos/${idCourse}`} refetchData={refetchData}
              >
                Vídeos de no máximo 2GB
              </InputFileVideo>
              {
                data?.vimeo_upload?.erro &&
                <MsgResponse msg='Houve um problema ao processar o último vídeo! Tente novamente por favor!' theme="alert" />
              }
            </>
          :
          <div>
            <InputFileVideo
              label="Vídeo de apresentação:"
              sublabel="Vídeo que aparecerá na tela de venda"
              disabledUpload
            >
              Você deve criar o curso antes de enviar o vídeo
            </InputFileVideo>
          </div>
      }

      <hr />

      <div className="row">
        <div className="col-xs-6">
          {
            idCourse &&
            <ButtonDelete width={150} onClick={() => setAlertConfirm(true)}>Excluir Curso</ButtonDelete>
          }
        </div>
        <div className="col-xs-6">
          <Button className="pull-right" onClick={() => handleSend()}>{type === 'create' ? 'Criar curso' : 'Salvar'}</Button>
        </div>
      </div>

      {error && <MsgResponse msg={error} theme="error" />}
      {success && <MsgResponse msg={success} theme="success" />}

      {
        type !== 'create' &&
        <>
          <hr />
          <ButtonNext link to={`${BASE_ALIAS_TEACHER}/curso/${idCourse}/modulo`}>Avançar</ButtonNext>
        </>
      }

      {alertConfirm && <LightboxAlertConfirm desc={msgAlertConfirm} confirmFunc={confirmAlertFunc} cancelFunc={cancelAlertFunc} />}
    </WrapForm >
  )
}
export default FormCourse