import styled from 'styled-components'
import * as mixin from '~/styles/mixins'
import { color, font, media } from '~/styles/variables'

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 330px;
  height: 330px;
  max-width: 100%;
  position: relative;
  padding: 15px;

  &.item-timer{
    .wrap{
      bottom: 12%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  a, button, .wrap-disabled{
    background: none;
    outline: none;
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;
    display:block;
    position: relative;
  }

  .wrap-disabled{
    opacity: .8;
  }

  .warning-disabled{
    p{
      color: ${color.alertColor};
      text-align: center;
    }
  }

  .wrap{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background: ${color.second};
    border-radius: 8px;
    padding: 20px 30px;
    ${mixin.transition('all 0.2s ease-in-out')}

    .wrap-tags {
      position: absolute;
      left: 0;
      top: 10px;

      @media ${media.md} {
        top: 18px;
      }

      span.tag {
        display: flex;
        width: 75px;
        height: 25px;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        font-weight: 700;
        color: ${color.primary};
        background: #ffffff;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        @media ${media.md} {
          width: 90px;
          height: 28px;
        }

        &:nth-child(2) {
          margin-left: 64px;
          z-index: 1;
          justify-content: flex-end;
          padding-right: 18px;

          @media ${media.md} {
            margin-left: 74px;
            padding-right: 24px;
          }
        }
        &.tag-promo {
          background: ${color.primary};
          color: #ffffff;
          animation-name: pulseBgPrimaryColor;
          animation-duration: 0.8s;
          animation-iteration-count: infinite;
        }
      }
    }

    .wrap-signature {
      position: absolute;
      right: 10px;
      top: 10px;

      @media ${media.sm} {
        right: 18px;
        top: 18px;
      }

      img {
        width: 30px;
      }
    }

    .wrap-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      align-content: center;
    }
    .wrap-bt {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-self: center;
      opacity: 0;
      visibility: hidden;
      ${mixin.transition('all 0.2s ease-in-out')}
    }

    .wrap-signature {
      position: absolute;
      right: 10px;
      top: 10px;

      ${media.sm} {
        right: 18px;
        top: 18px;
      }
    }

  }
  h2 {
    ${mixin.fs_head(25)};
    font-weight: 300;
    color: ${color.third};
    text-align: center;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0;
    ${mixin.transition('all 0.05s ease-in-out')}
  }

  span{
    pointer-events: none;
  }

  span.price {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    color: ${color.primary};
    text-align: center;
    align-content: center;
    justify-content: center;
    margin-top: 10px;

    &.price-off {
      font-size: 15px;
      color: #FFFFFF;
      margin-bottom: -10px;
      text-decoration: line-through;
    }
  }
  span.date-start {
    display: flex;
    font-family: ${font.second};
    font-size: 14px;
    font-weight: 400;
    color: ${color.third};
    text-align: center;
    align-content: center;
    justify-content: center;
    margin-top: 10px;

    span.text-captalize{
      text-transform: capitalize;
    }
  }

  a:hover, button:hover {
    .wrap {
      background: ${color.primary};
      ${props => !props.ongoing && "padding-bottom: 100px;"} /* MUDA HOVER SE O ITEM NÃO FOR LISTAGEM DE CURSO EM ANDAMENTO */
      
      .wrap-bt {
        opacity: 1;
        visibility: visible;
      }
    }
    .wrap-timer {
      border-top-color: #ffffff;
    }
    span.price, span.date-start {
      color: #ffffff;
    }
    h2 {
      color: #ffffff;
      ${mixin.transition('all 0.2s ease-in-out 0.1s')}
      ${props => !props.ongoing && "flex: initial;"}  /* MUDA HOVER SE O ITEM NÃO FOR LISTAGEM DE CURSO EM ANDAMENTO */
    }
    .status-name{
      color: #FFFFFF;
    }
  }
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 15px;

  .status-name{
    padding-right: 13px;
    color: ${props => props.status === 1 ? '#92E016' : color.third};
  }
`
