import styled from 'styled-components'
import { color, font, media } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const Section = styled.div`
  max-width: 100%;
  width: 450px;

  ${media.xl}{
    min-width: 450px;
  }

  h3{
    ${mixin.fs_head(23)};
    margin-bottom: 0;
    color: ${color.third}
  }
  
  h4{
    ${mixin.fs_head(14)};
    font-weight: 700;
    margin-bottom: 0;
  }

    p.msg-no-tests{
      font-size: 12px;
      color: ${color.third};
    }

  .wrap-head-new-class{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;

    p{
      font-family: ${font.second};
      font-size: 10px;
      margin-left: 20px;
      line-height: normal;
      
      svg{
        color: #959595;
        font-size: 14px;
        position: relative;
        top: 2px;
        max-lines: 2px;
      }
    }
  }
`