import React, { useState, useEffect } from 'react'

// style
import { ButtonClassFinished, MsgWarning } from './style'
import { color } from '~/styles/variables'

// icons
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'

// rest
import { useGet, usePut } from '~/services/rest'
import { MINIMUM_TEST_QUESTIONS } from '~/services/conf'

const ButtonStatusTest = ({ idClass, numQuestions }) => {

  const themeButtonClassFinished = {
    notFinished: {
      bg: '#CCC',
      fc: color.second,
      bdc: '#CCC'
    },
    finished: {
      bg: color.primary,
      fc: '#FFFFFF',
      bdc: color.primary
    }
  }

  const dataClass = useGet(idClass ? `aulas/${idClass}` : false)
  const [resUpdate, update] = usePut(`aulas/${idClass}`)

  const [status, setStatus] = useState(2)

  useEffect(() => {
    if (dataClass.data.length > 0) {
      setStatus(dataClass.data[0].habilita_teste)
    }
  }, [dataClass.data])

  useEffect(() => {
    if (Object.keys(resUpdate.data).length > 0 && !resUpdate.data.error) {
      setStatus(resUpdate.data.aula.habilita_teste)
    }
  }, [resUpdate])

  const handleFinishClass = async () => {

    const newStatus = status === 1 ? 0 : 1

    await update({
      habilita_teste: newStatus
    })
  }

  if (numQuestions < MINIMUM_TEST_QUESTIONS) {
    return <MsgWarning><b>Faltam {MINIMUM_TEST_QUESTIONS - numQuestions} questões</b> para habilitar o teste!<br />(Apenas questões com respostas cadastradas serão contadas).</MsgWarning>
  }

  // ativo
  if (status === 1) {
    return (
      <ButtonClassFinished
        theme={themeButtonClassFinished.finished}
        onClick={() => handleFinishClass()}>
        <FaCheckCircle /> Teste ativo, clique para desativar.
      </ButtonClassFinished>
    )
  }

  // nao ativo
  return (
    <ButtonClassFinished
      theme={themeButtonClassFinished.notFinished}
      onClick={() => handleFinishClass()}>
      <FaRegCircle /> Teste inativo, clique para ativar.
    </ButtonClassFinished>
  )
}
export default ButtonStatusTest