// conf
import { COOKIE_DOMAIN } from './conf'

export const cpf_validation = strCPF => {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;
  if (strCPF === "11111111111") return false;
  if (strCPF === "22222222222") return false;
  if (strCPF === "33333333333") return false;
  if (strCPF === "44444444444") return false;
  if (strCPF === "55555555555") return false;
  if (strCPF === "66666666666") return false;
  if (strCPF === "77777777777") return false;
  if (strCPF === "88888888888") return false;
  if (strCPF === "99999999999") return false;

  for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (var y = 1; y <= 10; y++) Soma = Soma + parseInt(strCPF.substring(y - 1, y)) * (12 - y);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export const cnpj_validation = cnpj => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14)
    return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999")
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line eqeqeq
  if (resultado != digitos.charAt(0))
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line eqeqeq
  if (resultado != digitos.charAt(1))
    return false;

  return true;
}

export const clear_mask = value => {
  return value.replace(/[^\w\s]/gi, '').replace(/ /g, '')
}

export const createCookie = (name, value) => {
  // cria cookies para manter login entre plataformas
  let dateExpireCookie = new Date();
  dateExpireCookie.setDate(dateExpireCookie.getDate() + 1);
  let val_cookie = encodeURIComponent(value);
  document.cookie = `${name}=${val_cookie};domain=${COOKIE_DOMAIN};path=/;expires=${dateExpireCookie}`
}
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return null;
}
export const deleteCookie = name => {
  document.cookie = `${name}=;domain=${COOKIE_DOMAIN};path=/;expires==Thu, 01 Jan 1970 00:00:01 GMT;`
}
// export const createCookie = (name, value) => {
//   // cria cookies para manter login entre plataformas
//   let dateExpireCookie = new Date();
//   dateExpireCookie.setDate(dateExpireCookie.getDate() + 1);
//   document.cookie = `${name}=${value};domain=${COOKIE_DOMAIN};path=/;expires=${dateExpireCookie}`
// }
// export const getCookie = name => {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
// }

export const stripSlashes = str => {
  str = str.replace(/\\'/g, '\'');
  str = str.replace(/\\"/g, '"');
  str = str.replace(/\\0/g, '\0');
  str = str.replace(/\\\\/g, '\\');
  return str;
}

export const getInitials = (name) => {
  let initials = name.split(' ');

  if (initials.length > 1) {
    initials = initials.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }

  return initials.toUpperCase();
}

export const alertConfirm = (text, funcConfirm) => {
  var r = window.confirm(text);
  if (r === true) {
    funcConfirm()
  }
}

// procura por urls e substitui por links
export const replaceURLs = message => {
  if (!message) return;

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, function (url) {
    var hyperlink = url;
    // eslint-disable-next-line no-useless-escape
    if (!hyperlink.match('^https?:\/\/')) {
      hyperlink = 'http://' + hyperlink;
    }
    return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
  });
}

export const timerBetweenDates = (fim_promo, setDay, setHoure, setMinute, setSecond, setLoading) => {

  var end_date = new Date(fim_promo);
  var now = new Date();
  var distance = end_date.getTime() - now.getTime();

  if (distance <= 0) {
    // Timer done
    document.location.reload(true);
    return false
  } else {

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (days === 1) {
      days -= 1;
      hours += 24
    }

    // strings ---
    var _days = `${days}D`;
    var _hours = `${hours.toString().padStart(2, "0")}:`;
    var _minutes = `${minutes.toString().padStart(2, "0")}:`;
    var _seconds = seconds.toString().padStart(2, "0");

    setDay(_days);
    setHoure(_hours);
    setMinute(_minutes);
    setSecond(_seconds);
    setLoading(false)
  }
}