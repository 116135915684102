import React from 'react'

// style
import { Container, WrapButtonFoot } from './style'

// image
import Button from '~/components/form/Button'
import { color } from '~/styles/variables'

const LightboxAlert = ({ title, desc, confirmFunc, cancelFunc, ConfirmTxt, canceelTxt }) => {

  const themeConfirm = {
    bg: color.primary,
    fc: '#FFFFFF',
    bc: color.primary
  }
  return (
    <Container>
      <div className="overlay-opacity"></div>

      <div className="box">
        <div className="content">
          <h2 className="tts-alert">{title || 'Atenção!'}</h2>
          <p>{desc || 'Realmente deseja fazer isso?'}</p>

          <WrapButtonFoot>
            <Button theme={themeConfirm} onClick={() => confirmFunc()}>{ConfirmTxt || 'Confirmar'}</Button>
            <Button onClick={() => cancelFunc()}>{canceelTxt || 'Cancelar'}</Button>
          </WrapButtonFoot>
        </div>
      </div>

    </Container>
  )
}
export default LightboxAlert