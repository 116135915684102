import React from 'react';


// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section } from './style';

// components
import HeaderUser from '~/components/HeaderUser'

// elements
import FormDataPayments from '~/elements/FormDataPayment';

const DataPayments = () => {

  return (
    <TemplateHome template="teacher">
      <HeaderUser />

      <Section>
        <h2>Configure seus dados para recebimento</h2>
        <p>Os dados informados abaixo serão utilizados para processar os pagamentos dos cursos a você de forma automática. Certifique que os mesmos estão corretos e mantenha sempre atualizados.</p>

        <FormDataPayments />

      </Section>

    </TemplateHome>
  )
}
export default DataPayments