import React, { useContext } from 'react';

// Context
import { AuthContext } from '~/hooks/auth'

// components
import { LoadingMainTitle } from '~/components/Loading'

const HeaderUser = () => {

  const auth = useContext(AuthContext)

  return (
    !auth.user
      ?
      <LoadingMainTitle />
      :
      <h1>Olá {auth.user.nome}!</h1>
  )
}
export default HeaderUser