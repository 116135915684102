import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Section = styled.section`
  max-width: 100%;
  width: 906px;
  margin: 60px 0;
  text-align: center;

  ${media.sm}{
    text-align: left;
  }
  ${media.xl}{
    width: 100%;
  }

  h2{
    color: ${color.primary};
  }

  .grid-form{
    ${media.max_lg}{
      margin-bottom: 30px;
    }
  }
`