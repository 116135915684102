import styled from 'styled-components'
import { media } from '~/styles/variables'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;

  ${media.sm}{
    justify-content: flex-start;
  }

  .head{
    padding-right: 20px;
  }

  h1,h2,h4,h5,h6,p{
    margin: 5px 0;
  }
`
