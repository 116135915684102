import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Section = styled.section`
  h2.tts{
    color: ${color.primary};
    margin-bottom: 20px;
  }

  p{
    &.msg-curso-marcelo{
      margin-top: 30px;
      text-align: left;

      ${media.xl}{
        margin-left: 5%;
        margin-top: 0;
      }
    }
  }
`