import React from 'react';
import HeaderUser from '~/components/HeaderUser';
import { Redirect } from 'react-router'

// templates
import TemplateHome from '~/templates/Home'
import { BASE_ALIAS_TEACHER } from '~/services/conf';

const CourseNotFound = ({ location }) => {
  if (location.state?.access) {
    return (
      <TemplateHome template="teacher">
        <HeaderUser />
        <h2 className="primaryColor mb15">Curso nao encontrado!</h2>
        <h3 className='fw-400'>Por favor, verifique o curso que está tentando acessar.</h3>
      </TemplateHome>
    )
  } else {
    return <Redirect to={BASE_ALIAS_TEACHER} />
  }

}
export default CourseNotFound