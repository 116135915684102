import React from 'react'

// style
import { Item } from './style'

// internal component
import { IconMaterial } from './IconMaterial'

const MenuItem = ({ dataMaterial, dataCourse }) => {

  const type = dataMaterial.arquivo.split('.').pop();

  // const handleDownload = () => {
  //   fetch(dataMaterial.arquivo_url)
  //     .then(resp => resp.blob())
  //     .then(blob => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.style.display = 'none';
  //       a.href = url;
  //       // the filename you want
  //       // a.download = dataMaterial.arquivo;
  //       a.setAttribute('target', '_blank');
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch(() => alert('Ops! Arquivo não encontrado'));
  // }

  return (
    <Item>
      <a href={dataMaterial.arquivo_url} title="Download" target="_blank" rel="noopener noreferrer">
        <span className='icon'>
          <IconMaterial typeFile={type} />
        </span>
        <span className='text'>{dataMaterial.titulo ? dataMaterial.titulo : dataCourse.data[0].aulas.titulo}</span>
      </a>
    </Item>
  )
}
export default MenuItem