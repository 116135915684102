import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'

// elements
import RightSidebar from '~/elements/RightSidebar'
import CourseSidebar from '~/elements/CourseSidebar'
import HeaderCourse from '~/elements/HeaderCourse'

// template
import TemplateCourse from '~/templates/Course'

// components
import LightboxAlert from '~/components/LightboxAlert'
import Button from '~/components/form/Button'

// internal components
import ClassContent from './ClassContent'
import TestContent from './TestContent'

// rest
import { usePut, useGet } from '~/services/rest'

const Course = () => {

  // url do curso
  const { slug } = useParams()
  const [idHistoricCourse, setIdHistoricCourse] = useState()
  const [errorCourseMaster, setErrorCourseMaster] = useState(false)

  // pega dados do curso para usar a url amigável  ----
  const courseDataMaster = useGet(`historicocursos/url/${slug}`)

  // lightbox
  const [lightboxAlert, setLightboxAlert] = useState(false);

  useEffect(() => {
    if (courseDataMaster.error) {
      setErrorCourseMaster(true)
    } else if (courseDataMaster.data.length > 0) {
      setLightboxAlert(courseDataMaster.data[0].alerta)
      setIdHistoricCourse(courseDataMaster.data[0].idhistorico_cursos)
    }
  }, [courseDataMaster])

  const [, changeCourseData] = usePut(`historicocursos/${idHistoricCourse}`)

  // pega o id do historico da aula quando o usuário acessa uma aula
  const [idHistoricClass, setIdHistoricClass] = useState(null)

  // controles gerais do estado do curso
  const [courseData, setCourseData] = useState(null) // dados do curso inicial que são carregados assim que é acessado (curso/módulos/aulas)
  const [updateCourseSideBar, setUpdateCourseSidebar] = useState(false)
  // const [firstRender, setFirstRender] = useState(true)
  const [testSended, setTestSended] = useState(false) // true quando o usuário clica em enviar no teste

  // controle de porcentagem mínima assistida para atualizar os botões da RightSidebar
  const [minPercentagePlayed, setMinPercentagePlayed] = useState(null)

  // define os estilo quando um modulo ou curso é clicado
  const [moduleIdActive, setModuleIdActive] = useState(null)
  const [classIdActive, setClassIdActive] = useState(null)

  // componentes que controlam o conteúdo que será exibido e o conteúdo da barra lateral direita
  const [contentComponent, setContentComponent] = useState()
  const [rightSidebarComponent, setRightSidebarComponent] = useState(<RightSidebar />)

  // priva de setar a a Sidebar nesse estado na primeira carregada -----
  // useEffect(() => {
  //   if (!firstRender) {
  //     // setRightSidebarComponent(<RightSidebar testSended={testSended} type='test' />)
  //   }
  // }, [testSended])

  // quando carregar os dados do curso
  useEffect(() => {
    if (courseData) {

      if (!courseData[0].iniciado_em) {
        const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
        changeCourseData({ iniciado_em: now })
      }
      if (courseData[0].status === 1) {
        changeCourseData({ status: 10 })
      }
    }

  }, [courseData])

  // useEffect(() => {
  //   setFirstRender(false)
  // }, [])

  // handlers ativado quando se clica em um curso
  const handleClassContent = (idHistoricClass, idHistoricModule) => {
    setClassIdActive(idHistoricClass)
    setModuleIdActive(idHistoricModule)
    setIdHistoricClass(idHistoricClass)

    setContentComponent(
      <ClassContent
        idHistoricClass={idHistoricClass}
        handleClassContent={handleClassContent}
        handleTestContent={handleTestContent}
        setUpdateCourseSidebar={setUpdateCourseSidebar}
        setMinPercentagePlayed={setMinPercentagePlayed} />
    )
    setRightSidebarComponent(
      <RightSidebar
        idHistoricClass={idHistoricClass}
        type='class'
        handleClassContent={handleClassContent}
        handleTestContent={handleTestContent}
        setUpdateCourseSidebar={setUpdateCourseSidebar}
        minPercentagePlayed={minPercentagePlayed} />
    )
  }

  // toda vez que troca de aula ou o controle de porcentagem mínima muda, autaliza a sidebar 
  useEffect(() => {
    if (idHistoricClass) {
      setRightSidebarComponent(
        <RightSidebar
          idHistoricClass={idHistoricClass}
          type='class'
          handleClassContent={handleClassContent}
          handleTestContent={handleTestContent}
          setUpdateCourseSidebar={setUpdateCourseSidebar}
          minPercentagePlayed={minPercentagePlayed} />
      )
    }
  }, [idHistoricClass, minPercentagePlayed, setRightSidebarComponent])

  // handler ativado quando se clica em iniciar teste
  const handleTestContent = (classHistoricData) => {

    setContentComponent(
      <TestContent
        idClass={classHistoricData.aulas.idaulas}
        idHistoricClass={classHistoricData.idhistorico_aulas}
        setTestSended={setTestSended}
        classHistoricData={classHistoricData}
        handleClassContent={handleClassContent}
        setUpdateCourseSidebar={setUpdateCourseSidebar} />
    )

    setRightSidebarComponent(<RightSidebar testSended={testSended} type='test' />)
  }

  // barra lateral que lista os módulos e aulas
  const courseSidebarComponent =
    <CourseSidebar
      idHistoricCourse={idHistoricCourse}
      classIdActive={classIdActive}
      moduleIdActive={moduleIdActive}
      setCourseData={setCourseData}
      setModuleIdActive={setModuleIdActive}
      handleClassContent={handleClassContent}
      updateCourseSideBar={updateCourseSideBar}
      setUpdateCourseSidebar={setUpdateCourseSidebar} />

  // lightbox ---
  const buttonsLightbox = [
    <Button key={1} onClick={() => handdleCloseModalAlert()}>Ok</Button>,
  ]
  const handdleCloseModalAlert = () => {
    delete courseDataMaster.data[0].alerta;
    setLightboxAlert(false);
  }

  return (
    <>
      <TemplateCourse
        HeaderCourse={<HeaderCourse idHistoricCourse={idHistoricCourse} errorCourse={errorCourseMaster} />}
        CourseSidebar={courseSidebarComponent}
        RightSidebar={rightSidebarComponent}
        Content={contentComponent}>
      </TemplateCourse>
      {
        lightboxAlert &&
        <LightboxAlert closeLightbox={handdleCloseModalAlert} closable={false} buttons={buttonsLightbox}>
          <h2 className="tts-alert">Aviso</h2>
          <p>O curso que você esta acessando possui um ou mais módulos que você já estudou e se certificou. Sendo assim, ja concluímos para você, então não precisará faze-lo novamente, mas se quiser, poderá acessá-lo para revisar a qualquer momento.</p>
        </LightboxAlert>
      }
    </>
  )

}
export default Course