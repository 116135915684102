import styled from 'styled-components'
import { InputStyledCss } from '~/components/form/Input/style'
import { color, font } from '~/styles/variables';
import { transparentize } from 'polished';

export const Container = styled.div`
  position: relative;
  margin: 36px 0;
`
export const AcceptedFileMsg = styled.span`
  display: block;
  font-family: ${font.second};
  font-size: 12px;
  line-height: 16px;
  margin-top: 12px;
`
export const FakeInput = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  ${InputStyledCss}

  .file-name {
    display: flex;
    justify-content: flex-start;
    font-size: 11px;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1;
    padding-right: 155px;
  }

  span.bt-select {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 140px;
    background: ${color.second};
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 8px;
  }

  i,
  img {
    font-size: 20px;
    margin: 0 15px 0 5px;
  }
`
export const InputFileStyled = styled.input`
  position: relative;
  z-index: 2;
  outline: none;
  opacity: 0;
  ${InputStyledCss}

  &:hover + .fake-file-input {
    background: ${transparentize(0.9, color.second)}
    /* background: fade(@secondColor, 10%); */
  }
`