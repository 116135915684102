import React from 'react'

// styles
import { StatusContainer } from './style'

// img
import IconCheck from '~/assets/icons/check.svg'
import IconCheckGray from '~/assets/icons/check-gray.svg'

const Status = ({ status }) => {

  const icon = status === 1 ? IconCheck : IconCheckGray;
  const label = status === 1 ? "Publicado" : "Rascunho";

  return (
    <StatusContainer status={status}>
      <div className="status-name">
        {label}
      </div>
      <div className="status-icon">
        <img src={icon} alt="" />
      </div>
    </StatusContainer>
  )
}
export default Status