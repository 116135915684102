import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router'

// Context
import { AuthContext } from '~/hooks/auth'

// images
import logo from '~/assets/logo/inova-online-n.v2.svg'
import leftArrow from '~/assets/icons/left-arrow.svg'

//style 
import { MainContainer, Content, Logo, BackButton, WrapForm, ButtonBigger } from './style'

// main style
import { color } from '~/styles/variables'

//conf
import { BASE_ALIAS_TEACHER } from '~/services/conf'
import { createCookie } from '~/services/functions'

// elements
import FormDataPayments from '~/elements/FormDataPayment'

// components
import HeaderUser from '~/components/HeaderUser'
import InputFile from '~/components/form/InputFile'
import MsgResponse from '~/components/MsgResponse'

// rest
import { usePost } from '~/services/rest'

const SignupTeacher = ({ location, history }) => {

  const auth = useContext(AuthContext);

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  // data logo
  const [logoName, setLogoName] = useState('')

  const [formSwitch, setFormSwitch] = useState('formPaymentProfile')

  // res -------------------
  const [resLogoProfile, changeLogoProfile] = usePost(`usuarios/${auth.user.idusuarios}/logo`) // atualiza foto de perfil

  const handleLogo = async e => {
    const new_logo = Array.from(e.target.files)

    let sendLogo = {
      logo_thumb_url: null
    }

    setLogoName('')

    if (new_logo.length > 0) {
      setLogoName(new_logo[0].name)
      const formData = new FormData()
      formData.append('logo', new_logo[0])
      sendLogo = formData
    }

    // faz upload de novo avatar se houver
    await changeLogoProfile(sendLogo) // caso queira remover logo
    return false;
  }

  useEffect(() => {
    if (resLogoProfile.error) {
      setError(resLogoProfile.error)
    } else if (resLogoProfile.success && (resLogoProfile.data.length > 0 || resLogoProfile.data)) {

      //atualiza cookie de usuário
      createCookie('@InovaOnline:user', JSON.stringify(resLogoProfile.data))
      setSuccess("Logo enviado! Redirecionando, aguarde...")

      setTimeout(() => {
        window.location.href = BASE_ALIAS_TEACHER
      }, 2000);
    }
  }, [resLogoProfile, setError, setSuccess])

  if (!location.state?.access) {
    return (
      <Redirect to='/' />
    )
  } else {
    return (
      <MainContainer>
        <Content bg={color.second}>
          <BackButton to='selecao-de-ambiente' title="Voltar">
            <img src={leftArrow} alt="Seta para trás" />
            Voltar
          </BackButton>
          <Logo src={logo} alt="Inova Online" width={322} />
        </Content>
        <Content>
          <HeaderUser />
          {
            formSwitch === 'formPaymentProfile'
              ?
              <WrapForm>
                <p>Ficamos felizes que você tem interesse em compartilhar seu conhecimento através da nossa plataforma. Para isso, basta completar seu cadastro através do formulário abaixo:</p>

                <FormDataPayments setFormSwitch={setFormSwitch} />
              </WrapForm>
              :
              <>
                <h2 className="subTts">Muito bem!</h2>
                <WrapForm>
                  <p>Se você tiver um logotipo, poderá publicar aqui para que o mesmo seja impresso nos certificados emitidos aos usuários que concluírem os cursos que você ofertou. Caso não tenha, não tem problema pois iremos imprimir com suas iniciais.</p>

                  <InputFile label="Seu logo" fileName={logoName} id="fm_file" accept="image/jpeg, image/png" onChange={e => handleLogo(e)}>
                    São aceitos arquivos nas extensões PNG, JPG, JPEG - dimensões recomendadas 800x800px.
                  </InputFile>

                  {error && <MsgResponse msg={error} theme="error" />}
                  {success && <MsgResponse msg={success} theme="success" />}

                  {
                    resLogoProfile.loading
                      ?
                      <ButtonBigger>Enviando logo...</ButtonBigger>
                      :
                      <ButtonBigger onClick={() => window.location.href = BASE_ALIAS_TEACHER}>Concluir</ButtonBigger>
                  }
                </WrapForm>
              </>
          }

        </Content>
      </MainContainer>
    )

  }

}
export default SignupTeacher