import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

// Context
import { AuthContext } from '~/hooks/auth'

// style
import { Container, Header, Content, Footer, Avatar, ButtonSwitchArea } from './style'
import { color } from '~/styles/variables'

// images

import iconSwitch from '~/assets/icons/switch.svg'

// components
import { LoadingAvatar } from '~/components/Loading'
import Menu from './Menu'

const LeftSideBar = ({ templateTheme }) => {

  const auth = useContext(AuthContext)

  const themeSwitchButton = {
    student: {
      bg: color.primary,
      bdc: color.primary,
      fc: '#FFFFFF'
    },
    teacher: {
      bg: color.second,
      bdc: color.second,
      fc: '#FFFFFF'
    }
  }

  return (
    <Container theme={templateTheme}>
      <Header>
        <Link to={{
          pathname: `/perfil`,
          state: { theme: templateTheme }
        }}>
          {
            !auth.user
              ?
              <LoadingAvatar />
              :
              <Avatar avatar={auth.user.avatar} avatar_url={auth.user.avatar_url}>
                <div className="bg"></div>
              </Avatar>
          }
          <h4>Meu Perfil</h4>
        </Link>
        {
          templateTheme === 'teacher' ?
            <ButtonSwitchArea link to="/" theme={themeSwitchButton[templateTheme]}>
              <img src={iconSwitch} alt="Icone Trocar" />
              <span className="txt-desk">Alterar para aluno/a</span>
              <span className="txt-m">Aluno/a</span>
            </ButtonSwitchArea>
            :
            <ButtonSwitchArea link to="/professor" theme={themeSwitchButton[templateTheme]}>
              <img src={iconSwitch} alt="Icone Trocar" />
              <span className="txt-desk">Alterar para professor/a</span>
              <span className="txt-m">Professor/a</span>
            </ButtonSwitchArea>
        }
      </Header>
      <Content>
        <Menu local="content" theme={templateTheme} />
      </Content>
      <Footer>
        <Menu local="footer" theme={templateTheme} />
      </Footer>
    </Container>
  )
}
export default LeftSideBar