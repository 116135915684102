import React from 'react';
import moment from "moment";
import 'moment/locale/pt-br'

// styles
import {
  Container, WrapBaseCertificate, InitialsName, ContentItem, LabelC, SubTitleC
} from './style';

// functions
import { getInitials } from '~/services/functions'

// img
import topoCertificado from '~/assets/certificado/topo.jpg'
import baseCertificado from '~/assets/certificado/base.jpg'
import rightSideShadow from '~/assets/certificado/right-side-shadow.jpg'
import leftSideShadow from '~/assets/certificado/left-side-shadow.jpg'

const CertificateBase = ({ user, preview }) => {
  moment.locale('pt-br');

  return (
    <Container>

      <span>Prévia</span>

      <div className="clearfix"></div>

      <WrapBaseCertificate>
        <img src={topoCertificado} alt="Certificado" className="img-responsive" />

        <div className="wrap-content-certificate">
          <div className="left-detail" style={{ backgroundImage: `url(${leftSideShadow})` }}></div>

          <div className="box-content">
            <div className="col-logo">
              {
                preview ?
                  <img src={preview} alt="Logo" className="img-responsive center-block" />
                  :
                  <InitialsName>{getInitials(user.nome.trim())}</InitialsName>
              }
            </div>
            <div className="col-desc">
              <ContentItem>
                <LabelC>Certifico que</LabelC>
                <SubTitleC>{user.nome}</SubTitleC>
              </ContentItem>
              <ContentItem>
                <LabelC>concluiu o programa</LabelC>
                <SubTitleC>Curso Qualificado</SubTitleC>
              </ContentItem>
              <ContentItem>
                <LabelC>ministrado por</LabelC>
                <SubTitleC>{user.nome}.</SubTitleC>
              </ContentItem>
              <LabelC><span className="fisrt-uppercase">{moment().format('MMMM')}</span> de {moment().format('YYYY')}.</LabelC>
            </div>
          </div>

          <div className="right-detail" style={{ backgroundImage: `url(${rightSideShadow})` }}></div>
        </div>

        <img src={baseCertificado} alt="Certificado" className="img-responsive" />

      </WrapBaseCertificate>

    </Container>
  )
}
export default CertificateBase