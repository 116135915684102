import React from 'react'

// style
import { Container, Iframe, Close } from './style'

// image
import close from '~/assets/icons/close.svg'

const LightboxIframe = ({ iframe, open, closeHandler }) => {

  if (open) {
    return (
      <Container >
        <div className="overlay-opacity" onClick={() => closeHandler(false)}></div>

        <div className="box">
          <Close onClick={() => closeHandler(false)}>
            <img src={close} alt="Fechar" />
          </Close>
          {
            iframe &&
            <>
              <div className="content">
                <Iframe src={iframe}></Iframe>
              </div>
            </>
          }
        </div>

      </Container>
    )

  } else {
    return false;
  }
}
export default LightboxIframe