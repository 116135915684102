import React from 'react'

// style
import { Container, Close, WrapButtonFoot } from './style'

// image
import close from '~/assets/icons/close.svg'

const LightboxAlert = ({ children, closeLightbox, buttons, closable = true, iframe = false, width }) => {

  return (
    <Container iframe={iframe} width={width}>
      {
        closable ?
          <div className="overlay-opacity" onClick={() => closeLightbox()}></div>
          :
          <div className="overlay-opacity"></div>
      }

      <div className="box">
        {
          closable &&
          <Close onClick={() => closeLightbox()}>
            <img src={close} alt="Fechar" />
          </Close>
        }
        <div className="content">
          {children}


          {
            buttons?.length > 0 &&
            <WrapButtonFoot>
              {buttons}
            </WrapButtonFoot>
          }
        </div>
      </div>

    </Container>
  )
}
export default LightboxAlert