import styled from "styled-components"

export const VideoFrame = styled.div`
  padding:56.25% 0 0 0;
  position:relative;
  margin-bottom: 10px;

  iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border: none;
  }
`