import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'

// style
import { ButtonClassFinished } from './style'
import { color } from '~/styles/variables'

// icons
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'

// rest
import { usePut } from '~/services/rest'

/**
 * STATUS:
 * 9: NÃO INICIADO
 * 10: INICIADO
 * 11: FINALIZADO
 */

const ButtonStatus = React.forwardRef(({ classContent, setUpdateCourseSidebar }, ref) => {

  const themeButtonClassFinished = {
    notFinished: {
      bg: '#CCC',
      fc: color.second,
      bdc: '#CCC'
    },
    finished: {
      bg: color.primary,
      fc: '#FFFFFF',
      bdc: color.primary
    }
  }

  const [changeStatusClass, setChangeStatusClass] = usePut(`historicoaulas/${classContent.idhistorico_aulas}`)

  const [statusClass, setStatusClass] = useState(classContent.status)

  useEffect(() => {
    if (Object.keys(changeStatusClass.data).length > 0 && !changeStatusClass.data.error) {
      setStatusClass(changeStatusClass.data.historicoAulaUpdate.status)
    }
  }, [changeStatusClass])

  const handleFinishClass = async () => {

    const newStatus = statusClass === 11 ? 10 : 11
    const dateFinished = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")

    await setChangeStatusClass({
      status: newStatus,
      concluido_em: dateFinished
    })

    await setUpdateCourseSidebar(true)
  }

  // concluído
  if (statusClass === 11) {
    return (
      <ButtonClassFinished
        theme={themeButtonClassFinished.finished}
        finished={true}
      >
        <FaCheckCircle /> Aula concluída
      </ButtonClassFinished>
    )
  }

  // nao concluído
  return (
    <ButtonClassFinished
      ref={ref}
      theme={themeButtonClassFinished.notFinished}
      finished={false}
      onClick={() => handleFinishClass()}
    >
      <FaRegCircle /> Aula concluída
    </ButtonClassFinished>
  )
})
export default ButtonStatus