import styled from 'styled-components'
import { color, font } from '~/styles/variables'

export const WrapForm = styled.div`
  max-width: 100%;
  width: 410px;
  margin: 28px 0;
  text-align: left;
`
export const WrapTwoField = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  
  .item{
    padding: 0;

    &.item-left{
      padding-right: 5px;
    }
    &.item-right{
      padding-left: 5px;
    }
  }
`
export const OpenRegulamento = styled.span`
  display: block;
  font-family: ${font.second};
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: ${color.second};
  margin: 30px 0 10px 0;
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
`
