import styled from 'styled-components'
import { color } from '~/styles/variables'

export const Section = styled.section`
  max-width: 100%;
  width: 410px;
  padding-bottom: 50px;

  h2{
    color: ${color.primary};
  }
`