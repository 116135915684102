import React, { useContext } from 'react'
import moment from "moment";

// styles
import { WrapHead, WrapInfo, WrapListTable, WrapMarca, WrapRecibo } from './style'

// rest
import { LoadingPurchasesHistoric } from '~/components/Loading'
import ItemListRcibo from './ItemListRecibo'
import { AuthContext } from '~/hooks/auth';

const Recibo = React.forwardRef(({ historic }, ref) => {

  const auth = useContext(AuthContext)

  const id = historic.data[0].idhistorico_cursos;
  const day_purchase = moment(historic.data[0].pagamentos[0].created_at).format('DD');
  const month_purchase = moment(historic.data[0].pagamentos[0].created_at).format('MMM');
  const year_purchase = moment(historic.data[0].pagamentos[0].created_at).format('YYYY');
  const date_purchase = `${day_purchase} de ${month_purchase} de ${year_purchase}`;

  return (
    <WrapRecibo ref={ref}>
      <h3 className='fw-300'>Pedido - {date_purchase}</h3>
      <hr />

      <WrapHead>
        <WrapMarca>
          <h2 className='mb5'>Inova Online</h2>
          <h4 className='mb5 fw-300'>INOVA CONSULTORIA DE GESTAO E INOVACAO E. LTDA EPP <br /> CNPJ: 22.110.885/0001-14</h4>
          <p className='mb5'> <a href="//inovaol.com.br" title='inovaol.com.br'>inovaol.com.br</a> </p>
        </WrapMarca>
        <WrapInfo>
          <p className='mb5'><strong>Data: </strong> {date_purchase}</p>
          <p className='mb5'><strong>Nº do pedido: </strong> {year_purchase + id}</p>
        </WrapInfo>
      </WrapHead>

      <hr />
      <p><strong>Vendido para:</strong> {auth.user.nome}</p>
      <hr />

      <WrapListTable>
        <div className='table-responsive'>
          <table className="table table-recibo">
            <thead>
              <tr>
                <th>Curso</th>
                <th>Data de compra</th>
                <th>Parcelas</th>
                <th>Cupom</th>
                <th>Desconto</th>
                <th>Preço</th>
              </tr>
            </thead>
            <tbody>
              {
                historic.loading ?
                  <tr>
                    <td colSpan={5}>
                      <LoadingPurchasesHistoric num={3} />
                    </td>
                  </tr>
                  : historic.data.length > 0 ?
                    historic.data.map((item, index) => <ItemListRcibo key={index} data={item} />)
                    :
                    <tr>
                      <td colSpan={5}>Nenhum historico encontrado!</td>
                    </tr>
              }
            </tbody>
          </table>
        </div>
      </WrapListTable>

    </WrapRecibo>
  )
})

export default Recibo