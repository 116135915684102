import React from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom'
import { BsPencilSquare } from 'react-icons/bs'

// style
import { Item } from './style'

// functions
import { stripSlashes } from '~/services/functions'
import { BASE_ALIAS_TEACHER } from '~/services/conf'

const ItemMenuClass = ({ data }) => {

  const params = useParams()

  return (
    <Item>
      <Link to={`${BASE_ALIAS_TEACHER}/curso/${params.idCourse}/modulo/${params.idModule}/aula/${data.idaulas}`}>
        <span className='icon-finished'>
          <BsPencilSquare size={17} />
        </span>
        {stripSlashes(data.titulo)}
      </Link>
    </Item>
  )

}
export default ItemMenuClass