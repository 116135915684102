import React from 'react';

// styles
import { WrapForm } from './style';

// components
import MsgResponse from '~/components/MsgResponse';
import InputFile from '~/components/form/InputFile';

const FormClass = (props) => {

  const {
    handleFiles,
    error,
    success,
  } = props

  return (
    <WrapForm>
      <InputFile
        label="Selecione os arquivos que deseja disponibilizar:"
        fileName=''
        id="fm_file"
        accept="image/jpg,image/png,image/jpeg,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.pdf"
        multiple="multiple"
        onChange={e => handleFiles(e)}
      >
        Permitido: PDF, DOC, PPT, JPG, JPEG, PNG.
      </InputFile>

      {error && <MsgResponse msg={error} theme="error" />}
      {success && <MsgResponse msg={success} theme="success" />}
    </WrapForm >
  )
}
export default FormClass