import styled from 'styled-components'
import { color, media } from '~/styles/variables'

// grid
import { gridHeaderCreateCourse } from '~/styles/gridSystem'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background: #FFFFFF;
  flex-direction: column;
  z-index: 2;
  ${gridHeaderCreateCourse}

  ${media.sm}{
    position: fixed;
    top: 0;
  }

  h1{
    margin-bottom: 25px;
    padding: 0;

    ${media.lg}{
      margin-bottom: 45px;
    }
  }
  h2{
    color: ${color.primary};
    margin-bottom: 25px;

    ${media.sm}{
      margin-bottom: 35px;
    }
    ${media.xl}{
      margin-bottom: 45px;
    }
  }
`