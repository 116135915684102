import React from 'react'
import { InputStyled, InputMaskStyled, InputMaskCpfCnpjStyled, InputCurrency } from './style'

const Input = ({ mask, cpfCnpj, currencyInput, noGroup, innerRef, ...rest }) => {

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  if (mask) {
    return (
      <div className={!noGroup ? 'form-group' : 'w-100'}>
        <InputMaskStyled ref={innerRef} {...rest} mask={mask} />
      </div>
    )
  }
  if (cpfCnpj) {
    return (
      <div className={!noGroup ? 'form-group' : 'w-100'}>
        <InputMaskCpfCnpjStyled ref={innerRef} {...rest} />
      </div>
    )
  }
  if (currencyInput) {
    return (
      <div className={!noGroup ? 'form-group' : 'w-100'}>
        <InputCurrency config={currencyConfig} currency="BRL" ref={innerRef} {...rest} />
      </div>
    )
  }
  return (
    <div className={!noGroup ? 'form-group' : 'w-100'}>
      <InputStyled ref={innerRef} {...rest} />
    </div>
  )
}

export default Input