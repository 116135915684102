import styled from 'styled-components'
import { color, font } from '~/styles/variables'

export const SubLabelStyle = styled.span`
  font-family: ${font.second};
  font-size: 10px;
  line-height: 1;
  text-align: left;
  color: ${color.second};
  float: right;
  margin-bottom: 10px;
  margin-top: 5px;
`