import React from 'react'

// style
import { Container } from './style'

const HeadButton = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <div className="head">
        {children[0]}
      </div>
      <div className="button">
        {children[1]}
      </div>
    </Container>
  )

}
export default HeadButton