import React from 'react';

import IconGraph from '~/assets/icons/open-graph.svg';
import IconCalendar1 from '~/assets/icons/calendar.svg';
import IconCalendar2 from '~/assets/icons/calendar-2.svg';

// styles
import { Container } from './style';

// components
import ItemStatistic from './ItemStatistic'
import { useGet } from '~/services/rest';
import { LoadingSquare } from '../Loading';

const Statistics = () => {

  const reports = useGet('relatorios/dashboard')

  return (
    <Container>
      {
        reports.loading ?
          <LoadingSquare />
          :
          reports.success &&
          <>
            <ItemStatistic
              icon={IconGraph}
              title="Até o momento"
              liquidValue={reports.data.relatorios.total.liquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              grossValue={reports.data.relatorios.total.bruto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              courseSold={reports.data.relatorios.total.cursos} />
            <ItemStatistic
              icon={IconCalendar1}
              title="Último mês"
              liquidValue={reports.data.relatorios.mes_ultimo.liquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              grossValue={reports.data.relatorios.mes_ultimo.bruto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              courseSold={reports.data.relatorios.mes_ultimo.cursos} />
            <ItemStatistic
              icon={IconCalendar2}
              title="Mês atual"
              liquidValue={reports.data.relatorios.mes_atual.liquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              grossValue={reports.data.relatorios.mes_atual.bruto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              courseSold={reports.data.relatorios.mes_atual.cursos} />
          </>
      }
    </Container>
  )
}
export default Statistics