import React, { useState } from 'react';
import { useParams } from 'react-router';

// templates
import TemplateCreateCourse from '~/templates/CreateCourse'

// elements
import CourseSidebar from '~/elements/CreateCourseSidebar'
import Header from '~/elements/HeaderCreateCourse'

// styles
import { Section } from './style';

// components
import MenuSteps from './MenuSteps'
import ItemSteps from './MenuSteps/Item';

// pages
import DescriptionMaintenance from '~/pages/teacher-area/courses-maintenance/ClassDescription'
import MaterialMaintenance from '~/pages/teacher-area/courses-maintenance/ClassMaterial'
import TestMaintenance from '~/pages/teacher-area/courses-maintenance/ClassTest'

const itemsMenuSteps = [
  {
    page: 'descricao',
    title: 'Descritivo'
  },
  {
    page: 'material',
    title: 'Materiais de apoio'
  },
  {
    page: 'teste',
    title: 'QUIZ (teste)'
  },
]

const MainClassMaintenance = ({ history }) => {

  // parametros de rota
  const { idClass } = useParams()

  // controles de refetch e visibilidade do formulário
  const [formSwitch, setFormSwitch] = useState('descricao')
  const [refetchHeader, setRefetchHeader] = useState(false)
  const [refetchSidebar, setRefetchSidebar] = useState(false)

  // components
  const ComponentCourseSidebar = <CourseSidebar activeMenu="module" refetch={{ do: refetchSidebar, set: setRefetchSidebar }} />
  const ComponentHeader = <Header refetch={{ do: refetchHeader, set: setRefetchHeader }} />

  return (
    <TemplateCreateCourse
      template="teacher"
      Header={ComponentHeader}
      Sidebar={ComponentCourseSidebar}
    >
      <Section>
        <h2 className="tts">{idClass ? 'Editando uma aula' : 'Criando uma aula'}</h2>

        <MenuSteps>
          {itemsMenuSteps.map((item, index) => <ItemSteps key={index} data={item} page={formSwitch} setFormSwitch={setFormSwitch} />)}
        </MenuSteps>

        {
          formSwitch === 'descricao'
            ?
            <DescriptionMaintenance history={history} setFormSwitch={setFormSwitch} />
            :
            formSwitch === 'material'
              ?
              <MaterialMaintenance setFormSwitch={setFormSwitch} />
              :
              formSwitch === 'teste'
                ?
                <TestMaintenance />
                :
                <></>
        }
      </Section>
    </TemplateCreateCourse>
  )
}
export default MainClassMaintenance