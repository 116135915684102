import styled from 'styled-components'
import { color, font, media } from '~/styles/variables'

export const WrapForm = styled.div`
  max-width: 100%;
  width: 410px;
  margin: 0 auto;
  text-align: left;

  ${media.xl}{
    min-width: 410px;
  }
`
export const SubLabel = styled.span`
  display: inline-block;
  font-family: ${font.second};
  font-size: 10px;
  line-height: 1;
  text-align: left;
  color: ${color.second};
  float: right;
  margin-bottom: 10px;
`
export const OpenRegulamento = styled.span`
  display: block;
  font-family: ${font.second};
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: ${color.second};
  margin: 30px 0 10px 0;
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
`
export const WrapAnswer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  input{
    width: 100%;
    margin-left: 15px;
  }
`