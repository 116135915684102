import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

// Context
import { AuthContext } from '~/hooks/auth'

// elements
import LeftSidebar from '~/elements/LeftSidebar'
import RightSidebar from '~/elements/RightSidebar'
import AlertRemoteUser from '~/components/AlertRemoteUser'

// functions
import { createCookie } from '~/services/functions'

// components
import LightboxAlert from '~/components/LightboxAlert'
import Button from '~/components/form/Button'
import { color } from '~/styles/variables'

// conf
import { BASE_URL_API, TERMS_TEACHER_NAME } from '~/services/conf'

// api
import { apiAxio } from '~/services/api'

const TemplateHome = ({ template, children }) => {

  const templateTheme = template ? template : 'student';

  const auth = useContext(AuthContext);

  // lightbox ---
  const themeButton = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: color.primary
  }
  const [lightboxAlert, setLightboxAlert] = useState(auth.user.primeiro_acesso);
  const buttonsLightbox = [
    <Button key={1} onClick={() => handdleCloseModalAlert('perfil')}>Quero alterar</Button>,
    <Button key={2} onClick={() => handdleCloseModalAlert()} theme={themeButton}>Está correto</Button>
  ]
  // lightbox ---

  // lightbox novos termos de uso ---
  const newTerms = require('~/assets/regulamentos/' + TERMS_TEACHER_NAME)
  const themeButtonAcceptTerms = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: color.primary
  }
  const [lightboxAlertNewTerms, setLightboxAlertNewTerms] = useState(false);
  useEffect(() => {
    if (auth.user.v_termos_aceite_professor !== undefined && TERMS_TEACHER_NAME !== auth.user.v_termos_aceite_professor) {
      setLightboxAlertNewTerms(true)
    }
  }, [auth.user])
  const buttonsLightboxNewTerms = [
    <Button key={1} onClick={() => handdleCloseModalAlertNewTerms()} width='300' theme={themeButtonAcceptTerms}>Clique aqui para aceitar</Button>
  ]
  const handdleCloseModalAlertNewTerms = async () => {
    const res = await apiAxio.put(`${BASE_URL_API}usuarios`, { v_termos_aceite_professor: TERMS_TEACHER_NAME });
    createCookie('@InovaOnline:user', JSON.stringify(res.data.usuarioUpdate))
    window.location.reload();
    setLightboxAlertNewTerms(false);
  }
  // lightbox novos termos de uso ---


  const [redirectProfile, setRedirectProfile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // lightbox para primeiro acesso -----
    setLightboxAlert(auth.user.primeiro_acesso === 1 ? true : false)

    // força o usuário terminar o cadastro na tela de perfil
    if (auth.user.primeiro_acesso === 0) {
      if (location.pathname !== '/perfil' && auth.user.cadastro_confirmacao === 0) {
        setRedirectProfile(true);
      }
    }
  }, [auth.user, location.pathname])

  const handdleCloseModalAlert = async (pageRedirect) => {
    setLightboxAlert(false);
    auth.user.primeiro_acesso = 0;
    createCookie('@InovaOnline:user', JSON.stringify(auth.user))
    await apiAxio.put(`${BASE_URL_API}usuarios/primeiro_acesso`);

    if (pageRedirect) {
      setRedirectProfile(true)
    }
  }

  if (redirectProfile) {
    return <Redirect to='/perfil' />
  }

  // se usuario nao se tornou professor ainda ele redireciona para a tela cadastro
  if (auth.user) {
    if (auth.user.usuarios_tipos_id !== 4 && templateTheme === "teacher") {
      return <Redirect to={{
        pathname: `perfil-professor`,
        state: { access: true }
      }} />
    }
  }

  return (
    <div className="full-container">
      <LeftSidebar templateTheme={templateTheme} />
      {auth.user.remoto && <AlertRemoteUser />}
      <div className="main-content-offset">
        <div className="main-content">
          {children}
        </div>
      </div>
      <RightSidebar />

      {
        lightboxAlert &&
        <LightboxAlert closeLightbox={handdleCloseModalAlert} closable={false} buttons={buttonsLightbox}>
          <h2 className="tts-alert">Aviso</h2>
          <p>Olá, estamos felizes com seu primeiro acesso. Aproveite para conferir se você registrou o seu nome corretamente. Após concluir o curso, o certificado será emitido para este nome e não poderá ser alterado.</p><p><strong>{auth.user.nome}</strong></p>
        </LightboxAlert>
      }
      {
        lightboxAlertNewTerms && templateTheme === 'teacher' &&
        <LightboxAlert closeLightbox={handdleCloseModalAlertNewTerms} closable={false} buttons={buttonsLightboxNewTerms} iframe>
          <h2 className="tts-alert">Os termos de uso foram atualizados.</h2>
          <iframe src={newTerms} title="Os termos de uso foram atualizados"></iframe>
        </LightboxAlert>
      }
    </div>
  )
}
export default TemplateHome