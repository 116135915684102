import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Section = styled.section`
  width: 100%;
  margin: 60px 0;
  text-align: center;
  max-width: 100%;
  width: 960px;

  ${media.sm}{
    text-align: left;
  }

  h2{
    color: ${color.primary};
  }

  p{
    &.warning-withdraw{
      font-size: 10px;
      text-align: right;
      margin-top: -45px;
    }
    &.warning{
      font-size: 12px;
      line-height: 20px;
    }

  }


  
  a.see-more{
    vertical-align: middle;
    color: #FFFFFF;
    margin-left: 15px;
    height: 227px;
    width: 270px;
    max-width: 100%;
    font-size: 20px;
  }
`
export const OpenRegulamento = styled.span`
  font-weight: 700;
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
`