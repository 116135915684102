import styled from 'styled-components'
import { media } from '~/styles/variables'

export const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;

  ${media.sm}{
    flex-direction: row;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: 30px;
  background: ${props => props.bg ? props.bg : '#FFFFFF'};

  ${media.sm}{
    height: 100vh;
    flex: 1;
    padding: 0 15px;
  }
`;
export const BackButton = styled.a`
  display: inline-flex;
  margin-bottom: 30px;
  color: #FFFFFF !important;
  font-size: 12px;
  font-weight: 700;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  line-height: 1;
  z-index: 2;

  ${media.sm}{
    position: absolute;
    top: 54px;
    left: 46px;
  }

  img{
    font-weight: 400;
    margin-right: 10px
  }

  &:hover{
    text-decoration: underline;
  }
`;
export const Logo = styled.img`
  max-width: 100%;
  
  ${media.max_xs}{
    width: 200px;
  }
`;
