import React from 'react'
import { useParams } from 'react-router';

// style
import { Item } from './style'

const ItemSteps = ({ data, page, setFormSwitch }) => {

  const params = useParams()

  if (!params.idClass) {
    return (
      <Item active={data.page === page ? true : false}>
        <div className='button disabled'>
          <span className="step-num"></span>
          {data.title}
        </div>
      </Item>
    )
  }

  return (
    <Item active={data.page === page ? true : false}>
      <div className='button' onClick={() => setFormSwitch(data.page)}>
        <span className="step-num"></span>
        {data.title}
      </div>
    </Item>
  )

}
export default ItemSteps