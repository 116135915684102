import styled from 'styled-components'
import { color } from '~/styles/variables'

// components
import Button from '~/components/form/Button'

export const ButtonClassFinished = styled(Button)`
  margin-bottom: 15px;
  font-size: 14px;
  height: 40px;
  width: auto;
  margin-bottom: 30px;

  svg{
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }

  &:hover{
    color: ${color.second};
  }
`
export const MsgWarning = styled.p`
  background: #f6f6f6;
  color: ${color.second};
  font-size: 14px;
  margin-bottom: 30px;
  padding: 20px;
  line-height: normal;
`