import React, { useEffect, useCallback } from 'react'
import { format } from 'date-fns'
import ReactTooltip from "react-tooltip"

// conf
import { BLOCK_BY_VIDEO_PAYED } from '~/services/conf'

// style
import { Item } from './style'

// icons
import { FaCheckCircle } from 'react-icons/fa'

// rest
import { usePut } from '~/services/rest'

// functions
import { stripSlashes } from '~/services/functions'

const ClassItem = props => {

  const {
    dataModule,
    dataClass,
    idActive,
    blockClassesByTest,
    blockClassesByPercentageWatched,
    currentTestClass,
    classClick,
    activeClass
  } = props

  const [, changeModule] = usePut(`historicomodulos/${dataModule.idhistorico_modulos}`)
  const [, changeClass] = usePut(`historicoaulas/${dataClass.idhistorico_aulas}`)

  const handleClassClick = useCallback(() => {
    classClick(dataClass.historico_modulos_id, dataClass.idhistorico_aulas)

    // insere data de inicio do modulo e aula e status para iniciado (10)
    const dataModuleToUpdate = {}
    const dataClassToUpdate = {}

    if (!dataModule.iniciado_em) {
      const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
      dataModuleToUpdate.iniciado_em = now;
    }
    if (!dataClass.iniciado_em) {
      const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
      dataClassToUpdate.iniciado_em = now;
    }
    if (!dataModule.status || dataModule.status === 9) {
      dataModuleToUpdate.status = 10;
    }
    if (!dataClass.status || dataClass.status === 9) {
      dataClassToUpdate.status = 10;
    }

    if (Object.keys(dataModuleToUpdate).length > 0) {
      changeModule(dataModuleToUpdate)
    }
    if (Object.keys(dataClassToUpdate).length > 0) {
      changeClass(dataClassToUpdate)
    }

  }, [dataClass, dataModule, classClick, changeModule, changeClass])

  // carrega aulas que o usuario parou
  useEffect(() => {
    if (activeClass) {
      handleClassClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // verifica se o sistema esta configurado para bloquear a aula por porcentagem assistida dos vídeos
  // se sim faz a verificação, se não renderiza direto o item da aula
  if (BLOCK_BY_VIDEO_PAYED) {
    if ((!currentTestClass && blockClassesByTest) || blockClassesByPercentageWatched) { // renderiza item sem onclick
      return (
        <Item
          blocked
          status={dataClass.status}
          active={idActive === dataClass.idhistorico_aulas}
          data-tip="Você deve assistir pelo menos 80% da aula atual para liberar a próxima!">

          <button type='button'>
            {
              dataClass.status === 11 &&
              <span className='icon-finished'>
                <FaCheckCircle size={17} />
              </span>
            }
            {stripSlashes(dataClass.aulas.titulo)}
          </button>
          <ReactTooltip place="top" type="warning" effect="solid" className='toolTypeWarning' />
        </Item>
      )
    } else {
      return (
        <Item
          status={dataClass.status}
          active={idActive === dataClass.idhistorico_aulas}
          onClick={() => handleClassClick()}>

          <button type='button'>
            {
              dataClass.status === 11 &&
              <span className='icon-finished'>
                <FaCheckCircle size={17} />
              </span>
            }
            {stripSlashes(dataClass.aulas.titulo)}
          </button>
        </Item>
      )
    }
  } else {
    return (
      <Item
        status={dataClass.status}
        active={idActive === dataClass.idhistorico_aulas}
        onClick={() => handleClassClick()}>

        <button type='button'>
          {
            dataClass.status === 11 &&
            <span className='icon-finished'>
              <FaCheckCircle size={17} />
            </span>
          }
          {stripSlashes(dataClass.aulas.titulo)}
        </button>
      </Item>
    )
  }

}
export default ClassItem