import React from 'react'
import { Editor } from './style'
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link'],
    ['clean']
  ],
}
const formats = [
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'link'
]

const EditorWYSIWYG = ({ ...rest }) => {
  return (
    <div className="form-group">
      <Editor {...rest} modules={modules} formats={formats} />
    </div>
  )
}
export default EditorWYSIWYG