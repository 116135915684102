import styled from 'styled-components'
import { color, font } from '~/styles/variables'

// components
import Button from '~/components/form/Button'
import { lighten } from 'polished'

export const Container = styled.div`
  width: 100%;
`
export const WrapValidations = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
export const MsgResponseValidation = styled.div`
  display: block;

  .item{
    display: inline-block;
    margin: 0 0 5px 0;
    padding: 5px 10px;
    line-height: 1;
    font-family: ${font.primary};
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    background: ${lighten(0.45, color.alertColor)};
    border: 1px solid ${color.alertColor};
    color: ${color.alertColor};
  }
`
export const ButtonClassFinished = styled(Button)`
  margin-top: 15px;
  font-size: 14px;
  height: 40px;
  width: auto;
  margin-bottom: 30px;

  svg{
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }

  &:not(:disabled):hover{
    color: ${color.second};
  }
  &:disabled{
    pointer-events: none;
    opacity: .5;
  }
`
export const MsgWarning = styled.p`
  color: ${color.third};
  font-size: 12px;
  margin-bottom: 30px;
  line-height: normal;
`