import React from 'react'

// images
import logo from '~/assets/logo/inova-online-n.v2.svg'
import leftArrow from '~/assets/icons/left-arrow.svg'

//style 
import { MainContainer, Content, Logo, BackButton } from './style'

// main style
import { color } from '~/styles/variables'

//conf
import { BASE_URL_SITE } from '~/services/conf'

const PoliticasPrivacidade = () => {

  return (
    <MainContainer>
      <Content bg={color.second}>
        <BackButton href={`${BASE_URL_SITE}`} title="Ir para o site">
          <img src={leftArrow} alt="Seta para trás" />
          Ir para o site
        </BackButton>
        <Logo src={logo} alt="Inova Online" width={322} />
      </Content>
      <Content style={{ padding: '30px' }}>
        <div style={{ width: '90%', maxHeight: '100%' }}>
          <h1>Política de Privacidade</h1>

          <h2>Cookies</h2>
          <p>Para lhe proporcionar uma melhor experiência de navegação, nosso site utiliza cookies. Cookies são pequenos arquivos de texto colocados no seu dispositivo para armazenar dados a serem utilizados durante sua navegação atual ou no futuro.</p>

          <p>Estes cookies são utilizados para lhe proporcionar uma melhor experiência através de suas preferências de navegação, configurações, coletar estatísticas, fornecer ofertas, anúncios, ações promocionais personalizadas, entre outros.</p>

          <p>Os cookies poderão ser compartilhados com nossos subdomínios, hotsites ou marcas afiliadas (Unità Faculdade, Escola Superior de Direito, Instituto Brasileiro em Formação de Educadores, Proordem, Inova Business School.</p>

          <h2>Dados pessoais</h2>
          <p>Em nossos formulários solicitamos informações e, quando necessário, dados pessoais. Estes dados são acessados por profissionais autorizados e habilitados por nossa empresa, com seu consentimento, para dar atendimento necessário à sua solicitação. Quando necessário, para atender à sua solicitação de uma melhor forma ou concluir transações, estes dados também poderão ser compartilhados com afiliados ou em resposta a processos judiciais.</p>

          <p>Temos o compromisso de não vender ou alugar suas informações a qualquer terceiro.</p>

          <p>Caso queira, você poderá solicitar a exclusão dos seus dados pessoais do banco de dados do site a qualquer momento, entrando em contato através de nosso formulário de contato.</p>

          <h2>Segurança</h2>
          <p style={{ paddingBottom: 30 }}>Em seções / páginas ou hotsites, onde são solicitadas a digitação de dados pessoais ou informações, utilizamos protocolos seguros com criptografia SSL para proteger a transmissão.</p>
        </div>
      </Content>
    </MainContainer>
  )
}

export default PoliticasPrivacidade