import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Menu = styled.ul`
  padding: 0;
  margin: 0;
  font-size: 0;
  list-style-type: none;
  counter-reset: item;
  margin-bottom: 40px;
`
export const Item = styled.li`
  display: flex;
  counter-increment: item;
  padding-right: 25px;
  margin-bottom: 15px;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;

  ${media.md}{
    display: inline-block;
  }

  &:last-child{
    padding-right: 0;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    opacity: ${props => props.active ? 1 : 0.3};
    cursor: pointer;

    &:not(.disabled):hover {
      color:${color.primary};
      pointer-events: all;
      cursor: pointer;
      opacity: 1;

      span.step-num {
        background: ${color.primary};
      }
    }

    &.disabled{
      cursor: default;
    }

    span.step-num {
      display: flex;
      width: 40px;
      height: 40px;
      position: relative;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background:${color.second};
      margin-right: 12px;

      &:before {
        content: counters(item, "");
        color: #ffffff;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
`