import styled, { css } from 'styled-components'
import { color, font } from '~/styles/variables'
import { lighten } from 'polished'

export const Container = styled.div`
  display: block;
`
const MsgBase = css`
  display: inline-block;
  line-height: 1;
  font-family: ${font.primary};
  background: #f1f1f1;
  border: 1px solid #CCCCCC;
  color: ${color.second};
  border-radius: 4px;
  text-align: center;
  width: ${props => props.width ? props.width : 'auto'};
  font-size: ${props => props.bigger ? '20px' : '14px'};
  padding: ${props => props.bigger ? '15px' : '10px'};
  /* font-weight: ${props => props.bigger ? '700' : '400'}; */
`

export const Msg = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
  margin: ${props => props.margin ? props.margin : '15px'} 0;
`
export const MsgAlert = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
  background: ${lighten(0.45, color.alertColor)};
  border-color: ${color.alertColor};
  color: ${color.alertColor};
  margin: ${props => props.margin ? props.margin : '15px'} 0;
`
export const MsgError = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
  background: ${lighten(0.5, color.errorColor)};
  border-color: ${color.errorColor};
  color: ${color.errorColor};
  margin: ${props => props.margin ? props.margin : '15px'} 0;
`
export const MsgSuccess = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
  background: ${lighten(0.5, color.successColor)};
  border-color: ${color.successColor};
  color: ${color.successColor};
  margin: ${props => props.margin ? props.margin : '15px'} 0;
`
