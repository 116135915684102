import styled from 'styled-components'
import { InputStyledCss } from '~/components/form/Input/style'
import { color, font } from '~/styles/variables';
import { transparentize } from 'polished';
import FileInput from 'react-fine-uploader/file-input';

export const Container = styled.div`
  position: relative;
  margin-bottom: 15px;
  opacity: ${props => props.disabled ? 0.4 : 1};
`
export const AcceptedFileMsg = styled.span`
  display: block;
  font-family: ${font.second};
  font-size: 12px;
  line-height: 16px;
  margin-top: 12px;
`
export const FakeInput = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  ${InputStyledCss}

  .file-name {
    display: flex;
    justify-content: flex-start;
    font-size: 11px;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1;
    padding-right: 155px;
  }

  span.bt-select {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 140px;
    background: ${color.second};
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 8px;
  }

  i,
  img {
    font-size: 20px;
    margin: 0 15px 0 5px;
  }
`
export const InputFileStyled = styled(FileInput)`
  position: relative;
  z-index: 2;
  outline: none;
  opacity: 0;
  ${InputStyledCss}

  &:hover + .fake-file-input {
    background: ${transparentize(0.9, color.second)}
    /* background: fade(@secondColor, 10%); */
  }
`
export const ProgressBar = styled.div`
  width: 410px;
  max-width: 100%;
  height: 43px;
  border-radius: 6px;
  border: 1px solid ${color.primary};
  background: #FFFFFF;
  position: relative;
  margin-bottom: 30px;

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${props => props.percentage ? props.percentage + '%' : '0%'};
    transition: all .1s linear;
    height: 100%;
    background-color: ${color.primary};
  }

  span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 18px;
    font-weight: 700;
    z-index: 3;
  }
`