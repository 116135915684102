import styled from 'styled-components'
import { color } from '~/styles/variables'
import { Button } from '~/components/form/Button/style'

export const ButtonClassFinished = styled.button`
  ${Button};
  font-size: 12px;
  width: 130px;
  height: 40px;
  padding: 5px;

  svg{
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }

  &:hover{
    color: ${props => props.finished ? '#FFFFFF' : color.second};
  }
`