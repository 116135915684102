import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// config
import { BASE_ALIAS_TEACHER } from '~/services/conf';

// styles
import { Section } from './style';

// rest
import { useDelete, useGet, usePost, usePut } from '~/services/rest'

//components
import FormClass from '../forms-components/ClassDescription';
import ButtonNext from '~/components/form/ButtonNext';

// functions
import { stripSlashes } from '~/services/functions';

const initialStateDataForm = {
  modulos_id: '',
  titulo: '',
  subtitulo: '',
  video_url: '',
  descricao: '',
  status: 1
}

const ClassMaintenance = ({ history, setFormSwitch }) => {

  // parametros de rota
  const { idCourse, idModule, idClass } = useParams()

  // dados
  const [dataForm, setDataForm] = useState(initialStateDataForm)

  // controle de mensagens de resposta
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  // controle de requisições
  const dataClass = useGet(idClass ? `aulas/${idClass}` : false)
  const [resCreate, create] = usePost(`aulas`)
  const [resUpdate, update] = usePut(`aulas/${idClass}`)
  const [, deleteClass] = useDelete(`aulas/${idClass}`)
  const [, deleteVideo] = useDelete()
  const [deleteVideoStatus, setDeleteVideoStatus] = useState(false)

  // se for update puxa os dados
  useEffect(() => {
    if (dataClass.success && (dataClass.data.length > 0)) {
      setDataForm(
        p => ({
          ...p,
          titulo: stripSlashes(dataClass.data[0].titulo || ''),
          subtitulo: stripSlashes(dataClass.data[0].subtitulo || ''),
          descricao: stripSlashes(dataClass.data[0].descricao || ''),
          video_id: dataClass.data[0].video_id || '',
          video_url: dataClass.data[0].video_url || '',
          vimeo_upload: dataClass.data[0].vimeo_upload || ''
        })
      )
    }
  }, [dataClass.success, dataClass.data])

  // função que preenche os dados do form
  const handleFormFill = field => evt => {

    let value = '';

    if (evt?.target?.value || evt?.target?.value === '') {
      value = evt.target.value
    } else {
      value = evt
    }

    setDataForm(
      p => ({
        ...p,
        [field]: value
      })
    )
  }

  const refetchData = () => {
    dataClass.refetch(dataClass.refetchCancelToken)
  }

  // envia formulário
  const handleSend = async () => {

    setError(null)

    if (
      !dataForm.titulo
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    if (idClass) {

      const dataSendUpdate = {
        ...dataForm,
      }
      delete dataSendUpdate.modulos_id;

      update(dataSendUpdate)
    } else {

      const dataSend = {
        ...dataForm,
        modulos_id: +idModule,
      }

      create(dataSend)
    }
  }

  const handleDeleteClass = async () => {
    await deleteClass(`aulas/${idClass}`);
    history.push(`${BASE_ALIAS_TEACHER}/curso/${idCourse}/modulo/${idModule}`)
  }

  // RESPONSE CREATE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resCreate.success && (resCreate.data || resCreate.data.length > 0)) {
      setSuccess('Aula criada, você já pode enviar o vídeo!')

      history.push(`${BASE_ALIAS_TEACHER}/curso/${idCourse}/modulo/${idModule}/aula/${resCreate.data.aula.idaulas}`)

    } else if (resCreate.error) {
      setError(resCreate.error)
    }
  }, [resCreate, history, idCourse, idModule])

  // RESPONSE UPDATE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resUpdate.success && (resUpdate.data || resUpdate.data.length > 0)) {
      setSuccess('Aula atualizada!')
    } else if (resUpdate.error) {
      setError(resUpdate.error)
    }
  }, [resUpdate])


  // video controls --------------------

  // excluir vídeo
  const handleDeleteVideo = async () => {
    setDeleteVideoStatus(true);
    await deleteVideo(`aulas/${idClass}/video`);
    await refetchData()
  }

  return (
    <Section>
      <FormClass
        handleFormFill={handleFormFill}
        handleSend={handleSend}
        handleDeleteVideo={handleDeleteVideo}
        handleDeleteClass={handleDeleteClass}
        deleteVideoStatus={deleteVideoStatus}
        data={dataForm}
        refetchData={refetchData}
        idClass={idClass}
        error={error}
        success={success}
        type={idClass ? 'update' : 'create'}
      />

      {
        idClass &&
        <>
          <hr />
          <ButtonNext onClick={() => setFormSwitch('material')}>Avançar</ButtonNext>
        </>
      }
    </Section>
  )
}
export default ClassMaintenance