import styled from 'styled-components'
import { media } from '~/styles/variables'

// grid
import { gridSidebarCreateCourse } from '~/styles/gridSystem'

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${gridSidebarCreateCourse}
  padding-top: 10px;
  margin-bottom: 30px;

  ${media.sm}{
    position: fixed;
    bottom: 0;
  }
`