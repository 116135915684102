import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// styles
import { Container, Content } from './style';
import { color } from '~/styles/variables';

// rest
import { useGet, usePut } from '~/services/rest'
import { BASE_ALIAS_TEACHER, MINIMUM_TEST_QUESTIONS, MINIMUM_VIDEO_PLAYED } from '~/services/conf';

//components
import FormTestDescription from '../forms-components/ClassTestDescription';
import TestContent from './Content';
import HeadButton from '~/components/HeadButton';
import Button from '~/components/form/Button';
import LightboxAlertConfirm from '~/components/LightboxAlertConfirm'

// functions
import { stripSlashes } from '~/services/functions';

const initialStateDataForm = {
  testes_titulo: '',
  testes_descricao: '',
}

const TestMaintenance = () => {

  // parametros de rota
  const { idCourse, idClass } = useParams()

  // dados
  const [dataForm, setDataForm] = useState(initialStateDataForm)

  // controle mostra/esconde form
  const [formVisible, setFormVisible] = useState('loading')

  // controle de mensagens de resposta
  const [error, setError] = useState('')

  // controle de requisições
  const dataClass = useGet(idClass ? `aulas/${idClass}` : false)
  const [, update] = usePut(`aulas/${idClass}`)

  // se for update puxa os dados
  useEffect(() => {
    if (dataClass.success && (dataClass.data.length > 0)) {
      if (dataClass.data[0].testes_titulo) {
        setDataForm(
          p => ({
            ...p,
            testes_titulo: stripSlashes(dataClass.data[0].testes_titulo || ''),
            testes_descricao: stripSlashes(dataClass.data[0].testes_descricao || ''),
          })
        )
        setFormVisible('hide')
      } else {
        setFormVisible('show')
      }
    }
  }, [dataClass.success, dataClass.data])

  // função que preenche os dados do form
  const handleFormFill = field => evt => {
    setDataForm({
      ...dataForm,
      [field]: evt.target.value
    })
  }

  // envia formulário
  const handleSend = async () => {

    if (
      !dataForm.testes_titulo
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    setAlertConfirm(true)
  }

  // cria/atualiza teste 
  const handleCreateUpdateTest = async () => {

    const dataSendUpdate = {
      ...dataForm,
    }

    await update(dataSendUpdate)
    dataClass.refetch(dataClass.refetchCancelToken)
  }

  // Alert Confirm config
  const [alertConfirm, setAlertConfirm] = useState(false)
  const msgAlertConfirm = 'Você tem certeza que deseja cadastrar um Quiz (teste) nesta aula deste módulo? Se fizer isto, o aluno só avançará ou emitirá o certificado após aprovado no mesmo.';
  const confirmAlertFunc = () => {
    setAlertConfirm(false)
    handleCreateUpdateTest()
  }
  const cancelAlertFunc = () => setAlertConfirm(false)

  // RESPONSE UPDATE  -----
  // useEffect(() => {
  //   setError('')
  //   setSuccess('')

  //   if (resUpdate.success && (resUpdate.data || resUpdate.data.length > 0)) {
  //     setFormVisible('loading')

  //   } else if (resUpdate.error) {
  //     setError(resUpdate.error)
  //   }
  // }, [resUpdate])


  const themeButton = {
    bg: color.primary,
    fc: '#ffffff',
    bdc: color.primary,
  }

  return (
    <Container>
      <div className="wrap-info">
        <p>Caso esta seja a última aula do seu curso, você pode ou não ter um quiz (teste) para que o aluno emita o certificado. Caso não queira, revise a apresentação e em seguida publique-o.</p>
        <p>Caso queira, comece a cadastrar o seu quiz (teste). São {MINIMUM_TEST_QUESTIONS} questões, com 5 alternativas cada e somente uma correta.  A plataforma irá apresentar ao aluno 10 questões por tentativa e o avaliado terá que ter o acerto de no mínimo {MINIMUM_VIDEO_PLAYED}% para concluir e emitir o certificado. O aluno terá quantas tentativa quiser e o sistema irá apresentar randomicamente 10 questões por tentativa.</p>

        <p>Atenção! Não colocar em nenhuma das questões alternativas do tipo: <b>Nenhuma das anteriores</b> ou <b>Todas as anteriores</b></p>

        <p>Por que? O sistema, além de mesclar randomicamente as questões, irá misturar as alternativas a cada tentativa.</p>

        <p>Recomendamos que o nível das questões seja de fácil a médio. O objetivo é que o aluno tenha capturado os pontos mais importantes e básicos do seu curso, ou seja, o objetivo não é dificultar muito, até porque o aluno terá que acertar 8 das 10 questões que irão aparecer em cada tentativa, para poder emitir o certificado</p>

      </div>
      <Content>
        {
          formVisible === 'loading' ? <></> :
            formVisible === 'hide' ?
              <TestContent data={dataForm} editHandler={setFormVisible} />
              :
              <FormTestDescription
                handleFormFill={handleFormFill}
                handleSend={dataClass.data[0]?.testes_titulo ? handleCreateUpdateTest : handleSend}
                data={dataForm}
                error={error}
                type={dataClass.data[0]?.testes_titulo ? 'update' : 'create'} />
        }


      </Content>

      <div style={{ width: '450px' }}>
        <hr />
        <HeadButton style={{ marginBottom: 15 }}>
          <p style={{ width: '290px' }}>Se você já cadastrou todo o conteúdo do seu curso, revise a apresentação e em seguida publique-o.</p>
          <Button link to={`${BASE_ALIAS_TEACHER}/curso/${idCourse}`} theme={themeButton}>Revisar e publicar</Button>
        </HeadButton>
      </div>

      {alertConfirm && <LightboxAlertConfirm desc={msgAlertConfirm} confirmFunc={confirmAlertFunc} cancelFunc={cancelAlertFunc} />}
    </Container>
  )
}
export default TestMaintenance