import React, { useContext } from 'react'
import { ContainerMenu } from './style'

// conf
import { BASE_ALIAS_TEACHER } from '~/services/conf'

// Context
import { AuthContext } from '~/hooks/auth'

// component
import ItemMenu from './ItemMenu'

// img ---
import iconCursos from '~/assets/icons/cursos.svg'
import iconCertificados from '~/assets/icons/certificados.svg'
import iconFinances from '~/assets/icons/map-finance.svg'
import iconSair from '~/assets/icons/sair.svg'

const Menu = ({ local, theme }) => {

  const auth = useContext(AuthContext)

  const handleLogout = () => {
    auth.signOutUser()
    window.location.reload();
  }

  if (local === 'footer') {
    return (
      <ContainerMenu theme={theme}>
        <ul>
          <ItemMenu type="button" icon={iconSair} title="Sair" handdle={handleLogout} />
        </ul>
      </ContainerMenu>
    )
  } else {
    if (theme === 'student') {
      return (
        <ContainerMenu theme={theme}>
          <ul>
            <ItemMenu to='/' icon={iconCursos} title="Meus Cursos" />
            <ItemMenu to='/certificados' icon={iconCertificados} title="Certificados" />
          </ul>
        </ContainerMenu>
      )
    } else if (theme === 'teacher') {
      return (
        <ContainerMenu theme={theme}>
          <ul>
            <ItemMenu to={`${BASE_ALIAS_TEACHER}/cursos`} icon={iconCursos} title="Meus Cursos" />
            <ItemMenu to={`${BASE_ALIAS_TEACHER}/certificados`} icon={iconCertificados} title="Configurar Certificado" />
            <ItemMenu to={`${BASE_ALIAS_TEACHER}/relatorios-pagamentos`} icon={iconFinances} title="Relatórios e pagamentos" />
          </ul>
        </ContainerMenu>
      )
    } else {
      return false;
    }

  }
}

export default Menu