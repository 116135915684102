import styled from 'styled-components'
import { color } from '~/styles/variables'
import { lighten } from 'polished'
import Button from '~/components/form/Button'

export const ButtonDeleteStyled = styled(Button)`
  background-color: #FFFFFF;
  color: ${color.errorColor};
  border-color: ${color.errorColor};
  width: ${props => props.width ? props.width : '90px'};
  font-size: 10px;
  height: 30px;
  padding: 5px;
  border-radius: 6px;

  svg{
    margin-right: 5px;
  }

  &:hover{
    opacity: 1;
    color: ${color.errorColor};
    background-color: ${lighten(0.6, color.errorColor)};
  }
`