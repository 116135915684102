import React, { useEffect, useState } from 'react';
import { BsBackspace, BsPencilSquare, BsGear, BsExclamationTriangle } from 'react-icons/bs'
import { useParams } from 'react-router';
import { apiAxio } from '~/services/api'

// styles
import { Section } from './style';

//components
import HeadButton from '~/components/HeadButton';
import Button from '~/components/form/Button';
import MenuListActions from '~/components/MenuListActions'
import ItemListActions from '~/components/MenuListActions/Item'
import Label from '~/components/form/Label';
import Input from '~/components/form/Input';
import InputGroupAddon from '~/components/form/InputGroupAddon';
import MsgResponse from '~/components/MsgResponse';
import { LoadingMenuListActions } from '~/components/Loading';
import LightboxForm from '~/components/LightboxForm';
import FormTestAnswers from '~/pages/teacher-area/courses-maintenance/forms-components/ClassTestAnswers'
import ButtonStatus from './ButtonStatus'
import ButtonNext from '~/components/form/ButtonNext';

// rest
import { useDelete, useGet, usePost, usePut } from '~/services/rest';
import { Legend, WrapTitle } from '../../ClassMaterial/style';
import { BASE_URL_API } from '~/services/conf';

// functions
import { stripSlashes } from '~/services/functions';

const initialStateDataFormQuestion = {
  aulas_id: '',
  questao: '',
  status: 1
}
const initialStateDataFormEditQuestion = {
  id: '',
  title: '',
}
const initialStateQuestionAnswers = {
  testes_id: '',
  correta: '',
  resposta: '',
  status: 1
}

const TestContent = ({ data, editHandler }) => {

  const { idClass } = useParams()

  // form data cadastro questão
  const [dataFormQuestion, setDataFormQuestion] = useState(initialStateDataFormQuestion)
  const [dataFormQuestionLoading, setDataFormQuestionLoading] = useState(false)
  const [btFinishedLightbox, setBtFinishedLightbox] = useState(false)

  // data form respostas
  const [dataAnswer1, setDataAnswer1] = useState(initialStateQuestionAnswers)
  const [dataAnswer2, setDataAnswer2] = useState(initialStateQuestionAnswers)
  const [dataAnswer3, setDataAnswer3] = useState(initialStateQuestionAnswers)
  const [dataAnswer4, setDataAnswer4] = useState(initialStateQuestionAnswers)
  const [dataAnswer5, setDataAnswer5] = useState(initialStateQuestionAnswers)

  // form data edit questão
  const [currentQuestion, setCurrentQuestion] = useState(initialStateDataFormEditQuestion)

  // controles res
  const dataTests = useGet(idClass ? `testesprofessores/aula/${idClass}` : false)
  const [resCreateQuestion, createQuestion] = usePost(`testes`)
  const [, updateQuestion] = usePut(`testes/${currentQuestion.id}`)
  const [, deleteQuestion] = useDelete()

  // controle de mensagens de resposta
  const [error, setError] = useState('')
  const [successAnswers, setSuccessAnswers] = useState('')
  const [errorAnswers, setErrorAnswers] = useState('')
  const [buttonSendStatus, setButtonSendStatus] = useState('')

  // controle lightbox
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxTitleQuestion, setLightboxTitleQuestion] = useState('')

  // conta quantas questões tem cadastrado
  const [numQuestions, setNumQuestions] = useState(0)

  // confere se questão tem respotas para aumentar contador de qustão cadastrada
  useEffect(() => {
    if (dataTests.data.length > 0) {
      setNumQuestions(dataTests.data.length)
      dataTests.data.forEach(test => {
        if (test.respostas.length === 0) {
          setNumQuestions(p => p - 1)
        }
      });
    }
  }, [dataTests.data])

  // handlers 
  const handleSendQuestion = async () => {
    setNumQuestions(0)

    if (dataFormQuestion.questao === '') {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    if (!idClass) {
      setError('Aula não encontrada!')
      return false
    }

    const dataSendCreate = {
      ...dataFormQuestion,
      aulas_id: +idClass,
    }

    setDataFormQuestionLoading(true)
    await createQuestion(dataSendCreate)
    setDataFormQuestion(initialStateDataFormQuestion)
    setDataFormQuestionLoading(false)
    dataTests.refetch(dataTests.refetchCancelToken)
  }

  const handleEditQuestion = async () => {
    setNumQuestions(0)

    if (currentQuestion.title === '') {
      setCurrentQuestion(initialStateDataFormEditQuestion)
      return false;
    }

    const dataSend = {
      questao: currentQuestion.title,
    }

    await updateQuestion(dataSend)
    dataTests.refetch(dataTests.refetchCancelToken)
    setCurrentQuestion(initialStateDataFormEditQuestion)
  }
  const handleDeleteQuestion = async (id) => {
    setNumQuestions(0)
    await deleteQuestion(`testes/${id}`)
    dataTests.refetch(dataTests.refetchCancelToken)
  }

  // response create question ----------------
  useEffect(() => {
    if (resCreateQuestion.success && resCreateQuestion.data?.sucesso) {
      // abre lighbox assim que cadastrar uma nova questão
      handleOpenLightboxAnswers(resCreateQuestion.data.teste.idtestes, resCreateQuestion.data.teste.questao)
    }
  }, [resCreateQuestion])

  // handles answers ------------------------------------------------------------------------------
  const handleOpenLightboxAnswers = async (idTest, titleTest) => {
    // reseta tudo
    setErrorAnswers('')
    setSuccessAnswers('')
    setLightboxTitleQuestion(titleTest)
    setBtFinishedLightbox(false)

    const res = await apiAxio.get(`${BASE_URL_API}respostas/teste/${idTest}`);

    if (res.status === 200 && res.data.length > 0) {
      setDataAnswer1({ ...res.data[0], resposta: stripSlashes(res.data[0].resposta) })
      setDataAnswer2({ ...res.data[1], resposta: stripSlashes(res.data[1].resposta) })
      setDataAnswer3({ ...res.data[2], resposta: stripSlashes(res.data[2].resposta) })
      setDataAnswer4({ ...res.data[3], resposta: stripSlashes(res.data[3].resposta) })
      setDataAnswer5({ ...res.data[4], resposta: stripSlashes(res.data[4].resposta) })
      setBtFinishedLightbox(true)
    } else {
      setDataAnswer1({ ...initialStateQuestionAnswers, testes_id: idTest })
      setDataAnswer2({ ...initialStateQuestionAnswers, testes_id: idTest })
      setDataAnswer3({ ...initialStateQuestionAnswers, testes_id: idTest })
      setDataAnswer4({ ...initialStateQuestionAnswers, testes_id: idTest })
      setDataAnswer5({ ...initialStateQuestionAnswers, testes_id: idTest })
      setBtFinishedLightbox(false)
    }

    setLightboxOpen(true)
  }

  const handleCloseLightboxAnswers = () => {
    // reseta contagem de numero de questoes
    dataTests.refetch(dataTests.refetchCancelToken)
    setNumQuestions(0)
    setButtonSendStatus('')
    setLightboxOpen(false)
  }

  const handleSend = async () => {
    setErrorAnswers('')
    setSuccessAnswers('')
    setButtonSendStatus('disabled')

    if (
      dataAnswer1.resposta === '' ||
      dataAnswer2.resposta === '' ||
      dataAnswer3.resposta === '' ||
      dataAnswer4.resposta === '' ||
      dataAnswer5.resposta === ''
    ) {
      setErrorAnswers('Por favor, preencha todas as respostas!')
      setButtonSendStatus('')
      return false;
    }
    if (
      dataAnswer1.correta === '' ||
      dataAnswer2.correta === '' ||
      dataAnswer3.correta === '' ||
      dataAnswer4.correta === '' ||
      dataAnswer5.correta === ''
    ) {
      setErrorAnswers('Por favor, atribua uma resposta como correta!')
      setButtonSendStatus('')
      return false;
    }

    if (!dataAnswer1.idrespostas) {
      // cria respostas ----
      await apiAxio.all([
        apiAxio.post(`${BASE_URL_API}respostas`, dataAnswer1),
        apiAxio.post(`${BASE_URL_API}respostas`, dataAnswer2),
        apiAxio.post(`${BASE_URL_API}respostas`, dataAnswer3),
        apiAxio.post(`${BASE_URL_API}respostas`, dataAnswer4),
        apiAxio.post(`${BASE_URL_API}respostas`, dataAnswer5),
      ]).then(apiAxio.spread((res1, res2, res3, res4, res5) => {
        if (
          res1.status === 200 &&
          res2.status === 200 &&
          res3.status === 200 &&
          res4.status === 200 &&
          res5.status === 200
        ) {
          setButtonSendStatus('disabled')
          setSuccessAnswers('Respostas salvas!')
        }
      }));
      setBtFinishedLightbox(true)
    } else {
      // atualiza respostas ----
      await apiAxio.all([
        apiAxio.put(`${BASE_URL_API}respostas/${dataAnswer1.idrespostas}`, dataAnswer1),
        apiAxio.put(`${BASE_URL_API}respostas/${dataAnswer2.idrespostas}`, dataAnswer2),
        apiAxio.put(`${BASE_URL_API}respostas/${dataAnswer3.idrespostas}`, dataAnswer3),
        apiAxio.put(`${BASE_URL_API}respostas/${dataAnswer4.idrespostas}`, dataAnswer4),
        apiAxio.put(`${BASE_URL_API}respostas/${dataAnswer5.idrespostas}`, dataAnswer5),
      ]).then(apiAxio.spread((res1, res2, res3, res4, res5) => {
        if (
          res1.status === 200 &&
          res2.status === 200 &&
          res3.status === 200 &&
          res4.status === 200 &&
          res5.status === 200
        ) {
          setSuccessAnswers('Respostas atualizadas!')
        }
      }));
    }
  }

  return (
    <Section>

      <ButtonStatus idClass={idClass} numQuestions={numQuestions} />

      <HeadButton style={{ marginBottom: 15 }}>
        <h3 className="secondColor">{data.testes_titulo}</h3>
        <Button type="button" className="smaller" onClick={() => editHandler('visible')}>Editar</Button>
      </HeadButton>

      {/* <p>{data.testes_descricao}</p> */}

      <hr />

      <div className="form-group">
        <Label htmlFor='fm_titulo'>Enunciado da Questão:</Label>
        <InputGroupAddon className="input-group">
          <Input
            noGroup
            value={dataFormQuestion.questao}
            onChange={e => setDataFormQuestion({ ...dataFormQuestion, questao: e.target.value })}
            onKeyDown={e => e.key === 'Enter' && handleSendQuestion()} />
          <Button type="button" onClick={() => handleSendQuestion()}>Salvar</Button>
        </InputGroupAddon>
        {error && <MsgResponse msg={error} theme="error" />}
      </div>

      <WrapTitle className="wrap-tests">
        <h4>Questões:</h4>
        <div>
          <Legend><BsGear /> Configurar respostas</Legend>
          <Legend><BsPencilSquare /> Editar</Legend>
          <Legend><BsBackspace /> Excluir</Legend>
          <Legend><BsExclamationTriangle /> Sem resposta</Legend>
        </div>
      </WrapTitle>

      {dataFormQuestionLoading && <LoadingMenuListActions />}
      {
        dataTests.data.length > 0 ?
          <MenuListActions>
            {
              dataTests.data.map(
                test => (
                  <ItemListActions
                    key={test.idtestes}
                    id={test.idtestes}
                    title={stripSlashes(test.questao || '')}
                    idCurrent={currentQuestion.id}
                    titleCurrent={currentQuestion.title}
                    warning={test.respostas.length === 0}
                    buttonConfig={handleOpenLightboxAnswers}
                    buttonDelete={handleDeleteQuestion}
                    buttonEdit={setCurrentQuestion}
                    handleEdit={handleEditQuestion}
                  />
                )
              )
            }
          </MenuListActions>
          :
          <p className="msg-no-tests">Suas questões apareceram aqui</p>
      }

      <LightboxForm open={lightboxOpen} closeHandler={handleCloseLightboxAnswers}>
        <h2>Questão: {lightboxTitleQuestion}</h2>
        <FormTestAnswers
          handleSend={handleSend}
          setDataAnswer1={setDataAnswer1}
          setDataAnswer2={setDataAnswer2}
          setDataAnswer3={setDataAnswer3}
          setDataAnswer4={setDataAnswer4}
          setDataAnswer5={setDataAnswer5}
          dataAnswer1={dataAnswer1}
          dataAnswer2={dataAnswer2}
          dataAnswer3={dataAnswer3}
          dataAnswer4={dataAnswer4}
          dataAnswer5={dataAnswer5}
          error={errorAnswers}
          success={successAnswers}
          buttonSendStatus={buttonSendStatus}
        />
        {
          btFinishedLightbox &&
          <>
            <hr />
            <ButtonNext onClick={() => handleCloseLightboxAnswers()}>Concluir</ButtonNext>
          </>
        }
      </LightboxForm>


    </Section>
  )
}
export default TestContent