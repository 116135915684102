import styled from 'styled-components'
import { color, font, media } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const Menu = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: content-box;
  padding-right: 12px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const Item = styled.li`
  display: block;
  margin-bottom: 10px;

  >button{
    display: inline-flex;
    border: none;
    outline: none;
    align-items: center;
    width: 100%;
    background: ${props => props.active ? color.third : '#F5F5F5'};
    color: ${props => props.active ? '#FFFFFF' : props.status === 11 ? color.third : '#000000'};
    text-align: left;
    font-weight: 700;
    line-height: 1;
    ${mixin.transition('all .1s ease-in')}
    pointer-events: ${props => props.active ? 'none' : 'all'};
    min-height: 70px;
    padding: 10px 15px;
    font-size: 14px;

    ${media.lg}{
      min-height: 80px;
      padding: 15px 20px;
      font-size: 16px;
    }

    ${media.xl}{
      min-height: 90px;
      padding: 15px 35px;
      font-size: 18px;
    }

    .text{
      flex: 1
    }

    span.sub-title{
      display: block;
      font-family: ${font.second};
      color: ${props => props.active ? '#FFFFFF' : color.third};
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;

      ${media.lg}{
        font-size: 13px;
      }
      ${media.xl}{
        font-size: 14px;
      }
    }

    span.icon-finished{
      padding-left: 10px;
    }

    &:hover{
      background: ${color.primary};
      color: #000000;

      span.sub-title{
        color: #FFFFFF;
      }
    }
  }
  >ul{
    display: ${props => props.active ? 'block' : 'none'};
  }
`