import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// config
import { BASE_ALIAS_TEACHER } from '~/services/conf';

// templates
import TemplateCreateCourse from '~/templates/CreateCourse'

// elements
import CourseSidebar from '~/elements/CreateCourseSidebar'
import Header from '~/elements/HeaderCreateCourse'

// styles
import { Section } from './style';

// rest
import { useDelete, useGet, usePost, usePut } from '~/services/rest'

//components
import FormModule from '../forms-components/Module';

// local components
import ModuleContent from './Content';
import { LoadingSquare } from '~/components/Loading';

// funcions
import { stripSlashes } from '~/services/functions';

const initialStateDataForm = {
  cursos_id: '',
  titulo: '',
  subtitulo: '',
  descricao: '',
  status: 1
}

const ModuleMaintenance = ({ history }) => {

  // parametros de rota
  const { idCourse, idModule } = useParams()

  // dados
  const [dataForm, setDataForm] = useState(initialStateDataForm)

  // controles de refetch e visibilidade do formulário
  const [formVisible, setFormVisible] = useState('loading')
  const [refetchHeader, setRefetchHeader] = useState(false)
  const [refetchSidebar, setRefetchSidebar] = useState(false)

  // controle de mensagens de resposta
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  // controle de requisições
  const dataModule = useGet(idModule ? `modulos/${idModule}` : false)
  const [resCreate, create] = usePost(`modulos`)
  const [resUpdate, update] = usePut(`modulos/${idModule}`)
  const [, deleteModule] = useDelete(`modulos/${idModule}`)

  // reset components
  useEffect(() => {
    if (!idModule) {
      setDataForm(initialStateDataForm);
      setFormVisible('show')
    } else {
      setFormVisible('hide')
    }
    setError('')
    setSuccess('')
  }, [idModule])

  // se for update puxa os dados
  useEffect(() => {
    if (dataModule.success && (dataModule.data.length > 0)) {
      setDataForm(
        p => ({
          ...p,
          titulo: stripSlashes(dataModule.data[0].titulo || ''),
          subtitulo: stripSlashes(dataModule.data[0].subtitulo || ''),
          descricao: stripSlashes(dataModule.data[0].descricao || ''),
        })
      )
    }
  }, [dataModule.success, dataModule.data])

  // função que preenche os dados do form
  const handleFormFill = field => evt => {
    setDataForm({
      ...dataForm,
      [field]: evt.target.value
    })
  }

  // envia formulário
  const handleSend = async () => {

    setError(null)

    if (
      !dataForm.titulo
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    const dataSend = {
      ...dataForm,
      cursos_id: +idCourse,
    }

    const dataSendUpdate = {
      ...dataForm,
    }
    delete dataSendUpdate.cursos_id;

    if (idModule) {
      update(dataSendUpdate)
    } else {
      create(dataSend)
    }
  }

  const handleDeleteModule = async () => {
    await deleteModule(`modulos/${idModule}`);
    history.push(`${BASE_ALIAS_TEACHER}/curso/${idCourse}/modulo`)
    setRefetchSidebar(true);
  }

  // RESPONSE CREATE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resCreate.success && (resCreate.data || resCreate.data.length > 0)) {
      setSuccess('Modulo cadastrado, redirecionando...')

      history.push(`${BASE_ALIAS_TEACHER}/curso/${idCourse}/modulo/${resCreate.data.modulo.idmodulos}`)
      setRefetchSidebar(true);

    } else if (resCreate.error) {
      setError(resCreate.error)
    }
  }, [resCreate, history, idCourse])

  // RESPONSE UPDATE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resUpdate.success && (resUpdate.data || resUpdate.data.length > 0)) {
      setFormVisible('hide');
      setRefetchSidebar(true);
    } else if (resUpdate.error) {
      setError(resUpdate.error)
    }
  }, [resUpdate])

  // components
  const ComponentCourseSidebar = <CourseSidebar activeMenu="module" refetch={{ do: refetchSidebar, set: setRefetchSidebar }} />
  const ComponentHeader = <Header refetch={{ do: refetchHeader, set: setRefetchHeader }} />

  return (
    <TemplateCreateCourse
      template="teacher"
      Header={ComponentHeader}
      Sidebar={ComponentCourseSidebar}
    >
      <Section>

        {
          formVisible === 'loading' ? <LoadingSquare></LoadingSquare> :
            formVisible === 'hide' ?
              <ModuleContent data={dataForm} editHandler={setFormVisible} />
              :
              <>
                <FormModule
                  handleFormFill={handleFormFill}
                  handleSend={handleSend}
                  handleDeleteModule={handleDeleteModule}
                  data={dataForm}
                  idModule={idModule}
                  error={error}
                  success={success}
                  type={idModule ? 'update' : 'create'}
                />
                <p className="msg-curso-marcelo">Se seu curso tiver apenas um módulo não tem problema.<br /> Preencha as informações solicitadas e siga em frente.</p>
              </>
        }
      </Section>
    </TemplateCreateCourse>
  )
}
export default ModuleMaintenance