const banks = [
  { code: '001', name: '(001) Banco do Brasil' },
  { code: '003', name: '(003) Banco da Amazônia' },
  { code: '004', name: '(004) Banco do Nordeste' },
  { code: '021', name: '(021) Banestes' },
  { code: '025', name: '(025) Banco Alfa' },
  { code: '027', name: '(027) Besc' },
  { code: '029', name: '(029) Banerj' },
  { code: '031', name: '(031) Banco Beg' },
  { code: '033', name: '(033) Banco Santander Banespa' },
  { code: '036', name: '(036) Banco Bem' },
  { code: '037', name: '(037) Banpará' },
  { code: '038', name: '(038) Banestado' },
  { code: '039', name: '(039) BEP' },
  { code: '040', name: '(040) Banco Cargill' },
  { code: '041', name: '(041) Banrisul' },
  { code: '044', name: '(044) BVA' },
  { code: '045', name: '(045) Banco Opportunity' },
  { code: '047', name: '(047) Banese' },
  { code: '062', name: '(062) Hipercard' },
  { code: '063', name: '(063) Ibibank' },
  { code: '065', name: '(065) Lemon Bank' },
  { code: '066', name: '(066) Banco Morgan Stanley Dean Witter' },
  { code: '069', name: '(069) BPN Brasil' },
  { code: '070', name: '(070) Banco de Brasília – BRB' },
  { code: '072', name: '(072) Banco Rural' },
  { code: '073', name: '(073) Banco Popular' },
  { code: '074', name: '(074) Banco J. Safra' },
  { code: '075', name: '(075) Banco CR2' },
  { code: '076', name: '(076) Banco KDB' },
  { code: '096', name: '(096) Banco BMF' },
  { code: '104', name: '(104) Caixa Econômica Federal' },
  { code: '107', name: '(107) Banco BBM' },
  { code: '116', name: '(116) Banco Único' },
  { code: '151', name: '(151) Nossa Caixa' },
  { code: '175', name: '(175) Banco Finasa' },
  { code: '184', name: '(184) Banco Itaú BBA' },
  { code: '204', name: '(204) American Express Bank' },
  { code: '208', name: '(208) Banco Pactual' },
  { code: '212', name: '(212) Banco Matone' },
  { code: '213', name: '(213) Banco Arbi' },
  { code: '214', name: '(214) Banco Dibens' },
  { code: '217', name: '(217) Banco Joh Deere' },
  { code: '218', name: '(218) Banco Bonsucesso' },
  { code: '222', name: '(222) Banco Calyon Brasil' },
  { code: '224', name: '(224) Banco Fibra' },
  { code: '225', name: '(225) Banco Brascan' },
  { code: '229', name: '(229) Banco Cruzeiro' },
  { code: '230', name: '(230) Unicard' },
  { code: '233', name: '(233) Banco GE Capital' },
  { code: '237', name: '(237) Bradesco' },
  { code: '241', name: '(241) Banco Clássico' },
  { code: '243', name: '(243) Banco Stock Máxima' },
  { code: '246', name: '(246) Banco ABC Brasil' },
  { code: '248', name: '(248) Banco Boavista Interatlântico' },
  { code: '249', name: '(249) Investcred Unibanco' },
  { code: '250', name: '(250) Banco Schahin' },
  { code: '252', name: '(252) Fininvest' },
  { code: '254', name: '(254) Paraná Banco' },
  { code: '260', name: '(260) Nu Pagamentos S.A.' },
  { code: '263', name: '(263) Banco Cacique' },
  { code: '265', name: '(265) Banco Fator' },
  { code: '266', name: '(266) Banco Cédula' },
  { code: '300', name: '(300) Banco de la Nación Argentina' },
  { code: '318', name: '(318) Banco BMG' },
  { code: '320', name: '(320) Banco Industrial e Comercial' },
  { code: '336', name: '(336) Banco C6 S.A.' },
  { code: '356', name: '(356) ABN Amro Real' },
  { code: '341', name: '(341) Itau' },
  { code: '347', name: '(347) Sudameris' },
  { code: '351', name: '(351) Banco Santander' },
  { code: '353', name: '(353) Banco Santander Brasil' },
  { code: '366', name: '(366) Banco Societe Generale Brasil' },
  { code: '370', name: '(370) Banco WestLB' },
  { code: '376', name: '(376) JP Morgan' },
  { code: '389', name: '(389) Banco Mercantil do Brasil' },
  { code: '394', name: '(394) Banco Mercantil de Crédito' },
  { code: '399', name: '(399) HSBC' },
  { code: '409', name: '(409) Unibanco' },
  { code: '412', name: '(412) Banco Capital' },
  { code: '422', name: '(422) Banco Safra' },
  { code: '453', name: '(453) Banco Rural' },
  { code: '456', name: '(456) Banco Tokyo Mitsubishi UFJ' },
  { code: '464', name: '(464) Banco Sumitomo Mitsui Brasileiro' },
  { code: '477', name: '(477) Citibank' },
  { code: '479', name: '(479) Itaubank (antigo Bank Boston)' },
  { code: '487', name: '(487) Deutsche Bank' },
  { code: '488', name: '(488) Banco Morgan Guaranty' },
  { code: '492', name: '(492) Banco NMB Postbank' },
  { code: '494', name: '(494) Banco la República Oriental del Uruguay' },
  { code: '495', name: '(495) Banco La Provincia de Buenos Aires' },
  { code: '505', name: '(505) Banco Credit Suisse' },
  { code: '600', name: '(600) Banco Luso Brasileiro' },
  { code: '604', name: '(604) Banco Industrial' },
  { code: '610', name: '(610) Banco VR' },
  { code: '611', name: '(611) Banco Paulista' },
  { code: '612', name: '(612) Banco Guanabara' },
  { code: '613', name: '(613) Banco Pecunia' },
  { code: '623', name: '(623) Banco Panamericano' },
  { code: '626', name: '(626) Banco Ficsa' },
  { code: '630', name: '(630) Banco Intercap' },
  { code: '633', name: '(633) Banco Rendimento' },
  { code: '634', name: '(634) Banco Triângulo' },
  { code: '637', name: '(637) Banco Sofisa' },
  { code: '638', name: '(638) Banco Prosper' },
  { code: '643', name: '(643) Banco Pine' },
  { code: '652', name: '(652) Itaú Holding Financeira' },
  { code: '653', name: '(653) Banco Indusval' },
  { code: '654', name: '(654) Banco A.J. Renner' },
  { code: '655', name: '(655) Banco Votorantim' },
  { code: '707', name: '(707) Banco Daycoval' },
  { code: '719', name: '(719) Banif' },
  { code: '721', name: '(721) Banco Credibel' },
  { code: '734', name: '(734) Banco Gerdau' },
  { code: '735', name: '(735) Banco Pottencial' },
  { code: '738', name: '(738) Banco Morada' },
  { code: '739', name: '(739) Banco Galvão de Negócios' },
  { code: '740', name: '(740) Banco Barclays' },
  { code: '741', name: '(741) BRP' },
  { code: '743', name: '(743) Banco Semear' },
  { code: '745', name: '(745) Banco Citibank' },
  { code: '746', name: '(746) Banco Modal' },
  { code: '747', name: '(747) Banco Rabobank International' },
  { code: '748', name: '(748) Banco Cooperativo Sicredi' },
  { code: '749', name: '(749) Banco Simples' },
  { code: '751', name: '(751) Dresdner Bank' },
  { code: '752', name: '(752) BNP Paribas' },
  { code: '753', name: '(753) Banco Comercial Uruguai' },
  { code: '755', name: '(755) Banco Merrill Lynch' },
  { code: '756', name: '(756) Banco Cooperativo do Brasil' },
  { code: '757', name: '(757) KEB' },
]

export default banks;