import styled from 'styled-components'
import { color, font } from '~/styles/variables'

export const Menu = styled.ul`
  padding: 0;
  margin: 0;
  font-size: 0;
  list-style-type: none;
  counter-reset: item;
`
export const Item = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 5px;
  margin-bottom: 8px;
  border-bottom: 1px dashed #C3C3C3;

  &:hover{
    background: #f9f9f9;
  }

  button.action{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    min-width: 35px;
    height: 35px;
    border: none;
    outline: none;
    background: none;
    color: ${color.second};
    line-height: 1;
    font-size: 15px;
    border: 1px solid transparent;

    svg{
      position: relative;
      top: 3px;
    }

    &:hover{
      background-color: #FFFFFF;
      border-color: #e9e9e9;

      svg{
        transform: scale(1.2);
      }
    }
  }
  .title{
    padding-left: 15px;
  }
  span.text{
    font-family: ${font.second};
    font-size: 14px;
    line-height: 25px;
    text-align: left;
    color: ${color.second};
    line-height: normal;
  }
`
export const WarningIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 40px;
  z-index: 5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 39%, rgba(255,255,255,1) 40%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 39%,rgba(255,255,255,1) 40%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 39%,rgba(255,255,255,1) 40%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
`