import React from 'react'

// style
import { Wrap } from './style'

const InputCheckbox = ({ label, disabled, ...rest }) => {
  return (
    <Wrap disabled={disabled}>
      <label>
        <span>
          <input type="checkbox" disabled={disabled} {...rest} />
          <div className="circle-check"></div>
        </span>
        <span>
          {label}
        </span>
      </label>
    </Wrap>
  )
}
export default InputCheckbox