import React from 'react';
import { stripSlashes } from '~/services/functions';

// styles
import { Item } from './style';

const ItemSale = (props) => {

  const content = { ...props }

  return (
    <Item>
      <div className="desc">
        <span>{stripSlashes(content.desc)}</span>
      </div>
      <div className="value">
        {content.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
      </div>
    </Item>
  )
}
export default ItemSale