import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

// Context
import { AuthContext } from '~/hooks/auth'

// elements
import LeftSidebar from '~/elements/LeftSidebar'
import AlertRemoteUser from '~/components/AlertRemoteUser'

// functions
import { createCookie } from '~/services/functions'

// components
import LightboxAlert from '~/components/LightboxAlert'
import Button from '~/components/form/Button'

// style
import { color } from '~/styles/variables'

// services
import { BASE_URL_API } from '~/services/conf'
import { apiAxio } from '~/services/api'

const TemplateCourse = ({ HeaderCourse, CourseSidebar, RightSidebar, Content, template }) => {

  const templateTheme = template ? template : 'student';

  const auth = useContext(AuthContext);

  // lightbox ---
  const themeButton = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: color.primary
  }
  const [lightboxAlert, setLightboxAlert] = useState(auth.user.primeiro_acesso);
  const buttonsLightbox = [
    <Button key={1} onClick={() => handdleCloseModalAlert('perfil')}>Quero alterar</Button>,
    <Button key={2} onClick={() => handdleCloseModalAlert()} theme={themeButton}>Está correto</Button>
  ]

  const [redirectProfile, setRedirectProfile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLightboxAlert(auth.user.primeiro_acesso === 1 ? true : false)

    // força o usuário terminar o cadastro na tela de perfil
    if (auth.user.primeiro_acesso === 0) {
      if (location.pathname !== '/perfil' && auth.user.cadastro_confirmacao === 0) {
        setRedirectProfile(true);
      }
    }
  }, [auth.user, location.pathname])

  const handdleCloseModalAlert = async (pageRedirect) => {
    setLightboxAlert(false);
    auth.user.primeiro_acesso = 0;
    createCookie('@InovaOnline:user', JSON.stringify(auth.user))
    await apiAxio.put(`${BASE_URL_API}usuarios/primeiro_acesso`);

    if (pageRedirect) {
      setRedirectProfile(true)
    }
  }

  if (redirectProfile) {
    return <Redirect to='/perfil' />
  }

  return (
    <div className="full-container">
      <LeftSidebar templateTheme={templateTheme} />
      {auth.user.remoto && <AlertRemoteUser />}
      {HeaderCourse}
      {CourseSidebar}
      <div className="main-content-offset-course">
        <div className="main-content">
          {Content}
        </div>
      </div>
      {RightSidebar}

      {
        lightboxAlert &&
        <LightboxAlert closeLightbox={handdleCloseModalAlert} closable={false} buttons={buttonsLightbox}>
          <h2 className="tts-alert">Aviso</h2>
          <p>Olá, estamos felizes com seu primeiro acesso. Aproveite para conferir se você registrou o seu nome corretamente. Após concluir o curso, o certificado será emitido para este nome e não poderá ser alterado.</p><p><strong>{auth.user.nome}</strong></p>
        </LightboxAlert>
      }
    </div>
  )
}
export default TemplateCourse