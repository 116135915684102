import styled from 'styled-components'
import { media } from '~/styles/variables'

export const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;

  ${media.sm}{
    flex-direction: row;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px;
  background: ${props => props.bg ? props.bg : '#FFFFFF'};

  ${media.sm}{
    height: 100vh;
    flex: 1;
    padding: 0 15px;
  }
`;
export const BackButton = styled.a`
  display: inline-flex;
  margin-bottom: 30px;
  color: #FFFFFF !important;
  font-size: 12px;
  font-weight: 700;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  line-height: 1;
  z-index: 2;

  ${media.sm}{
    position: absolute;
    top: 54px;
    left: 46px;
  }

  img{
    font-weight: 400;
    margin-right: 10px
  }

  &:hover{
    text-decoration: underline;
  }
`;
export const Logo = styled.img`
  max-width: 100%;
  
  ${media.max_xs}{
    width: 200px;
  }
`;
export const WrapButtonsSelection = styled.div`
  width: 265px;
  max-width: 100%;
  margin-top: 10%;
  text-align: center;

  .item{
    margin-bottom: 32px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  p{
    text-align: center;
    margin-bottom: 32px;
  }
  span{
    display: block;
    font-size: 10px;
    padding: 0 10px;
    margin-top: 10px;
    
  }
  a{
    width: 100%;
  }
`;
