import React, { useEffect, useState } from 'react'

// style
import { Container, ProgressBar, WrapProgressBar } from './style'

// internal components
import ModuleMenu from './ModuleMenu'
import ModuleItem from './ModuleMenu/ModuleItem'
import ClassMenu from './ClassMenu'
import ClassItem from './ClassMenu/ClassItem'

// components
// import { LoadingModuleItem } from '~/components/Loading'

// rest
import { useGet } from '~/services/rest'

const CourseSidebar = props => {

  // recupera props
  const {
    idHistoricCourse,
    moduleIdActive,
    classIdActive,
    setModuleIdActive,
    setCourseData,
    handleClassContent,
    updateCourseSideBar,
    setUpdateCourseSidebar
  } = props

  // rest
  const userCourse = useGet(idHistoricCourse ? `historicocursos/${idHistoricCourse}` : false)

  const [percentageClass, setPercentageClass] = useState(0);

  // calcular a porcentagem do curso concluido
  useEffect(() => {
    if (userCourse.data.length > 0) {
      const historicCourse = userCourse.data[0];
      let totalClass = 0;
      let classFinished = 0;

      historicCourse.historico_modulos.forEach(itemModule => {
        itemModule.historico_aulas.forEach(itemClass => {
          totalClass++;
          if (itemClass.status === 11) classFinished++;
        });
      });

      const percentageFinishedClasses = Math.round((classFinished * 100) / totalClass);
      setPercentageClass(percentageFinishedClasses);
    }
  }, [userCourse.data])

  // seta os dados do curso no estado do componente pai (página curso)
  useEffect(() => {
    if (userCourse.data.length > 0) {
      setCourseData(userCourse.data)
    }
  }, [userCourse, setCourseData])

  // atualiza dados se for requerido
  useEffect(() => {

    if (updateCourseSideBar) {
      userCourse.refetch(userCourse.refetchCancelToken)
      setUpdateCourseSidebar(false)
    }

  }, [userCourse, updateCourseSideBar, setUpdateCourseSidebar])

  const handleActiveModule = (idHistoryModule) => {
    setModuleIdActive(idHistoryModule)
  }

  const handleActiveClass = (idHistoryModule, idHistoryClass) => {
    handleClassContent(idHistoryClass, idHistoryModule)
  }

  // controle de bloqueio de aula baseado em testes
  let blockClassesByPercentageWatched = false

  // controle de bloqueio de aula baseado em testes
  let blockClassesByTest = false
  let currentTestClass = false

  // controle de qual classe deve vir aberta
  let activeClass = false
  let activeClassVerification = true

  const ModuleItemComponent = userCourse.data.length > 0 && userCourse.data[0].historico_modulos.map((itemModule, indexModule) => {

    // pega index do ultimo modulo
    const lastModuleIndex = userCourse.data[0].historico_modulos.length - 1;

    // constrói um menu de aula para cada item do menu modulo
    const ClassItemComponent = itemModule.historico_aulas.map((itemClass, indexClass) => {

      // pega index da ultima aula se for o ultimo modulo
      let lasClassOfLastModuleIndex = null
      if (indexModule === lastModuleIndex) lasClassOfLastModuleIndex = itemModule.historico_aulas.length - 1

      // checa se aula possui teste para bloquear as próximas
      if (!blockClassesByTest) {
        if (itemClass.aulas.habilita_teste) {
          currentTestClass = true
          if (itemClass.teste_resultado.length > 0) {
            for (let i = 0; itemClass.teste_resultado.length > i; i++) {
              if (itemClass.teste_resultado[i].aprovado) {
                blockClassesByTest = false
                currentTestClass = false
                break
              } else {
                blockClassesByTest = true
              }
            }
          } else {
            blockClassesByTest = true
          }
        } else {
          currentTestClass = false
        }
      } else {
        currentTestClass = false
      }
      // seta qual aula não esta concluída para abri-la
      if (itemClass.status !== 11 && activeClassVerification) {
        activeClass = true
        activeClassVerification = false
      } else {
        activeClass = false
      }
      // se o curso estiver concluído ativa sempre a última aula
      if (userCourse.data[0].status === 11 && (indexModule === lastModuleIndex && indexClass === lasClassOfLastModuleIndex)) {
        activeClass = true
      }

      // caso curso não seja linear ele libera sempre a primeira aula de cada modulo
      if (userCourse.data[0]?.cursos.linear === 0) {
        if (indexClass === 0) {
          blockClassesByPercentageWatched = false
        }
      }

      // cria componente da aula para ser retornado
      const classItem = <ClassItem
        key={itemClass.idhistorico_aulas}
        dataModule={itemModule}
        dataClass={itemClass}
        idActive={classIdActive}
        currentTestClass={currentTestClass}
        blockClassesByTest={blockClassesByTest}
        blockClassesByPercentageWatched={blockClassesByPercentageWatched}
        classClick={handleActiveClass}
        activeClass={activeClass} />

      // como queremos travar somente as aulas seguintes a atual que o aluno esta cursnado,
      // verificamos se ja assitiu o mínimo do vídio após a aula atual
      // checa se aluno ja assitiu quantia mínima do vídeo para bloquear/libear próxima aulas
      if (itemClass.video_assistido === 0) {
        blockClassesByPercentageWatched = true
      }

      return classItem
    })
    const ClassMenuComponent = <ClassMenu ClassItems={ClassItemComponent} />

    // constrói o menu modulo passando o menu de aulas
    return (
      <ModuleItem
        key={itemModule.idhistorico_modulos}
        dataModule={itemModule}
        idActive={moduleIdActive}
        status={itemModule.status}
        moduleClick={handleActiveModule}
        ClassMenu={ClassMenuComponent} />
    )
  })

  // if (userCourse.data.length === 0 || userCourse.loading) {
  //   return (
  //     <Container>
  //       <LoadingModuleItem num={3} />
  //     </Container>
  //   )
  // }

  if (userCourse.error) {
    return (
      <Container>
        <h2>Ops, algo deu errado!</h2>
        <p><strong> {userCourse.error} </strong></p>
        <p>Por gentileza, tente novamente</p>
      </Container>
    )
  }

  return (
    <Container>
      <WrapProgressBar>
        <ProgressBar percentage={percentageClass}></ProgressBar>
        <span>{percentageClass}% concluído</span>
      </WrapProgressBar>
      <ModuleMenu ModuleItems={ModuleItemComponent} />
    </Container>
  )
}
export default CourseSidebar