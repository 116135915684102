import React from 'react'
import { Link } from 'react-router-dom'

const ItemMenu = ({ to, icon, title, type, handdle }) => {
  return (
    <li>
      {
        type === 'button' ?
          <button type="button" onClick={() => handdle()}>
            <img src={icon} alt={title} />
            <span className="text">{title}</span>
          </button>
          :
          <Link to={to}>
            <img src={icon} alt={title} />
            <span className='text'>{title}</span>
          </Link>
      }
    </li>
  )
}

export default ItemMenu