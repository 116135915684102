// LOCALHOST
// export const BASE_URL_SITE = 'http://inovaol.local/'
// export const BASE_URL_API = 'http://localhost:3333/' // URL API
// export const COOKIE_DOMAIN = ''

// PRODUCTION
export const BASE_URL_SITE = 'https://inovaol.com/'
export const BASE_URL_API = 'https://api.inovaol.com/' // URL API
export const COOKIE_DOMAIN = '.inovaol.com'

// BOTHS
export const MINIMUM_VIDEO_PLAYED = 80; // percentage
export const MINIMUM_TEST_QUESTIONS = 20;
export const BLOCK_BY_VIDEO_PAYED = true; // ativa o bloqueio de aula por tempo assistido
export const BASE_ALIAS_TEACHER = '/professor'; // ativa o bloqueio de aula por tempo assistido
export const TERMS_TEACHER_NAME = 'termos-de-uso-educador-inova-25-11-21.pdf'; // termos atual sendo usado