import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// styles
import { Section } from './style';

// rest
import { usePost } from '~/services/rest'

//components
import FormMaterial from '../forms-components/ClassMaterial';
import ListMaterial from './List'
import ButtonNext from '~/components/form/ButtonNext';
import Button from '~/components/form/Button';
import { BASE_ALIAS_TEACHER } from '~/services/conf';
import HeadButton from '~/components/HeadButton';
import { color } from '~/styles/variables';

const MaterialMaintenance = ({ setFormSwitch }) => {

  // parametros de rota
  const { idCourse, idClass } = useParams()

  // refetch lista de materiais
  const [refetchMaterial, setRefetchMaterial] = useState(false)
  const [numFilesUploading, setNumFilesUploading] = useState(0)

  // controle de mensagens de resposta
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  // controle de requisições
  const [resCreate, create] = usePost(`aulasarquivos/aula/${idClass}`)

  const handleFiles = async e => {

    const files = Array.from(e.target.files)
    const formData = new FormData()

    setNumFilesUploading(files.length)

    files.forEach(file => {
      formData.append('arquivo', file)
    });

    await create(formData)
    setRefetchMaterial(true)
    setNumFilesUploading(0)
  }

  // RESPONSE CREATE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resCreate.error) {
      setError(resCreate.error)
    }
  }, [resCreate])

  const themeButton = {
    bg: color.primary,
    fc: '#ffffff',
    bdc: color.primary,
  }

  return (
    <Section>
      <FormMaterial
        error={error}
        success={success}
        handleFiles={handleFiles} />

      <ListMaterial numFilesUploading={numFilesUploading} refetch={{ do: refetchMaterial, set: setRefetchMaterial }} />

      <hr />
      <ButtonNext onClick={() => setFormSwitch('teste')}>Avançar</ButtonNext>
      <div className="clearfix"></div>
      <hr />
      <HeadButton style={{ marginBottom: 15 }}>
        <p style={{ width: '250px' }}>Se você já cadastrou todo o conteúdo do seu curso, revise a apresentação e em seguida publique-o.</p>
        <Button link to={`${BASE_ALIAS_TEACHER}/curso/${idCourse}`} theme={themeButton}>Revisar e publicar</Button>
      </HeadButton>
    </Section>
  )
}
export default MaterialMaintenance