import React, { useContext, useEffect, useState } from 'react';

// Context
import { AuthContext } from '~/hooks/auth'

// conf
import { BASE_ALIAS_TEACHER } from '~/services/conf'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { OpenRegulamento, Section } from './style';

// components
import HeaderUser from '~/components/HeaderUser'
import HeadButton from '~/components/HeadButton';
import Button from '~/components/form/Button';
import Statistics from '~/components/Statistics'
import SalesPerPeriod from '~/components/SalesPerPeriod'
import LightboxIframe from '~/components/LightboxIframe';

const ReportsPayments = () => {

  const auth = useContext(AuthContext);
  const [regulamento, setRegulamento] = useState(null)
  const [lightboxOpen, setLightboxOpen] = useState(false)

  useEffect(() => {
    if (auth.user) {
      setRegulamento(require(`~/assets/regulamentos/${auth.user.v_termos_aceite_professor}`))
    }
  }, [auth.user])

  return (
    <TemplateHome template="teacher">
      <HeaderUser />

      <Section>
        <HeadButton>
          <h2>Vendas</h2>
          <Button link to={`${BASE_ALIAS_TEACHER}/dados-para-pagamento`} className="smaller" title="Atualizar ou configurar recebimento">Atualizar ou configurar recebimento</Button>
        </HeadButton>

        <Statistics />
        <SalesPerPeriod />
        <p className="warning-withdraw">
          A cada saque é descontado R$ 3,67 do valor disponível.
        </p>

        {
          regulamento &&
          <>
            <p className='warning'>Os valores ficarão disponíveis para saque após 30 dias e os saques ocorrem de forma automática todo 15 dia do mês vigente - conforme descrito nos termos de uso, <OpenRegulamento onClick={() => setLightboxOpen(true)}>clique aqui para ler</OpenRegulamento>.</p>
            <LightboxIframe open={lightboxOpen} iframe={regulamento} closeHandler={setLightboxOpen} />
          </>
        }
      </Section>

    </TemplateHome>
  )
}
export default ReportsPayments