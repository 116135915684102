import styled from 'styled-components'
import { color } from '~/styles/variables'
import { lighten } from 'polished'
import Button from '~/components/form/Button'

export const ButtonNextStyled = styled(Button)`
  background-color: #FFFFFF;
  color: ${color.second};
  border-color: ${color.second};
  font-size: 14px;
  height: 35px;
  width: ${props => props.width ? props.width : '110px'};
  float: right;
  border-radius: 6px;

  &:hover{
    color: ${color.second};
    opacity: 1;
    background-color: ${lighten(0.8, color.second)};
  }
`