import React from 'react'

// style
import { VideoFrame } from './style'

const Video = ({ idVideo }) => {

  const video_url = `https://player.vimeo.com/video/${idVideo}`

  return (
    <VideoFrame>
      <iframe src={video_url} allow='autoplay; fullscreen' title="Vídeo aula"></iframe>
    </VideoFrame>
  )
}
export default Video