import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import CryptoJS from 'react-native-crypto-js';
import { FaSpinner } from 'react-icons/fa';

// conf
import { BASE_ALIAS_TEACHER, BASE_URL_API, BASE_URL_SITE } from '~/services/conf'
import { apiAxio } from '~/services/api'

// Context
import { AuthContext } from '~/hooks/auth'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section, WrapForm, Photo, RemovePhoto, WrapChangePassContact } from './style'
import { color } from '~/styles/variables'

// components
import Label from '~/components/form/Label'
import Input from '~/components/form/Input'
import Select from '~/components/form/Select'
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'
import WrapOtherCountry from './WrapOtherCountry'
import EditorWYSIWYG from '~/components/form/EditorWYSIWYG'
import LightboxAlert from '~/components/LightboxAlert'

// images
import DefaultAvatar from '~/assets/default-avatar.jpg'

// functions
import { cpf_validation, clear_mask, createCookie, cnpj_validation, deleteCookie } from '~/services/functions'

// rest
import { usePut, usePost } from '~/services/rest'


// arrays
import countries from '~/services/arr-countries'
import br_states from '~/services/arr-states'
import SubLabel from '~/components/form/SubLabel'

const Profile = ({ location }) => {

  const auth = useContext(AuthContext)

  const themeButton = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: color.primary
  }

  // sempre puxa os dados atualizados do usuario quando entrar nessa pagina
  useEffect(() => {
    auth.updateUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [warningCompleteProfile, setWarningCompleteProfile] = useState()

  const [docOriginal, setDocOriginal] = useState('')

  const [profileData, setProfileData] = useState({
    avatar: '',
    avatar_url: '',
    new_avatar: null,
    nome: '',
    doc: '',
    doc_tipo: '',
    email: '',
    email_secundario: '',
    celular: '',
    empresa: '',
    cep: '',
    pais: '',
    estado: '',
    cidade: '',
    bairro: '',
    endereco: '',
    numero: '',
    complemento: '',
    resumo_curriculo: '',
    link_linkedin: ''
  })

  const [isBrazil, setIsBrazil] = useState(true);

  useEffect(() => {
    if (auth.user) {

      setDocOriginal(auth.user.doc)
      setWarningCompleteProfile(auth.user.cadastro_confirmacao === 0 ? true : false)

      setProfileData(p => ({
        ...p,
        avatar: auth.user.avatar || '',
        avatar_url: auth.user.avatar_url || '',
        new_avatar: null,
        nome: auth.user.nome || '',
        email: auth.user.email || '',
        email_secundario: auth.user.email_secundario || '',
        celular: auth.user.celular || '',
        cep: auth.user.cep || '',
        empresa: auth.user.empresa || '',
        doc: auth.user.doc || '',
        doc_tipo: auth.user.doc_tipo || '',
        pais: auth.user.pais || '',
        estado: auth.user.estado || '',
        cidade: auth.user.cidade || '',
        bairro: auth.user.bairro || '',
        endereco: auth.user.endereco || '',
        numero: auth.user.numero || '',
        complemento: auth.user.complemento || '',
        resumo_curriculo: auth.user.resumo_curriculo || '',
        link_linkedin: auth.user.link_linkedin || '',
      }))

      if (auth.user.pais === 'br' || !auth.user.pais) {
        setIsBrazil(true);

        if (auth.user.celular && auth.user.celular.slice(0, 2) !== '55') {
          setProfileData(p => ({
            ...p,
            celular: '+55' + auth.user.celular || '',
          }))
        }

      } else {
        setIsBrazil(false);
      }
    }
  }, [auth.user])

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const [resPhotoProfile, changePhotoProfile] = usePost(`usuarios/${profileData.idusuarios}/avatar`) // atualiza foto de perfil
  const [resUserProfile, changeUserProfile] = usePut(`usuarios`) // atualiza dados do aluno

  // lightbox unificação de contas -------------------------------------------
  const [lightboxUnificacao, setLightboxUnificacao] = useState(false);
  const [lightboxUnificacaoEnviada, setLightboxUnificacaoEnviada] = useState(false);
  const [btsLightboxUnificacao, setBtsLightboxUnificacao] = useState([/* são gerados a partir da descoberta de unificação*/]);
  const btCanclearUnificacao = [
    <Button key={1} onClick={() => handleUnificacao('cancelar_unificar')}>Cancelar unificação</Button>
  ]
  const [unificacaoEnviada, setUnificacaoEnviada] = useState(false);

  const gerarHashUnificacao = (idusuarios_primario, idusuarios_secundario) => {
    // gera a hash com ids dos usuarios para
    const objtoencrypt = JSON.stringify({
      idusuarios_primario, // usuario que recebera os dados
      idusuarios_secundario, // usuario que sera unificado e desativado
    });

    return CryptoJS.AES.encrypt(
      objtoencrypt,
      'fasdfsd78sfd97898fsd987sdfsfdy8fdsgiudfs890fsdh98'
    ).toString().replace(/\//g, '+-+');
  }

  const handleUnificacao = async (op, idusuarios_primario, idusuarios_secundario) => {
    setSuccess("")
    setError("");
    setUnificacaoEnviada(false);

    switch (op) {
      case 'cancelar_operacao':
        setProfileData(p => ({ ...p, doc: auth.user.doc || '' }))
        setLightboxUnificacao(false);
        break;

      case 'solicitar_unificacao':
        setBtsLightboxUnificacao([<Button key={1} span>Enviando solicitação...</Button>]);

        const hashUnificacao = gerarHashUnificacao(idusuarios_primario, idusuarios_secundario)

        const res_su = await apiAxio.get(`${BASE_URL_API}usuarios/unificacao/${hashUnificacao}/1`);

        if (res_su.data.sucesso) {
          createCookie('@InovaOnline:user', JSON.stringify(res_su.data.usuarioUpdate))
          setLightboxUnificacaoEnviada(true)
          setLightboxUnificacao(false);
          auth.updateUserInfo();
        }

        break;
      case 'cancelar_unificar':

        const res_c = await apiAxio.put(`${BASE_URL_API}usuarios/unificacao/cancelar`);

        if (res_c.data.sucesso) {
          createCookie('@InovaOnline:user', JSON.stringify(res_c.data.usuarioUpdate))
          setLightboxUnificacaoEnviada(false)
          setLightboxUnificacao(false);
          auth.updateUserInfo();
        }

        break;
      case 'unificar':
        setUnificacaoEnviada(true);
        const res_u = await apiAxio.put(`${BASE_URL_API}usuarios/unificacao/unificar`);

        if (res_u.data.sucesso) {

          setSuccess('Unificação de contas realizada com sucesso! Você receberá em seu e-mail novas instruções para acessar sua conta. Se tiver alguma dúvida, por favor, entre em contato conosco. Estamos encerrando sua sessão...');
          deleteCookie('@InovaOnline:token');
          deleteCookie('@InovaOnline:user');

          setTimeout(() => {
            window.location.reload();
          }, 30000);
        } else {
          setError('Ops! Algo deu errado.');
        }
        break;

      default:
        setLightboxUnificacao(false);
        setUnificacaoEnviada(false);
        break;
    }
  }

  // checa status da unificacao
  useEffect(() => {
    if (auth.user) {

      if (auth.user.unificacao === 'confirmacao') {
        // checa a cada 5 segundos se a confirmação foi feita
        setLightboxUnificacaoEnviada(true);
        setTimeout(() => {
          auth.updateUserInfo();
        }, 5000);
      } else {
        setLightboxUnificacaoEnviada(false);
      }
    }
  }, [auth])
  // seta botoes lightbox --------------
  useEffect(() => {
    if (auth.user) {
      setBtsLightboxUnificacao([
        <Button key={1} onClick={() => handleUnificacao('solicitar_unificacao', resUserProfile.data.idusuarios, auth.user.idusuarios)} theme={themeButton}>Unificar cadastro</Button>,
        <Button key={2} onClick={() => handleUnificacao('cancelar_operacao')}>Cancelar operação</Button>
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, resUserProfile.data.idusuarios])

  // trata lightbox unificação
  useEffect(() => {
    if (resUserProfile.data.alertUnificacao && resUserProfile.data.email) {
      setLightboxUnificacao(true);
    } else {
      setLightboxUnificacao(false);
    }
  }, [resUserProfile.data])
  // lightbox unificação de contas -------------------------------------------

  // trata erros da atualização de usuario
  useEffect(() => {
    setSuccess("")
    setError("");

    if (resPhotoProfile.error) {
      setError(resPhotoProfile.error)

    } else if (resUserProfile.error) {

      setError(resUserProfile.error)
    }
  }, [resPhotoProfile, resUserProfile])

  // trata sucesso da atualização de usuario
  useEffect(() => {
    async function dataFech() {
      setSuccess("")
      setError("");

      if (resUserProfile.data.length > 0 || resUserProfile.data.sucesso) {
        setSuccess("Atualizando, aguarde...")

        // atualiza cookie de usuário
        createCookie('@InovaOnline:user', JSON.stringify(resUserProfile.data.usuarioUpdate))

        // atualiza perfil de pagamento -----
        // só atualiza se o doc enviado for diferente ----
        if (docOriginal !== clear_mask(profileData.doc) && auth.user.perfis_pagamento_id) {
          const dataPaymentUpdate = {
            perfil_usuario: true,
            usuario_doc: profileData.doc,
            usuario_doc_tipo: profileData.doc_tipo
          }
          const resChangePaymentProfile = await apiAxio.put(`${BASE_URL_API}perfispagamento/${auth.user.perfis_pagamento_id}`, dataPaymentUpdate)

          if (resChangePaymentProfile.status === 200) {
            setError("");
            setSuccess("Perfil atualizado!")
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            setSuccess("")
            setError(`Seus dados foram atualizados, porem ocorreu um erro com o perfil de pagamento. Por favor ferifique seu perfil de pagamento!`);
          }
        } else {
          setError("");
          setSuccess("Perfil atualizado!")
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }

      }
    }
    dataFech();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resUserProfile])

  const handleFormFill = field => (evt, type) => {

    if (field === 'doc') {
      setProfileData({
        ...profileData,
        [field]: evt.target.value,
        doc_tipo: type ? type.toLowerCase() : 'cpf',
      })
    } else {

      let value = '';

      if (evt?.target?.value || evt?.target?.value === '') {
        value = evt.target.value
      } else {
        value = evt
      }

      setProfileData(
        p => ({
          ...p,
          [field]: value
        })
      )

    }
  }

  const handleUpdateUser = async () => {

    setError(null)

    if (
      !profileData.nome ||
      !profileData.doc ||
      !profileData.celular ||
      !profileData.cep ||
      !profileData.estado ||
      !profileData.cidade ||
      !profileData.bairro ||
      !profileData.endereco ||
      !profileData.numero
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    if (!isBrazil && !profileData.pais) {
      setError('Por favor, informe seu país!')
      return false
    }

    // valida cpf
    if (profileData.doc_tipo === 'cpf' && !cpf_validation(clear_mask(profileData.doc))) {
      setError('CPF inválido!')
      return false
    }
    // valida cnpj
    if (profileData.doc_tipo === 'cnpj' && !cnpj_validation(clear_mask(profileData.doc))) {
      setError('CNPJ inválido!')
      return false
    }

    // recupera valores sem formatação de mascara
    const putData = {
      nome: profileData.nome,
      // email: profileData.email,
      empresa: profileData.empresa,
      doc: clear_mask(profileData.doc),
      doc_tipo: profileData.doc_tipo,
      celular: clear_mask(profileData.celular),
      cep: clear_mask(profileData.cep),
      pais: profileData.pais,
      estado: profileData.estado,
      cidade: profileData.cidade,
      bairro: profileData.bairro,
      endereco: profileData.endereco,
      numero: profileData.numero,
      complemento: profileData.complemento,
      resumo_curriculo: profileData.resumo_curriculo,
      link_linkedin: profileData.link_linkedin,
      cadastro_confirmacao: 1,
    }

    if (!putData.pais) delete putData.pais

    // faz upload de novo avatar se houver
    if (profileData.new_avatar) {
      if (profileData.new_avatar === 'remove') {
        await changePhotoProfile({ avatar: null }) // caso queira remover avatar
      } else {
        await changePhotoProfile(profileData.new_avatar)
      }
    }

    await changeUserProfile(putData)
  }

  const handlePhotoUser = e => {
    const new_avatar = Array.from(e.target.files)

    if (new_avatar.length > 0) {
      const preview = URL.createObjectURL(new_avatar[0])

      const formData = new FormData()
      formData.append('avatar', new_avatar[0])

      setProfileData({
        ...profileData,
        avatar_url: preview,
        avatar: new_avatar[0]['name'],
        new_avatar: formData
      })

    } else {
      setProfileData({
        ...profileData,
        avatar_url: auth.user.avatar_url,
        avatar: auth.user.avatar,
        new_avatar: null
      })
    }
  }

  const handleRemovePhoto = () => {
    setProfileData({
      ...profileData,
      avatar_url: null,
      avatar: null,
      new_avatar: 'remove'
    })
  }

  const handleChangeFieldsCountry = () => {
    setIsBrazil(!isBrazil);

    setProfileData({
      ...profileData,
      doc: '',
      pais: '',
      estado: '',
      cep: '',
      cidade: '',
      bairro: '',
      endereco: '',
      numero: '',
      complemento: '',
      celular: '',
    })
  }

  const handleChangeCountry = field => evt => {

    const codeFone = "+" + evt.target[evt.target.selectedIndex].getAttribute('data-code') + ' ';

    setProfileData({
      ...profileData,
      [field]: evt.target.value,
      celular: codeFone,
    })

  }
  const handleBlurCep = () => {
    try {
      if (profileData.cep !== '' && profileData.cep !== '_____-___') {
        fetch(`https://viacep.com.br/ws/${profileData.cep}/json/`)
          .then(response => {
            return response.json();
          }).then(data => {
            setProfileData({
              ...profileData,
              cidade: data.localidade,
              estado: data.uf,
              bairro: data.bairro,
              endereco: data.logradouro
            })
          })
      }
    } catch (error) {
      console.log('Cep não localizado!');
    }
  }

  const themeButtonPassword = {
    bg: color.primary,
    fc: '#ffffff',
    bdc: color.primary,
    bdc_hover: color.primary,
  }

  const indexForOptionsSelectCoutries = {
    value: 'iso_alpha2',
    label: 'name'
  }
  const dataAttrForOptionsSelectCoutries = [
    {
      name: 'data-code',
      idx: 'calling_code'
    },
  ]

  const indexForOptionsSelectState = {
    value: 'value',
    label: 'label'
  }

  // lightbox troca de email
  const [lighboxTrocaEmail, setLighboxTrocaEmail] = useState(false);
  const [btTrocaEmail, setBtTrocaEmail] = useState([
    <Button key={1} theme={themeButton} onClick={() => handleTrocarEmailPrimario('trocar')}>Sim, desejo trocar</Button>,
    <Button key={2} onClick={() => handleTrocarEmailPrimario('cancelar')}>Não, manter como está</Button>
  ]);
  const handleTrocarEmailPrimario = async (op) => {
    switch (op) {
      case 'trocar':

        setBtTrocaEmail([<Button key={1} theme={themeButton} span>Trocando...</Button>]);

        const resChangeEmail = await apiAxio.put(`${BASE_URL_API}usuarios/emailPrimario`);

        if (resChangeEmail.status === 200) {
          setBtTrocaEmail([<Button key={1} theme={themeButton} span>Email trocado!</Button>]);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }

        break;
      case 'cancelar':
        setLighboxTrocaEmail(false);
        break;

      default:
        setLighboxTrocaEmail(false);
        break;
    }
  }
  // lightbox troca de email

  if (auth?.user?.unificacao === 'aguardando') {
    return (
      <TemplateHome template={location.state?.theme ? location.state.theme : 'student'}>
        <Section>
          <h1>Meu Perfil</h1>
          <h2>Obrigado por confirmar o email!</h2>

          <p>Para concluir a unificação das contas, clique no botão Unificar cadastro abaixo:</p>

          {error && <MsgResponse msg={error} theme="error" />}

          {
            success
              ?
              <MsgResponse msg={success} theme="success" />
              :
              unificacaoEnviada
                ?
                <MsgResponse msg='Unificando contas, aguarde...' />
                :
                <div className="row fs-0">
                  <div className="col-xs-12 col-sm-auto form-group col-in-block">
                    <Button onClick={() => handleUnificacao('unificar')} theme={themeButton} className='bigger'>Unificar cadastro</Button>
                  </div>
                  <div className="col-xs-12 col-sm-auto form-group col-in-block">
                    <Button onClick={() => handleUnificacao('cancelar_unificar')} >Canclear unificação</Button>
                  </div>
                </div>
          }

        </Section>
      </TemplateHome>
    )
  }

  return (
    <TemplateHome template={location.state?.theme ? location.state.theme : 'student'}>
      <Section>
        <h1>Meu Perfil</h1>

        <WrapForm>
          <div className="left-side">
            <Photo avatar={profileData.avatar ? profileData.avatar_url : DefaultAvatar}>
              <div className="bg"></div>
              <span>Trocar foto</span>
              <input type='file' id='profilePhoto' onChange={e => handlePhotoUser(e)} />
            </Photo>
            {profileData.new_avatar && profileData.new_avatar !== 'remove' && <MsgResponse msg="Foto em modo de pré-visualização. Clique no botão Atualizar para efetivar a troca." theme="alert" />}
            {profileData.new_avatar === 'remove' && <MsgResponse msg="Clique no botão Atualizar para efetivar a remoção." theme="alert" />}
            {profileData.avatar &&
              <div className="text-center">
                <RemovePhoto onClick={() => handleRemovePhoto()}>Remover foto</RemovePhoto>
              </div>
            }

            <hr />

            <WrapChangePassContact>
              <Button to={{
                pathname: `/perfil/trocar-senha`,
                state: { theme: location.state?.theme }
              }} link className="button-nav-change-pass" theme={themeButtonPassword}>Trocar senha</Button>
              {/* <Button to='/perfil/trocar-senha' link className="button-nav-change-pass" theme={themeButtonPassword}>Trocar senha</Button> */}

              <p>
                Precisando de alguma ajuda com seu curso ou algum outro assunto?
                <Link to='/suporte'>Clique aqui e fale conosco.</Link>
              </p>

              <hr />

              <p>
                <Link to='/historico-compras'>Acessar histórico de compras.</Link>
              </p>
            </WrapChangePassContact>

          </div>
          <div className="right-side">

            {
              warningCompleteProfile && <MsgResponse msg='Você deve completar seu cadastro para ter acesso à plataforma!' bigger theme="alert" />
            }
            <h3>Meus dados</h3>

            <WrapOtherCountry isBrazil={isBrazil} handleChangeFieldsCountry={handleChangeFieldsCountry} />

            {
              !isBrazil &&
              <>
                <Label htmlFor='fm_pais'>País:</Label>
                <Select
                  id='fm_pais'
                  data={countries}
                  indexOption={indexForOptionsSelectCoutries}
                  dataOption={dataAttrForOptionsSelectCoutries}
                  onChange={handleChangeCountry('pais')}
                  value={profileData.pais}
                />
              </>
            }

            <Label htmlFor='fm_nome'>Nome ou Razão Social:</Label>
            <Input value={profileData.nome} onChange={handleFormFill('nome')} id='fm_nome' />
            {
              isBrazil ?
                <Label htmlFor='fm_doc'>CPF ou CNPJ:</Label>
                :
                <Label htmlFor='fm_doc'>Documento de identificação:</Label>
            }

            {
              isBrazil ?
                <Input cpfCnpj value={profileData.doc} onChange={handleFormFill('doc')} id='fm_doc' />
                :
                <Input value={profileData.doc} onChange={handleFormFill('doc')} id='fm_doc' />
            }
            <Label htmlFor='fm_email'>E-mail:</Label>
            <Input value={profileData.email} onChange={handleFormFill('email')} id='fm_email' readOnly disabled />
            {
              profileData.email_secundario &&
              <div>
                <div className='row row-half fs-0'>
                  <div className="col-xs-9 col-half col-in-block v-bottom">
                    <Label htmlFor='fm_email'>E-mail secundario:</Label>
                    <SubLabel>Somente para acesso</SubLabel>
                    <Input value={profileData.email_secundario} onChange={handleFormFill('email_secundario')} id='fm_email_secundario' readOnly disabled />
                  </div>
                  <div className="col-xs-3 col-half col-in-block v-bottom form-group">
                    <Button onClick={() => setLighboxTrocaEmail(true)}>Trocar</Button>
                  </div>
                </div>
                <SubLabel style={{ marginTop: '-5px' }}>Você pode trocar o email primário pelo secundario</SubLabel>
                <div className="clearfix"></div>
              </div>

            }
            <Label htmlFor='fm_celular'>Celular:</Label>
            {
              isBrazil ?
                <Input value={profileData.celular} onChange={handleFormFill('celular')} id='fm_celular' mask='+55 (99) 99999-9999' />
                :
                <Input value={profileData.celular} onChange={handleFormFill('celular')} id='fm_celular' />
            }
            <Label htmlFor='fm_empresa'>Empresa:</Label>
            <SubLabel>Opcional</SubLabel>
            <Input value={profileData.empresa} onChange={handleFormFill('empresa')} id='fm_empresa' />
            {
              isBrazil ?
                <Label htmlFor='fm_cep'>CEP:</Label>
                :
                <Label htmlFor='fm_cep'>Código postal:</Label>
            }

            {
              isBrazil ?
                <Input value={profileData.cep} onChange={handleFormFill('cep')} id='fm_cep' mask='99999-999' onBlur={() => handleBlurCep()} />
                :
                <Input value={profileData.cep} onChange={handleFormFill('cep')} id='fm_cep' />
            }

            <Label htmlFor='fm_estado'>Estado:</Label>
            {
              isBrazil ?
                <Select id='fm_estado' data={br_states} indexOption={indexForOptionsSelectState} onChange={handleFormFill('estado')} value={profileData.estado} />
                :
                <Input value={profileData.estado} onChange={handleFormFill('estado')} id='fm_estado' />
            }
            <div className="row row-half">
              <div className="col-sm-6 col-half">
                <Label htmlFor='fm_cidade'>Cidade:</Label>
                <Input value={profileData.cidade} onChange={handleFormFill('cidade')} id='fm_cidade' />
              </div>
              <div className="col-sm-6 col-half">
                <Label htmlFor='fm_bairro'>Bairro:</Label>
                <Input value={profileData.bairro} onChange={handleFormFill('bairro')} id='fm_bairro' />
              </div>
              <div className="col-sm-9 col-half">
                <Label htmlFor='fm_endereco'>Endereço:</Label>
                <Input value={profileData.endereco} onChange={handleFormFill('endereco')} id='fm_endereco' />
              </div>
              <div className="col-sm-3 col-half">
                <Label htmlFor='fm_numero'>Número:</Label>
                <Input value={profileData.numero} onChange={handleFormFill('numero')} id='fm_numero' />
              </div>
              {/* <div className="col-sm-12 col-half">
                <Label htmlFor='fm_complemento'>Complemento:</Label>
                <Input value={profileData.complemento} onChange={handleFormFill('complemento')} id='fm_complemento' />
              </div> */}
            </div>

            {
              auth.user.usuarios_tipos_id === 4 &&
              <div style={{ marginTop: '30px' }}>
                <h3 className="fw-700 mb15">Mini Currículo</h3>
                <Label htmlFor='fm_curriculo'>Digite aqui:</Label>
                <EditorWYSIWYG theme="bubble" value={profileData.resumo_curriculo} onChange={handleFormFill('resumo_curriculo')} id="fm_curriculo" />
                <Label htmlFor='fm_linkedin'>Link do perfil no LinkedIn:</Label>
                <SubLabel>Opcional</SubLabel>
                <Input value={profileData.link_linkedin} onChange={handleFormFill('link_linkedin')} id='fm_linkedin' />
              </div>
            }

            {error && <MsgResponse msg={error} theme="error" />}
            {success && <MsgResponse msg={success} theme="success" />}

            <br />

            <Button onClick={() => handleUpdateUser()}>Atualizar</Button>
            {
              auth.user.usuarios_tipos_id === 4 &&
              <p className="mt30">Para editar informações de recebimento, <Link to={`${BASE_ALIAS_TEACHER}/dados-para-pagamento`}>clique aqui</Link>.</p>
            }
          </div>
        </WrapForm>

      </Section>
      {
        lightboxUnificacao &&
        <LightboxAlert closeLightbox={handleUnificacao} closable={false} buttons={btsLightboxUnificacao} width='720px'>
          <h2 className="tts-alert">ATENÇÃO</h2>
          <p>Verificamos que o número de documento <strong>{profileData.doc}</strong> já esta vinculado a uma conta cadastrada em nosso sistema. Se o documento informado realmente lhe pertence, você deverá unificar seu cadastro para prosseguir.</p>
          <p>Para unificar o cadastro, clique no botão abaixo "Unificar cadastro" e siga as orientações. Uma mensagem de confirmação será enviada para o e-mail cadastrado na conta que anteriormente informou este documento através do e-mail: <strong>{resUserProfile.data?.email}</strong>.</p>
          <p>Acesse o e-mail acima e confirme as instruções enviadas. Feito isso,  volte e recarregue esta tela. Caso não tenha mais acesso ao e-mail acima, entre em contato conosco <a href={`${BASE_URL_SITE}contato`} target='_blank' rel="noopener noreferrer">clicando aqui</a>.</p>
          <p>Se o documento <strong>{profileData.doc}</strong> não pertence a você, por favor, cancele a operação e insira o seu número de documento.</p>
        </LightboxAlert>
      }
      {
        lightboxUnificacaoEnviada &&
        <LightboxAlert closable={false} buttons={btCanclearUnificacao}>
          <h2 className="tts-alert">ATENÇÃO</h2>
          <p>Solicitação de unificação enviada! Cheque seu e-mail para confirmar a solicitação!</p>
          <p className='primaryColor'><b>Estamos aguardando você clicar na verificação que foi enviada por e-mail.</b></p>
          <div className='text-center'>
            <FaSpinner size={35} className='icon-spin' color='#00B0EA' />
          </div>
        </LightboxAlert>
      }
      {
        lighboxTrocaEmail &&
        <LightboxAlert closable={false} buttons={btTrocaEmail}>
          <h2 className="tts-alert">Aviso</h2>
          <p>Você deseja utilizar o endereço de e-mail <b>{auth.user.email_secundario}</b> como o seu e-mail primário?</p>
        </LightboxAlert>
      }
    </TemplateHome>
  )
}
export default Profile