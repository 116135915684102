import React from 'react'
import { SubLabelStyle } from './style'

const SubLabel = ({ children, ...rest }) => {

  return (
    <SubLabelStyle {...rest}>
      {children}
    </SubLabelStyle>
  )
}

export default SubLabel