import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { pt } from 'date-fns/locale'

// conf
import { BASE_URL_SITE, BASE_ALIAS_TEACHER } from "~/services/conf";

// components 
import Button from '~/components/form/Button'
import StatusCourse from './Status'
import LightboxAlertConfirm from '~/components/LightboxAlertConfirm'
import Timer from './Timer'

// styles
import { Container } from './style'
import { color } from '~/styles/variables'
import brasao from '~/assets/logo/inova-brasao.svg'

// functions
import { stripSlashes } from '~/services/functions';

const CursoItem = ({ type, data, history }) => {

  const themeButton = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: '#FFFFFF'
  }

  // Alert Confirm config
  const [alertConfirm, setAlertConfirm] = useState(false)
  const msgAlertConfirm = 'Se você entrar no modo de edição de um curso publicado ele mudará para rascunho e deixará de ser listado para o público (não esqueça de reativá-la quando terminar a edição). Deseja continuar?';
  const confirmAlertFunc = () => history.push(`${BASE_ALIAS_TEACHER}/curso/${data.idcursos}`)
  const cancelAlertFunc = () => setAlertConfirm(false)

  const valor_original = data.valor_original && data.valor_original !== 0 ? data.valor_original.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '';
  const valor = data.valor ? data.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'Grátis';
  if (type === 'ongoing') {
    if (data.status === 26) {
      return (
        <Container ongoing>
          <div className='wrap-disabled'>
            <div className="wrap">
              <div className="wrap-info">
                <h2>{stripSlashes(data.cursos.titulo || '')}</h2>

                {
                  !data.cursos.proprietario &&
                  <div className="wrap-signature">
                    <img src={brasao} alt="Inova Brasão" />
                  </div>
                }

                <div className="warning-disabled">
                  <p>Normalize o pagamento para reativar o curso.</p>
                </div>

              </div>
            </div>
          </div>
        </Container>
      )
    } else {
      return (
        <Container ongoing>
          <Link to={`curso/${data.cursos.url}`} title={stripSlashes(data.cursos.titulo || '')}>
            <div className="wrap">
              <div className="wrap-info">
                <h2>{stripSlashes(data.cursos.titulo || '')}</h2>

                {
                  !data.cursos.proprietario &&
                  <div className="wrap-signature">
                    <img src={brasao} alt="Inova Brasão" />
                  </div>
                }

                {data.iniciado_em &&
                  <span className="date-start">Iniciado em&nbsp;<span className="text-captalize"> {format(new Date(data.iniciado_em), 'MMM/yy', { locale: pt })}</span></span>
                }
              </div>
            </div>
          </Link>
        </Container>
      )
    }
  }
  if (type === 'toBuy') {
    return (
      <Container className={`${valor_original && data.inicio_promo && data.fim_promo && 'item-timer'}`}>
        <a href={`${BASE_URL_SITE}curso/${data.url}`} title={stripSlashes(data.titulo || '')} rel="noopener noreferrer">
          <div className='wrap'>

            {
              data.tags &&
              <div className="wrap-tags">
                <span className="tag">{data.tags}</span>
              </div>
            }

            <div className="wrap-info">
              <h2>{stripSlashes(data.titulo || '')}</h2>

              {
                !data.proprietario &&
                <div className="wrap-signature">
                  <img src={brasao} alt="Inova Brasão" />
                </div>
              }

              {
                valor_original &&
                <span className="price price-off">
                  {valor_original}
                </span>
              }

              <span className="price">
                {valor}
              </span>
            </div>
            <div className="wrap-bt">
              <Button span theme={themeButton}>Conhecer</Button>
            </div>
          </div>
          {
            valor_original && data.inicio_promo && data.fim_promo &&
            <Timer fim_promo={data.fim_promo} />
          }
        </a>
      </Container>
    )
  }
  if (type === 'owner') {
    if (data.status === 1) {
      return (
        <>
          <Container className={`${valor_original && data.inicio_promo && data.fim_promo && 'item-timer'}`}>
            <button onClick={() => setAlertConfirm(true)}>
              <div className="wrap">
                <div className="wrap-info">

                  <StatusCourse status={data.status} />

                  <h2>{stripSlashes(data.titulo || '')}</h2>
                  {
                    valor_original &&
                    <span className="price price-off">
                      {valor_original}
                    </span>
                  }
                  <span className="price">
                    {valor}
                  </span>
                </div>
                <div className="wrap-bt">
                  <Button span theme={themeButton}>Editar</Button>
                </div>
              </div>
              {
                valor_original && data.inicio_promo && data.fim_promo &&
                <Timer fim_promo={data.fim_promo} />
              }
            </button>
          </Container>
          {alertConfirm && <LightboxAlertConfirm desc={msgAlertConfirm} confirmFunc={confirmAlertFunc} cancelFunc={cancelAlertFunc} />}
        </>
      )
    } else {
      return (
        <Container className={`${valor_original && data.inicio_promo && data.fim_promo && 'item-timer'}`}>
          <Link to={`${BASE_ALIAS_TEACHER}/curso/${data.idcursos}`} title={stripSlashes(data.titulo || '')}>
            <div className="wrap">
              <div className="wrap-info">

                <StatusCourse status={data.status} />

                <h2>{stripSlashes(data.titulo || '')}</h2>
                {
                  valor_original &&
                  <span className="price price-off">
                    {valor_original}
                  </span>
                }
                <span className="price">
                  {valor}
                </span>
              </div>
              <div className="wrap-bt">
                <Button span theme={themeButton}>Editar</Button>
              </div>
            </div>
            {
              valor_original && data.inicio_promo && data.fim_promo &&
              <Timer fim_promo={data.fim_promo} />
            }
          </Link>
        </Container>
      )
    }
  }
  return false;
}
export default CursoItem