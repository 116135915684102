import React from 'react'
import Label from '~/components/form/Label'
import { Container, FakeInput, InputFileStyled, AcceptedFileMsg } from './style'

const InputFile = ({ label, fileName, id, accept, children, onChange, ...rest }) => {
  return (
    <Container>
      {
        label &&
        <Label htmlFor={id}>{label}</Label>
      }
      <div className="form-group">
        <InputFileStyled type="file" id={id} accept={accept} onChange={onChange} {...rest} />
        <FakeInput className="fake-file-input">
          <span className="file-name">{fileName}</span>
          <span className="bt-select">Selecionar</span>
        </FakeInput>
        {
          children &&
          <AcceptedFileMsg>{children}</AcceptedFileMsg>
        }
      </div>
    </Container>
  )
}

export default InputFile