import styled from 'styled-components'
import { media, color } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const Container = styled.div`
  max-width: 100%;
  width: 960px;
  border-radius: 8px;
  background: #f5f5f5;
  margin-bottom: 48px;
  padding: 20px 30px;

  ${media.md}{
  }
  ${media.lg}{
    padding: 37px 57px;
    padding-right: 0;
  }

  h4.tts{
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${color.third};
    margin-bottom: 25px;

    img{
      margin-right: 15px;
    }
  }

`
export const WrapContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${media.md}{
    flex-direction: row;
    align-items: flex-start;
  }

.col-filter{
  width: 523px;
  max-width: 100%;
}
.col-resume{
  max-width: 100%;
}

`
export const WrapSalesList = styled.div`
  margin-top: 30px;
`
export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f5f5f5;
  margin-bottom: 14px;


  .desc{
    width: 100%;
    position: relative;
    font-weight: bold;
    ${mixin.fs_head(16)}
    text-align: left;
    color: ${color.second};

    span{
      display: inline;
      background: #f5f5f5;
      padding-right: 5px;
      position: relative;
      z-index: 2;
    }

    &::before{
      content: "";
      position: absolute;
      bottom: 6px;
      left: 0;
      right: 0;
      border-bottom: 2px dotted ${color.second};
    }
  }
  .value{
    white-space: nowrap;
    font-weight: bold;
    ${mixin.fs_head(20)}
    line-height: 20px;
    text-align: center;
    color: ${color.primary};
    padding-left: 9px;
  }
 
`