import React from 'react';

// styles
import { WrapForm } from './style';

// components
import Input from '~/components/form/Input';
import Label from '~/components/form/Label';
import MsgResponse from '~/components/MsgResponse';

// arrays
import Button from '~/components/form/Button';

const FormTestDescription = (props) => {

  const {
    handleFormFill,
    handleSend,
    data,
    error,
    success,
    type
  } = props

  return (
    <WrapForm>

      <Label htmlFor='fm_titulo'>Título do QUIZ (teste):</Label>
      <Input value={data.testes_titulo} onChange={handleFormFill('testes_titulo')} id='fm_titulo' />

      {/* <Label htmlFor='fm_descricao'>Texto descritivo:</Label>
      <Textarea value={data.testes_descricao} onChange={handleFormFill('testes_descricao')} id='fm_descricao' /> */}

      <div className="text-right">
        <Button onClick={() => handleSend()}>{type === 'create' ? 'Criar teste' : 'Salvar'}</Button>
      </div>

      {error && <MsgResponse msg={error} theme="error" />}
      {success && <MsgResponse msg={success} theme="success" />}

    </WrapForm >
  )
}
export default FormTestDescription