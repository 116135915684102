import React, { useContext, useEffect, useState } from 'react';

// Context
import { AuthContext } from '~/hooks/auth'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section } from './style';

// components
import HeaderUser from '~/components/HeaderUser'
import InputFile from '~/components/form/InputFile';
import Button from '~/components/form/Button';
import CertificateBase from './CertificateBase';
import MsgResponse from '~/components/MsgResponse'

// loading
import { LoadingCertificateTeacher } from '~/components/Loading'

// rest
import { usePost } from '~/services/rest'

// functions
import { createCookie } from '~/services/functions'
import ButtonDelete from '~/components/form/ButtonDelete';

const CertificatesTeacher = () => {

  const auth = useContext(AuthContext)

  // state ----------------------
  const [profileData, setProfileData] = useState({
    preview: '',
    new_logo: '',
    new_logo_name: '',
  })
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  // use effect dados usuario
  useEffect(() => {
    if (auth.user) {

      setProfileData({
        new_logo_name: '',
        preview: auth.user.logo ? auth.user.logo_thumb_url : '',
        new_logo: null,
      })

    }
  }, [auth.user])

  // res -------------------
  const [resLogoProfile, changeLogoProfile] = usePost(`usuarios/${profileData.idusuarios}/logo`) // atualiza foto de perfil

  // ação atualiza logo --
  const handleUpdateLogo = async () => {

    setError(null)

    // faz upload de novo avatar se houver
    if (profileData.new_logo) {
      if (profileData.new_logo === 'remove') {
        await changeLogoProfile({ logo: null }) // caso queira remover logo
      } else {
        await changeLogoProfile(profileData.new_logo)
      }
    } else {
      setError('Selecione uma imagem')
    }
  }

  // efeito atualiza logo --------
  useEffect(() => {
    if (resLogoProfile.error) {
      setError(resLogoProfile.error)

    } else if (resLogoProfile.success) {

      // atualiza cookie de usuário
      createCookie('@InovaOnline:user', JSON.stringify(resLogoProfile.data))
      setSuccess("Atualizando, aguarde...")

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [resLogoProfile])


  const handleLogo = e => {
    const new_logo = Array.from(e.target.files)

    if (new_logo.length > 0) {

      const formData = new FormData()
      formData.append('logo', new_logo[0])

      setProfileData({
        new_logo: formData,
        new_logo_name: new_logo[0].name,
        preview: URL.createObjectURL(new_logo[0])
      })
    } else {
      setProfileData({
        new_logo: 'remove',
        new_logo_name: '',
        preview: auth.user.logo ? auth.user.logo_thumb_url : ''
      })

    }

    return false;
  }


  const handleRemovePhoto = async () => {
    setProfileData({
      preview: null,
      new_logo: 'remove',
      new_logo_name: null
    })
  }

  return (
    <TemplateHome template="teacher">
      <HeaderUser />

      <Section>
        <h2>Configurar Certificado</h2>

        <div className="row">
          <div className="col-xs-12 col-xl-4 grid-form">
            <p>Se você tiver um logotipo, poderá publicar aqui para que o mesmo seja impresso nos certificados emitidos aos usuários que concluírem os cursos que você ofertou. Caso não tenha, não tem problema pois iremos imprimir com suas iniciais.</p>

            <InputFile label="Seu logo" fileName={profileData.new_logo_name} id="fm_file" accept="image/jpeg, image/png" onChange={e => handleLogo(e)}>
              São aceitos arquivos nas extensões PNG, JPG, JPEG - dimensões recomendadas 800x800px.
            </InputFile>

            {
              profileData.preview &&
              <div style={{ marginBottom: '30px' }}>
                <ButtonDelete onClick={() => handleRemovePhoto()}>Remover logo</ButtonDelete>
              </div>
            }

            <Button onClick={() => handleUpdateLogo()}>Atualizar</Button>

            {error && <MsgResponse msg={error} theme="error" />}
            {success && <MsgResponse msg={success} theme="success" />}

          </div>
          <div className="col-xs-12 col-xl-8">

            {
              auth.user ?
                <CertificateBase user={auth.user} preview={profileData.preview} />
                :
                <LoadingCertificateTeacher />
            }

          </div>
        </div>

      </Section>

    </TemplateHome>
  )
}
export default CertificatesTeacher