import styled from 'styled-components'
import { font, media } from '~/styles/variables'

export const ContainerMenu = styled.nav`
  width: 100%;

  ul{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
      display: block;

      &:not(:last-child){
        margin-bottom: 30px;
      }

      a, button{
        display: block;
        width: 100%;
        font-family: ${font.second};
        font-size: 12px;
        background: none;
        outline: none;
        border: none;
        color: #FFFFFF;
        opacity: ${props => props.theme === 'teacher' ? 1 : 0.4};
        /* opacity: .4; */
        text-align: center;
        padding: 5px;
        line-height: 1;

        ${media.xl}{
          font-size: 14px;
        }

        span.text{
          ${media.max_xs}{
            display: none;
          }
        }

        img{
          display: block;
          margin: 0 auto 15px auto;

          ${media.max_md}{
            width: 30px;
          }
        }

        &:hover{
          opacity: ${props => props.theme === 'teacher' ? 0.6 : 1};
        }
      }
    }
  }
`