import { css } from 'styled-components'
import {
  media,
  leftSidebar,
  rightSidebar,
  headerCourse,
  headerCreateCourse,
  paddingGrid,
  sidebarCourse,
  leftOffsetContentCourse
} from '~/styles/variables'

// elements 
export const gridHeaderCourse = css`
  padding-left: ${leftSidebar.xs.width + paddingGrid.sm.lr}px;
  padding-right: ${paddingGrid.sm.lr}px;
  height: ${headerCourse.xs.height}px;

  ${media.sm}{
    left: ${leftSidebar.sm.width}px;
    right: ${rightSidebar.sm.width}px;
    height: ${headerCourse.sm.height}px;
    padding-left: ${paddingGrid.sm.lr}px;
    padding-right: 0;
  }
  ${media.lg}{
    height: ${headerCourse.lg.height}px;
    right: ${rightSidebar.lg.width}px;
    left: ${leftSidebar.lg.width}px;
    padding-left: ${paddingGrid.lg.lr}px;
  }
  ${media.xl}{
    left: ${leftSidebar.xl.width}px;
    right: ${rightSidebar.xl.width}px;
    height: ${headerCourse.xl.height}px;
    padding-left: ${paddingGrid.xl.lr}px;
  }
`
export const gridHeaderCreateCourse = css`
  ${gridHeaderCourse};
  height: auto;
  padding-top: 40px;

  ${media.sm}{
    padding-top: 0;
    height: ${headerCreateCourse.sm.height}px;
  }
  ${media.lg}{
    height: ${headerCreateCourse.lg.height}px;
  }
  ${media.xl}{
    height: ${headerCreateCourse.xl.height}px;
  }
`
export const gridLeftSidebar = css`
  width: ${leftSidebar.xs.width}px;
  
  ${media.sm}{
    width: ${leftSidebar.sm.width}px;
  }
  ${media.lg}{
    width: ${leftSidebar.lg.width}px;
  }
  ${media.xl}{
    width: ${leftSidebar.xl.width}px;
  }
`
export const gridRightSideBar = css`
  padding-left: ${leftSidebar.xs.width + paddingGrid.sm.lr}px;
  padding-right: ${paddingGrid.sm.lr}px;
  padding-bottom: ${paddingGrid.sm.tb}px;

  ${media.sm}{
    width: ${rightSidebar.sm.width}px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  ${media.lg}{
    width: ${rightSidebar.lg.width}px;
  }
  ${media.xl}{
    width: ${rightSidebar.xl.width}px;
  }
`
export const gridSidebarCourse = css`
  padding-left: ${leftSidebar.xs.width + paddingGrid.xs.lr}px;
  padding-right: ${paddingGrid.xs.lr}px;

  ${media.sm}{
    width: ${sidebarCourse.sm.width}px;
    top: ${headerCourse.sm.height}px;
    left: ${leftSidebar.sm.width}px;
    padding-left: ${paddingGrid.sm.lr}px;
    padding-right: 0;
  }
  ${media.lg}{
    width: ${sidebarCourse.lg.width}px;
    top: ${headerCourse.lg.height}px;
    left: ${leftSidebar.lg.width}px;
    padding-left: ${paddingGrid.lg.lr}px;
  }
  ${media.xl}{
    width: ${sidebarCourse.xl.width}px;
    top: ${headerCourse.xl.height}px;
    left: ${leftSidebar.xl.width}px;
    padding-left: ${paddingGrid.xl.lr}px;
  }
`
export const gridSidebarCreateCourse = css`
  ${gridSidebarCourse};

  ${media.sm}{
    top: ${headerCreateCourse.sm.height}px;
  }
  ${media.lg}{
    top: ${headerCreateCourse.lg.height}px;
  }
  ${media.xl}{
    top: ${headerCreateCourse.xl.height}px;
  }
`

// global style
export const gridMainContentOffset = css`
  padding-left: ${leftSidebar.xs.width}px;

  ${media.sm}{
    padding-left: ${leftSidebar.sm.width}px;
    padding-right: ${rightSidebar.sm.width}px;
  }
  ${media.lg}{
    padding-left: ${leftSidebar.lg.width}px;
    padding-right: ${rightSidebar.lg.width}px;
  }
  ${media.xl}{
    padding-left: ${leftSidebar.xl.width}px;
    padding-right: ${rightSidebar.xl.width}px;
  }
`
export const gridMainContentCourseOffset = css`
  padding-left: ${leftOffsetContentCourse.xs.offset}px;

  ${media.sm}{
    padding-top: ${headerCourse.sm.height}px;
    padding-left: ${leftOffsetContentCourse.sm.offset}px;
    padding-right: ${rightSidebar.sm.width}px;
  }
  ${media.lg}{
    padding-top: ${headerCourse.lg.height}px;
    padding-left: ${leftOffsetContentCourse.lg.offset}px;
    padding-right: ${rightSidebar.lg.width}px;
  }
  ${media.xl}{
    padding-top: ${headerCourse.xl.height}px;
    padding-left: ${leftOffsetContentCourse.xl.offset}px;
    padding-right: ${rightSidebar.xl.width}px;
  }
`
export const gridMainContentCreateCourseOffset = css`
  ${gridMainContentCourseOffset};

  ${media.sm}{
    padding-top: ${headerCreateCourse.sm.height}px;
  }
  ${media.lg}{
    padding-top: ${headerCreateCourse.lg.height}px;
  }
  ${media.xl}{
    padding-top: ${headerCreateCourse.xl.height}px;
  }
`
export const gridMainContent = css`
  padding: ${paddingGrid.xs.tb}px ${paddingGrid.xs.lr}px;

  ${media.sm}{
    padding: ${paddingGrid.sm.tb}px ${paddingGrid.sm.lr}px;
  }
  ${media.lg}{
    padding: ${paddingGrid.lg.tb}px ${paddingGrid.lg.lr}px;
  }
  ${media.xl}{
    padding: ${paddingGrid.xl.tb}px ${paddingGrid.xl.lr}px;
  }
`