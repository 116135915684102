import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'

// style
import { ButtonClassFinished, Container, MsgResponseValidation, WrapValidations } from './style'
import { color } from '~/styles/variables'

// icons
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'

// rest
import { usePut } from '~/services/rest'
import MsgResponse from '~/components/MsgResponse'
import { BASE_ALIAS_TEACHER } from '~/services/conf'

const ButtonPublishCourse = ({ curso }) => {

  const themeButtonClassFinished = {
    disabled: {
      bg: '#f9f9f9',
      fc: '#CCCCCC',
      bdc: '#f9f9f9'
    },
    notFinished: {
      bg: '#CCC',
      fc: color.second,
      bdc: '#CCC'
    },
    finished: {
      bg: color.primary,
      fc: '#FFFFFF',
      bdc: color.primary
    }
  }

  const [resUpdate, update] = usePut(`cursos/${curso?.idcursos}`)
  const [status, setStatus] = useState(2)
  const [validations, setValidations] = useState([])
  const [showValidations, setShowValidations] = useState(false)
  const [redirectCourses, setRedirectCourses] = useState(false)

  // controle de mensagens de resposta
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setError('')

    setShowValidations(false)
    if (Object.keys(resUpdate.data).length > 0 && !resUpdate.data.error) {
      if (resUpdate.data.curso.status === 1) {
        setStatus(resUpdate.data.curso.status)
        setSuccess('Curso pulicado! Você será redirecionado...')

        setTimeout(() => {
          setRedirectCourses(true)
        }, 1500);
      }
    } else if (resUpdate.data.error) {
      setError(resUpdate.error)
    }
  }, [resUpdate])

  useEffect(() => {
    setStatus(curso?.status)
    setValidations([])

    if (curso) {
      if (!curso.video_id) setValidations(p => ([...p, 'Curso sem vídeo de apresentação!']))
      if (!curso.vimeo_upload?.disponivel) {
        setValidations(p => ([...p, `Espere o vídeo de apresentação ser publicado!`]))
      }
      if (!curso.categorias || curso.categorias.length === 0) {
        setValidations(p => ([...p, `Curso sem categorias!`]))
      }
      if (curso.modulos.length === 0) {
        setValidations(p => ([...p, 'Curso sem modulos cadastrados!']))
      } else {
        curso.modulos.forEach(itemModule => {
          if (itemModule.aulas.length === 0) {
            setValidations(p => ([...p, `Modulo "${itemModule.titulo}" sem aulas cadastradas!`]))
          } else {
            itemModule.aulas.forEach(itemClass => {
              if (!itemClass.video_id) {
                setValidations(p => ([...p, `Aula "${itemClass.titulo}" sem vídeo!`]))
              } else {
                if (!itemClass.vimeo_upload?.disponivel) {
                  setValidations(p => ([...p, `Espere o vídeo da aula "${itemClass.titulo}" ser publicado!`]))
                }
              }
            })
          }
        });
      }
    }

  }, [curso])

  // desabilita curso assim que entra na tela
  useEffect(() => {
    if (curso.status === 1) {
      update({ status: 2 })
      setStatus(2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFinishCourse = async () => {

    if (validations.length > 0) {
      setShowValidations(true)
      return false;
    } else {
      const newStatus = status === 1 ? 2 : 1
      await update({
        status: newStatus
      })
    }
  }

  if (redirectCourses) {
    return <Redirect to={`${BASE_ALIAS_TEACHER}/cursos`} />
  }

  return (
    <Container>
      {
        status === 1
          ?
          <ButtonClassFinished
            theme={themeButtonClassFinished.finished}
            onClick={() => handleFinishCourse()}>
            <FaCheckCircle /> Curso ativo, clique para desativar.
          </ButtonClassFinished>
          :
          <ButtonClassFinished
            theme={themeButtonClassFinished.notFinished}
            onClick={() => handleFinishCourse()}>
            <FaRegCircle /> Curso inativo, clique para ativar.
          </ButtonClassFinished>
      }

      {error && <MsgResponse msg={error} theme="error" />}
      {success && <MsgResponse msg={success} theme="success" />}

      {
        showValidations &&
        <WrapValidations>
          <p>Para publicar o curso, por favor, resolva as pendências abaixo: <br /> (atualize a página caso já tenha resolvido todas pendências)</p>
          {validations.map((item, index) =>
            <MsgResponseValidation key={index}>
              <div className="item"> {item} </div>
            </MsgResponseValidation>
          )}
        </WrapValidations>
      }
    </Container >
  )

}
export default ButtonPublishCourse