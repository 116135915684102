import React from 'react'

// style
import { Wrap } from './style'

const InputCheckbox = ({ label, ...rest }) => {
  return (
    <Wrap>
      <label>
        <input type="radio" {...rest} />
        <div className="circle-check"></div>
      </label>
    </Wrap>
  )
}
export default InputCheckbox