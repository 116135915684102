import React from 'react';

// styles
import { Item } from './style';

const ItemStatistic = (props) => {

  const content = { ...props }

  return (
    <Item>
      {
        content.title &&
        <>
          <img src={content.icon} alt={content.title} />
          <h4>{content.title}</h4>
        </>
      }
      <span className="label-value">Valor líquido</span>
      <h3 className="liquid-value">{content.liquidValue}</h3>
      <hr />
      <span className="label-value">Valor bruto</span>
      <h3 className="gross-value">{content.grossValue}</h3>
      <h5>{content.courseSold} <span>cursos vendidos</span></h5>
    </Item>
  )
}
export default ItemStatistic