import axios from 'axios';
import { BASE_URL_API } from './conf';
import { getCookie } from './functions';

const api = axios.create({
  baseURL: BASE_URL_API,
});

export const apiAxio = axios

api.defaults.validateStatus = code => code < 500
apiAxio.defaults.validateStatus = code => code < 500

const tokenCookie = getCookie('@InovaOnline:token')
if (tokenCookie && tokenCookie !== 'undefined') { // checa se há token no cookie
  api.defaults.headers.common['Authorization'] = `bearer ${tokenCookie}`
  apiAxio.defaults.headers.common['Authorization'] = `bearer ${tokenCookie}`
}

export default api;