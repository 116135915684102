import React from 'react';

// styles
import { WrapForm } from './style';

// components
import Input from '~/components/form/Input';
import Label from '~/components/form/Label';
import MsgResponse from '~/components/MsgResponse';
import Button from '~/components/form/Button';
import ButtonDelete from '~/components/form/ButtonDelete';

const FormModule = (props) => {

  const {
    handleFormFill,
    handleSend,
    handleDeleteModule,
    data,
    idModule,
    error,
    success,
    type
  } = props

  return (
    <WrapForm>

      <Label htmlFor='fm_titulo'>Título do módulo:</Label>
      <Input value={data.titulo} onChange={handleFormFill('titulo')} id='fm_titulo' />

      {/* <Label htmlFor='fm_subtitulo'>Subtítulo:</Label>
      <SubLabel>Ex: Módulo 1 ou Módulo Único</SubLabel>
      <Input value={data.subtitulo} onChange={handleFormFill('subtitulo')} id='fm_subtitulo' /> */}

      {/* <Label htmlFor='fm_descricao'>Apresente o módulo:</Label>
      <SubLabel>Opcional</SubLabel>
      <Textarea value={data.descricao} onChange={handleFormFill('descricao')} id='fm_descricao' /> */}

      <div className="row">
        <div className="col-xs-6">
          {
            idModule &&
            <ButtonDelete width='100px' onClick={() => handleDeleteModule()}>Excluir Modulo</ButtonDelete>
          }
        </div>
        <div className="col-xs-6">
          <Button className="pull-right" onClick={() => handleSend()}>{type === 'create' ? 'Avançar' : 'Salvar'}</Button>
        </div>
      </div>

      {error && <MsgResponse msg={error} theme="error" />}
      {success && <MsgResponse msg={success} theme="success" />}

    </WrapForm >
  )
}
export default FormModule