import styled from 'styled-components'
import { media } from '~/styles/variables'

// grid
import { gridHeaderCourse } from '~/styles/gridSystem'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #FFFFFF;
  flex-direction: column;
  z-index: 2;
  ${gridHeaderCourse}

  ${media.sm}{
    position: fixed;
    top: 0;
  }

  h1{
    margin: 0;

    ${media.max_xs}{
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
`