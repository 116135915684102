import styled from 'styled-components'
import { media, color, font } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const Container = styled.div`
  max-width: 100%;
  width: 960px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #f5f5f5;
  margin-bottom: 48px;

  ${media.md}{
    flex-direction: row;
  }
`
export const Item = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f5f5f5;
  padding: 20px 30px;

  ${media.lg}{
    padding: 30px 57px;
  }

  img{
    margin-bottom: 10px;
  }

  hr{
    margin: 5px 0;
    border-color: #707070;
    width: 100%;
  }

  h4{
    text-align: center;
    color: ${color.third};
    font-weight: 700;
  }
  span.label-value{
    display: block;
    width: 100%;
    font-family: ${font.second};
    font-size: 10px;
    line-height: 25px;
    text-align: right;
    color: ${color.second};
  }
  h3{
    text-align: center;
    margin-bottom: 15px;
    white-space: nowrap;

    &.liquid-value{
      ${mixin.fs_head(40)};
      color: #000;
      font-weight: 900;
    }
    &.gross-value{
      ${mixin.fs_head(23)};
      color: ${color.third};
      font-weight: 800;
    }
  }
  h5{
    font-weight: 700;
    font-size: 30px;
    ${mixin.fs_head(30)};
    line-height: 20px;
    text-align: center;
    color: ${color.primary};
    margin-bottom: 0;
    text-align: center;

    ${media.lg}{
      margin-top: 20px;
    }

    span{
      display: block;
      font-family: ${font.second};
      color: ${color.third};
      font-weight: 400;
      font-size: 40%;
      margin-top: 5px;
    }
  }
`