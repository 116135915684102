import React from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom'

// style
import { Item } from './style'

// icons
// import { FaCheckCircle } from 'react-icons/fa'


// functions
import { stripSlashes } from '~/services/functions'
import { BASE_ALIAS_TEACHER } from '~/services/conf'

const SubMenuItem = ({ data }) => {

  const params = useParams()

  return (
    <Item
      // status={dataClass.status}
      active={+params.idModule === +data.idmodulos}
    >

      <Link to={`${BASE_ALIAS_TEACHER}/curso/${data.cursos_id}/modulo/${data.idmodulos}`}>
        {/* {
            dataClass.status === 11 &&
            <span className='icon-finished'>
              <FaCheckCircle size={17} />
            </span>
          } */}
        {stripSlashes(data.titulo)}
      </Link>
    </Item>
  )

}
export default SubMenuItem