import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from "moment";

// templates
import TemplateCreateCourse from '~/templates/CreateCourse'

// elements
import CourseSidebar from '~/elements/CreateCourseSidebar'
import Header from '~/elements/HeaderCreateCourse'

// styles
import { Section } from './style';

// rest
import { useDelete, useGet, usePost, usePut } from '~/services/rest'

// pages
import FormCourse from '~/pages/teacher-area/courses-maintenance/forms-components/Course'

// conf
import { BASE_ALIAS_TEACHER } from '~/services/conf';
import ButtonPublishCourse from '../ButtonPublish';
import { stripSlashes } from '~/services/functions'

const initialStateDataForm = {
  titulo: '',
  subtitulo: '',
  chamada_publicitaria: '',
  valor: 0,
  valor_promocional: 0,
  inicio_promo: '',
  fim_promo: '',
  carga_horaria: '',
  descricao: '',
  informacoes_adicionais: '',
  categorias: [],
  segmentos_id: 3,
  status: 2
}

const CourseMaintenance = ({ history }) => {

  // parametros de rota
  const { idCourse } = useParams()

  // dados
  const [dataForm, setDataForm] = useState(initialStateDataForm)
  const [dataCategorias, setDataCategorias] = useState([])

  // controles de refetch e redirecionamento
  const [refetchSidebar, setRefetchSidebar] = useState(false)
  const [refetchHeader, setRefetchHeader] = useState(false)

  // controle de mensagens de resposta
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  // controle de requisições
  const dataCourse = useGet(idCourse ? `cursosprofessores/${idCourse}` : false)
  const [resCreate, create] = usePost(`cursos`)
  const [resUpdate, update] = usePut(`cursos/${idCourse}`)
  const [resDeleteCourse, deleteCourse] = useDelete(`modulos/${idCourse}`)

  // lista categorias default
  // const categorias_default = useGet(`categorias/default/true`)

  // video controls
  const [, deleteVideo] = useDelete()
  const [deleteVideoStatus, setDeleteVideoStatus] = useState(false)

  // se for update puxa os dados
  useEffect(() => {
    if (dataCourse.success && dataCourse.data.curso.length > 0) {
      setDataForm(
        p => ({
          ...p,
          titulo: stripSlashes(dataCourse.data.curso[0].titulo || ''),
          subtitulo: stripSlashes(dataCourse.data.curso[0].subtitulo || ''),
          chamada_publicitaria: stripSlashes(dataCourse.data.curso[0].chamada_publicitaria || ''),
          carga_horaria: dataCourse.data.curso[0].carga_horaria || '',
          descricao: stripSlashes(dataCourse.data.curso[0].descricao || ''),
          informacoes_adicionais: stripSlashes(dataCourse.data.curso[0].informacoes_adicionais || ''),
          valor: dataCourse.data.curso[0]?.valor_original ? dataCourse.data.curso[0]?.valor_original : dataCourse.data.curso[0].valor || 0,
          valor_promocional: dataCourse.data.curso[0].valor_promocional || 0,
          inicio_promo: dataCourse.data.curso[0].inicio_promo ? moment(dataCourse.data.curso[0].inicio_promo).format("L") : '',
          fim_promo: dataCourse.data.curso[0].fim_promo ? moment(dataCourse.data.curso[0].fim_promo).format("L") : '',
          video_id: dataCourse.data.curso[0].video_id || '',
          video_url: dataCourse.data.curso[0].video_url || '',
          vimeo_upload: dataCourse.data.curso[0].vimeo_upload || '',
        })
      )
      // pega id das categorias cadastradas
      const auxCateg = [];
      dataCourse.data.curso[0].categorias.forEach(item => {
        auxCateg.push(item.idcategorias)
      });
      setDataCategorias(auxCateg);

      /* BKP CATEGORIAS DEFAULT */
      /*
      // pega categorias cadastradas como default no banco de dados
      if (categorias_default.success && categorias_default.data.length > 0) {
        const categsDefault = []
        const auxCateg = [];
        categorias_default.data.forEach(item => {
          categsDefault.push(item.idcategorias) // pega os ids das categorias default
        });
        dataCourse.data.curso[0].categorias.forEach(item => {
          if (!categsDefault.includes(item.idcategorias)) auxCateg.push(item.idcategorias) // verifica se tem cateoria default cadastrada
        });
        setDataCategorias(auxCateg);
      }
       */
      /* BKP CATEGORIAS DEFAULT */
    }
  }, [dataCourse.data, dataCourse.success])

  // função que preenche os dados do form
  const handleFormFill = field => evt => {

    let value = '';

    if (evt?.target?.value || evt?.target?.value === '') {
      value = evt.target.value
    } else {
      value = evt
    }

    setDataForm(
      p => ({
        ...p,
        [field]: value
      })
    )
  }

  const handleChangeCurrency = (evt, value, maskedValue) => {
    evt.preventDefault();

    const field = evt.target.name;

    setDataForm(
      p => ({
        ...p,
        [field]: value
      })
    )
  };

  const handleChangeCategoria = (evt) => {
    const current_value = parseInt(evt.target.value);

    let categControl = dataCategorias.slice();

    // verifica se a categoria ja esta selecionada
    if (!categControl.includes(current_value)) {
      categControl.push(current_value)
    } else {
      categControl = categControl.filter(value => {
        return value !== current_value;
      });
    }

    setDataCategorias(categControl);
  };

  const refetchData = () => {
    dataCourse.refetch(dataCourse.refetchCancelToken)
  }

  // envia formulário
  const handleSend = async () => {

    setSuccess(null)
    setError(null)

    if (
      !dataForm.titulo ||
      !dataForm.valor ||
      !dataForm.carga_horaria ||
      !dataForm.informacoes_adicionais ||
      !dataForm.descricao
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    if (dataForm.chamada_publicitaria && dataForm.chamada_publicitaria.length > 100) {
      setError('A chamada publicitária deve conter menos de 100 caracteres. Atualmente: ' + dataForm.chamada_publicitaria.length)
      return false
    }

    const dataSend = {
      ...dataForm,
    }

    if (dataSend.valor < 19.90) {
      setError('Preço do curso está abaixo do mínimo indicado!')
      return false
    }
    if (dataSend.valor_promocional) {
      if (dataSend.valor_promocional >= dataSend.valor) {
        setError('Preço promocional não pode ser maior ou igual ao que prço de venda')
        return false
      }
    } else {
      dataSend.valor_promocional = null
    }

    // valida datas de promoção --------------
    if (dataSend.inicio_promo) {
      if (moment(dataSend.inicio_promo, "L", true).isValid()) {
        dataSend.inicio_promo = moment(dataSend.inicio_promo, "L").format();

      } else {
        setError('Data de início da promoção inválida')
        return false
      }
    } else {
      dataSend.inicio_promo = null
    }

    if (dataSend.fim_promo) {
      if (!dataSend.inicio_promo) {
        setError('Por favor informe a data de início da promoção!')
        return false
      }
      if (moment(dataSend.fim_promo, "L", true).isValid()) {
        dataSend.fim_promo = moment(dataSend.fim_promo, "L").add(86399, 'seconds').format(); //add segundos equivalentes a 23:59:59 para chegar até o final do dia

        if (moment(dataSend.inicio_promo).isSame(dataSend.fim_promo) || moment(dataSend.inicio_promo).isAfter(dataSend.fim_promo)) {
          setError('Data final da promoção deve ser maior que data de início')
          return false
        }

      } else {
        setError('Data de fim da promoção inválida')
        return false
      }
    } else {
      dataSend.fim_promo = null
    }
    // valida datas de promoção --------------

    // valida categorias ----------------
    if (!dataCategorias || dataCategorias.length === 0) {
      setError('Você deve selecionar ao menos uma categoria!')
      return false
    }

    dataSend.categorias = [];
    dataCategorias.forEach(item => {
      const objCateg = {
        cursos_id: parseInt(idCourse) || '',
        categorias_id: parseInt(item)
      }
      dataSend.categorias.push(objCateg)
    })

    // valida categorias ----------------

    if (idCourse) {
      update(dataSend)
    } else {
      create(dataSend)
    }
  }

  const handleDeleteCourse = async () => {
    await deleteCourse(`cursos/${idCourse}`)
  }

  // RESPONSE CREATE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resCreate.success && (resCreate.data || resCreate.data.length > 0)) {
      setSuccess('Curso criado, você já pode enviar seu vídeo de apresentação!')

      history.push(`${BASE_ALIAS_TEACHER}/curso/${resCreate.data.curso.idcursos}`)

    } else if (resCreate.error) {
      setError(resCreate.error)
    }
  }, [resCreate, history])

  // RESPONSE UPDATE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resUpdate.success && (resUpdate.data || resUpdate.data.length > 0)) {
      setSuccess('Curso atualizado')
      setRefetchHeader(true);
    } else if (resUpdate.error) {
      setError(resUpdate.error)
    }
  }, [resUpdate])

  // RESPONSE DELETE  -----
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resDeleteCourse.success && (resDeleteCourse.data || resDeleteCourse.data.length > 0)) {
      history.push(`${BASE_ALIAS_TEACHER}/cursos/`)
    } else if (resDeleteCourse.error) {
      setError(resDeleteCourse.error)
    }
  }, [resDeleteCourse, history])

  // video controls --------------------

  // excluir vídeo
  const handleDeleteVideo = async () => {
    setDeleteVideoStatus(true);
    await deleteVideo(`cursos/${idCourse}/video`);
    await refetchData()
  }

  // components
  const ComponentCourseSidebar = <CourseSidebar activeMenu="course" refetch={{ do: refetchSidebar, set: setRefetchSidebar }} />
  const ComponentHeader = <Header refetch={{ do: refetchHeader, set: setRefetchHeader }} />

  return (
    <TemplateCreateCourse
      template="teacher"
      Header={ComponentHeader}
      Sidebar={ComponentCourseSidebar}
    >
      {
        dataCourse.data?.curso &&
        <ButtonPublishCourse curso={dataCourse.data?.curso[0]} />
      }
      <Section>
        <FormCourse
          handleFormFill={handleFormFill}
          handleChangeCurrency={handleChangeCurrency}
          handleChangeCategoria={handleChangeCategoria}
          handleSend={handleSend}
          handleDeleteVideo={handleDeleteVideo}
          deleteVideoStatus={deleteVideoStatus}
          handleDeleteCourse={handleDeleteCourse}
          data={dataForm}
          dataCategorias={dataCategorias}
          refetchData={refetchData}
          idCourse={idCourse}
          error={error}
          success={success}
          type={idCourse ? 'update' : 'create'}
        />

        {
          !idCourse &&
          <p className="msg-curso-marcelo">Caso queira uma referência,<br /> visite um de nossos cursos, <a href="https://inovaol.com/cursos" title="Cursos" target="_blank" rel="noopener noreferrer">clicando aqui</a></p>
        }
      </Section>
    </TemplateCreateCourse>
  )
}
export default CourseMaintenance