import React from 'react'

// images
import logo from '~/assets/logo/inova-online-n.v2.svg'
import leftArrow from '~/assets/icons/left-arrow.svg'


//style 
import { MainContainer, Content, Logo, BackButton, WrapButtonsSelection } from './style'

// main style
import { color } from '~/styles/variables'

//conf
import { BASE_ALIAS_TEACHER, BASE_URL_SITE } from '~/services/conf'

// components
import HeaderUser from '~/components/HeaderUser'
import Button from '~/components/form/Button'

const AreaSelection = () => {

  const themeButtonTeacher = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: color.primary
  }

  return (
    <MainContainer>
      <Content bg={color.second}>
        <BackButton href={`${BASE_URL_SITE}`} title="Ir para o site">
          <img src={leftArrow} alt="Seta para trás" />
          Ir para o site
        </BackButton>
        <Logo src={logo} alt="Inova Online" width={322} />
      </Content>
      <Content>
        <HeaderUser />

        <WrapButtonsSelection>
          <p>Selecione qual ambiente deseja acessar:</p>

          <div className="item">
            <Button link to="/">Sou aluno/a</Button>
            <span>Aqui você visualizará os cursos adquiridos.</span>
          </div>

          <div className="item">
            <Button link to={BASE_ALIAS_TEACHER} theme={themeButtonTeacher}>Sou professor/a</Button>
            <span>Professor, aqui você realiza a gestão dos cursos que você leciona.</span>
          </div>

        </WrapButtonsSelection>
      </Content>
    </MainContainer>
  )
}

export default AreaSelection