import React, { useEffect } from 'react'
import { useParams } from 'react-router'

// style
import { Container } from './style'

// internal components
import Menu from './Menu'
import MenuItem from './Menu/MenuItem'

// config
import { BASE_ALIAS_TEACHER } from '~/services/conf'
import { useGet } from '~/services/rest'
import Button from '~/components/form/Button'

const Sidebar = props => {

  const params = useParams()
  const { activeMenu, refetch } = props

  const dataModule = useGet(params.idCourse ? `modulos/curso/${params.idCourse}` : false)

  useEffect(() => {
    if (refetch.do) {
      refetch.set(false)
      dataModule.refetch(dataModule.refetchCancelToken)
    }
  }, [refetch, dataModule])

  return (
    <Container>
      <Menu>
        <MenuItem
          active={activeMenu === 'course'}
          title="Apresentação do curso"
          subTitle="Página de venda do seu curso"
          linkActive
          modules={[]}
          link={`${BASE_ALIAS_TEACHER}/curso/${params.idCourse ? params.idCourse : ''}`}
        />
        <MenuItem
          active={activeMenu === 'module'}
          title="Módulos"
          subTitle="Aulas e testes"
          modules={dataModule.data}
          linkActive={params.idCourse ? true : false}
          link={`${BASE_ALIAS_TEACHER}/curso/${params?.idCourse && params.idCourse}/modulo`}
        />
      </Menu>
      {
        activeMenu === 'module' &&
        <div style={{ paddingLeft: 30 }}>
          <Button link to={`${BASE_ALIAS_TEACHER}/curso/${params.idCourse}/modulo`} className="smaller" title="Novo">Novo</Button>
        </div>
      }
    </Container>
  )
}
export default Sidebar