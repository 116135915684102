import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

// conf
import { BASE_ALIAS_TEACHER } from '~/services/conf'

// 404
import NoPageFound from '~/pages/PageNotFound'

// PÁGINAS ALUNO -------------------
import Login from '~/pages/Login'
import RecoveryPassword from '~/pages/Login/RecoveryPassword'
import Home from '~/pages/student-area/Home'
import Course from '~/pages/student-area/Course'
import Certificates from '~/pages/student-area/Certificates'
import Profile from '~/pages/Profile'
import ChangePassword from '~/pages/Profile/ChangePassword'
import Support from '~/pages/student-area/Support'
import PurchasesHistoric from '~/pages/student-area/PurchasesHistoric'
import CourseAvaliation from '~/pages/student-area/CourseAvaliation'

// PÁGINAS PROFESSOR -------------------
import HomeTeacher from '~/pages/teacher-area/Home'
import CoursesTeacher from '~/pages/teacher-area/Courses'
import CertificatesTeacher from '~/pages/teacher-area/Certificates'
import ReportsPayments from '~/pages/teacher-area/ReportsPayments'
import DataPayments from '~/pages/teacher-area/DataPayments'

import CourseNotFount from '~/pages/teacher-area/CourseNotFound'
import CourseMaintenance from '~/pages/teacher-area/courses-maintenance/Course'
import ModuleMaintenance from '~/pages/teacher-area/courses-maintenance/Module'
import ClassMaintenance from '~/pages/teacher-area/courses-maintenance/ClassMain'

// OUTRAS PÁGINAS ------------------
import AreaSelection from '~/pages/AreaSelection'
import SignupTeacher from '~/pages/ProfileTeacher'
import PoliticasPrivacidade from '~/pages/PoliticasPrivacidade'

const Routes = () => {

  return (
    <Switch>
      <Route path="/politica-de-privacidade" exact component={PoliticasPrivacidade} />
      <Route path="/login" exact component={Login} />
      <Route path="/login/recuperar-senha" exact component={RecoveryPassword} />

      {/* Outras rotas ------------------ */}
      <PrivateRoute path="/selecao-de-ambiente" exact component={AreaSelection} />
      <PrivateRoute path="/perfil-professor" exact component={SignupTeacher} />

      {/* Rotas da área do aluno -------- */}
      <PrivateRoute path="/" exact component={Home} />
      <PrivateRoute path="/curso/:slug/" exact component={Course} />
      <PrivateRoute path="/certificados/" exact component={Certificates} />
      <PrivateRoute path="/perfil/" exact component={Profile} />
      <PrivateRoute path="/perfil/trocar-senha" exact component={ChangePassword} />
      <PrivateRoute path="/suporte" exact component={Support} />
      <PrivateRoute path="/historico-compras" exact component={PurchasesHistoric} />
      <PrivateRoute path="/historico-compras/:idHistorico" exact component={PurchasesHistoric} />
      <PrivateRoute path="/avaliar-curso" exact component={CourseAvaliation} />

      {/* Rotas da área do professor -------- */}
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}`} exact component={HomeTeacher} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/cursos`} exact component={CoursesTeacher} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/certificados`} exact component={CertificatesTeacher} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/relatorios-pagamentos`} exact component={ReportsPayments} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/dados-para-pagamento`} exact component={DataPayments} />

      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/curso`} exact component={CourseMaintenance} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/curso/404`} exact component={CourseNotFount} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/curso/:idCourse`} exact component={CourseMaintenance} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/curso/:idCourse/modulo`} exact component={ModuleMaintenance} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/curso/:idCourse/modulo/:idModule`} exact component={ModuleMaintenance} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/curso/:idCourse/modulo/:idModule/aula`} exact component={ClassMaintenance} />
      <PrivateRoute path={`${BASE_ALIAS_TEACHER}/curso/:idCourse/modulo/:idModule/aula/:idClass`} exact component={ClassMaintenance} />

      <PrivateRoute component={NoPageFound} />
    </Switch>
  )
}

export default Routes