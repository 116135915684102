import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Section = styled.section`
  width: 100%;
  margin: 60px 0;
  text-align: center;

  ${media.sm}{
    text-align: left;
  }

  h2.tts{
    color: ${color.primary};
  }

  p{
    padding: 0 15px;

    &.msg-curso-marcelo{
      padding: 0;
      width: 410px;
      margin: 0 auto;
      margin-bottom: 15px;

      ${media.sm}{
        max-width: 100%;
        margin: 0;
        margin-bottom: 15px;
      }
    }
  }

  
  a.see-more{
    vertical-align: middle;
    color: #FFFFFF;
    margin-left: 15px;
    height: 227px;
    width: 270px;
    max-width: 100%;
    font-size: 20px;
  }
`