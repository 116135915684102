import styled from 'styled-components'
import ReactQuill from 'react-quill';

export const Editor = styled(ReactQuill)`
  width: 100%;
  border: 1px solid #707070;
  border-radius: 8px;
  outline: none;
  
  .ql-editor{
    min-height: 127px;
  }
  .ql-toolbar.ql-snow{
    border: none;
    border-bottom: 1px solid #ccc;
  }
  .ql-container.ql-snow{
    border: none;
  }
`