import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Section = styled.section`
  max-width: 100%;
  width: 543px;
  margin: 60px 0;
  text-align: center;

  ${media.sm}{
    text-align: left;
  }

  h2{
    color: ${color.primary};
  }

`