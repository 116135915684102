import React, { useContext, useEffect, useRef, useState } from 'react';
import BankAccountValidator from "br-bank-account-validator";

// Context
import { AuthContext } from '~/hooks/auth'

// styles
import { WrapForm, OpenRegulamento, WrapTwoField } from './style';

// components
import Input from '~/components/form/Input';
import Label from '~/components/form/Label';
import SubLabel from '~/components/form/SubLabel';
import Select from '~/components/form/Select';
import InputCheckbox from '~/components/form/InputCheckbox';
import MsgResponse from '~/components/MsgResponse';
import LightboxIframe from '~/components/LightboxIframe'

// arrays
import banks from '~/services/arr-banks'
import Button from '~/components/form/Button';

// rest
import { useGet, usePost, usePut } from '~/services/rest';
import { clear_mask, cnpj_validation, cpf_validation, createCookie } from '~/services/functions';

// conf
import { TERMS_TEACHER_NAME } from '~/services/conf'

const FormDataPayments = ({ setFormSwitch }) => {

  const auth = useContext(AuthContext);

  const regulameto = require('~/assets/regulamentos/' + TERMS_TEACHER_NAME)
  const versionTerms = TERMS_TEACHER_NAME;

  const agenciaDvRef = useRef()
  const contaDvReg = useRef()

  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [paymentData, setPaymentData] = useState({
    doc: '',
    doc_tipo: '',
    banco: '',
    banco_id: '',
    agencia: '',
    agencia_dv: '',
    conta: '',
    conta_dv: '',
    termos: '',
  })

  useEffect(() => {
    if (auth.user) {
      setPaymentData(
        p => ({
          ...p,
          doc: auth.user.doc,
          doc_tipo: auth.user.doc_tipo
        })
      )
    }
  }, [auth.user])

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  // handlers ---------
  const handleFormFill = field => (evt, type) => {


    if (field === 'banco_id') {

      let index = evt.nativeEvent.target.selectedIndex;

      setPaymentData({
        ...paymentData,
        [field]: evt.target.value,
        banco: evt.nativeEvent.target[index].text
      })
    } else if (field === 'termos') {
      setPaymentData({
        ...paymentData,
        [field]: evt.target.checked ? versionTerms : ''
      })
    } else if (field === 'doc') {
      setPaymentData({
        ...paymentData,
        [field]: evt.target.value,
        doc_tipo: type.toLowerCase(),
      })
    } else if (field === 'conta' || field === 'agencia') {
      // da split nos traços que vierem no conteudo para jogar para o campo de DV de cada um
      const valSplit = evt.target.value.split('-')
      if (field === 'agencia' && valSplit[1] !== undefined) agenciaDvRef.current.focus()
      if (field === 'conta' && valSplit[1] !== undefined) contaDvReg.current.focus()
      setPaymentData({
        ...paymentData,
        [field]: valSplit[0],
        [field + '_dv']: valSplit[1] ? valSplit[1] : paymentData[field + '_dv'],
      })

    } else {
      setPaymentData({
        ...paymentData,
        [field]: evt.target.value
      })
    }
  }

  const handleUpdate = async () => {

    setError(null)

    if (
      !paymentData.doc ||
      !paymentData.banco ||
      !paymentData.banco_id ||
      !paymentData.agencia ||
      !paymentData.conta ||
      !paymentData.conta_dv ||
      !paymentData.termos
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    try {
      BankAccountValidator.validate({
        bankNumber: paymentData.banco_id,
        agencyNumber: paymentData.agencia,
        agencyCheckNumber: paymentData.agencia_dv,
        accountNumber: paymentData.conta,
        accountCheckNumber: paymentData.conta_dv,
      });
      // Se chegou até aqui, a conta bancária é válida

    } catch (e) {
      // se não for válida, lança uma exceção
      setError(e.message)
      return false
    }

    // valida cpf
    if (paymentData.doc_tipo === 'cpf' && !cpf_validation(clear_mask(paymentData.doc))) {
      setError('CPF inválido!')
      return false
    }
    // valida cnpj
    if (paymentData.doc_tipo === 'cnpj' && !cnpj_validation(clear_mask(paymentData.doc))) {
      setError('CNPJ inválido!')
      return false
    }

    const dataSendBanco = {
      banco: paymentData.banco,
      banco_id: paymentData.banco_id,
      agencia: paymentData.agencia,
      agencia_dv: paymentData.agencia_dv,
      conta: paymentData.conta,
      conta_dv: paymentData.conta_dv,
    }
    const dataSendUser = {
      doc: clear_mask(paymentData.doc),
      doc_tipo: paymentData.doc_tipo,
      termos_aceite_professor: 1,
      v_termos_aceite_professor: paymentData.termos,
    }

    // deleta campo nao obrigatório
    if (!dataSendBanco.agencia_dv) delete dataSendBanco.agencia_dv

    await changeUserProfile(dataSendUser)

    if (auth.user.perfis_pagamento_id) {
      await changePaymentProfile(dataSendBanco)
    } else {
      await createPaymentProfile(dataSendBanco)
    }
  }

  const indexForOptionsSelectBank = {
    value: 'code',
    label: 'name'
  }

  // api ----
  // GET USUARIO -------------------------------
  const paymentProfileUser = useGet(auth.user.perfis_pagamento_id ? `perfispagamento/${auth.user.perfis_pagamento_id}` : false)

  useEffect(() => {

    if (paymentProfileUser.success && paymentProfileUser.data.length > 0) {

      if (setFormSwitch) setFormSwitch('formLogo');

      setPaymentData(
        p => ({
          ...p,
          conta: paymentProfileUser.data[0].conta || '',
          conta_dv: paymentProfileUser.data[0].conta_dv || '',
          agencia: paymentProfileUser.data[0].agencia || '',
          agencia_dv: paymentProfileUser.data[0].agencia_dv || '',
          banco: paymentProfileUser.data[0].banco || '',
          banco_id: paymentProfileUser.data[0].banco_id || '',
        })
      )
    }
  }, [paymentProfileUser.data, paymentProfileUser.success, setFormSwitch])

  // PUT USUARIO  -------------------------------------------
  const [resUserProfile, changeUserProfile] = usePut(`usuarios`) // atualiza dados do aluno

  // PUT PERFIL PAGAMENTO -------------------------------
  const [resChangePaymentProfile, changePaymentProfile] = usePut(`perfispagamento/${auth.user.perfis_pagamento_id}`)

  // POST PERFIL PAGAMENTO -------------------------------
  const [resCreatePaymentProfile, createPaymentProfile] = usePost(`perfispagamento`)

  // use effet results ----------------------------
  // resposta criando perfil de pagamento -------------------------
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resCreatePaymentProfile.success && (resCreatePaymentProfile.data || resCreatePaymentProfile.data.length > 0)) {
      setSuccess('Perfil de pagamento criado! Por favor aguarde...')

      if (resUserProfile.success && (resUserProfile.data || resUserProfile.data.length > 0)) {
        resUserProfile.data.usuarioUpdate.perfis_pagamento_id = resCreatePaymentProfile.data.perfis_pagamento.idperfis_pagamento
        resUserProfile.data.usuarioUpdate.usuarios_tipos_id = 4
      }

      // atualiza cookie de usuário
      createCookie('@InovaOnline:user', JSON.stringify(resUserProfile.data.usuarioUpdate))
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    } else {
      setError(resCreatePaymentProfile.error)
    }
  }, [resCreatePaymentProfile, resUserProfile])

  // resposta atualizando perfil de pagamento -------------------------
  useEffect(() => {
    setError('')
    setSuccess('')

    if (resChangePaymentProfile.success && (resChangePaymentProfile.data || resChangePaymentProfile.data.length > 0)) {
      setSuccess('Perfil de pagamento atualizado! Atualizando a página, aguarde...')

      if (resUserProfile.success && (resUserProfile.data || resUserProfile.data.length > 0)) {
        resUserProfile.data.usuarioUpdate.perfis_pagamento_id = resChangePaymentProfile.data.perfis_pagamento.idperfis_pagamento
      }

      // atualiza cookie de usuário
      createCookie('@InovaOnline:user', JSON.stringify(resUserProfile.data.usuarioUpdate))

      setTimeout(() => {
        window.location.reload();
      }, 2000);

    } else {
      setError(resChangePaymentProfile.error)
    }

  }, [resChangePaymentProfile, resUserProfile])


  return (
    <WrapForm>
      <Label htmlFor='fm_doc'>CPF ou CNPJ:</Label>
      <SubLabel>Informação obrigatória para recebimentos no Brasil.</SubLabel>
      <Input cpfCnpj value={paymentData.doc} onChange={handleFormFill('doc')} id='fm_doc' />

      <Label htmlFor='fm_banco'>Selecione o banco:</Label>
      <Select
        id='fm_banco'
        data={banks}
        indexOption={indexForOptionsSelectBank}
        onChange={handleFormFill('banco_id')}
        value={paymentData.banco_id}
      />

      <Label htmlFor='fm_agencia'>Agência:</Label>
      <SubLabel>Digito verificador não obrigatório para alguns bancos.</SubLabel>
      <WrapTwoField>
        <div className="item item-left col-xs-9">
          <Input value={paymentData.agencia} noGroup onChange={handleFormFill('agencia')} id='fm_agencia' />
        </div>
        <div>-</div>
        <div className="item item-right col-xs-3">
          <Input innerRef={agenciaDvRef} value={paymentData.agencia_dv} noGroup onChange={handleFormFill('agencia_dv')} />
        </div>
      </WrapTwoField>

      <Label htmlFor='fm_conta'>Conta:</Label>
      <SubLabel>Informe o digito verificador da conta.</SubLabel>
      <WrapTwoField>
        <div className="item item-left col-xs-9">
          <Input value={paymentData.conta} noGroup onChange={handleFormFill('conta')} id='fm_conta' />
        </div>
        <div>-</div>
        <div className="item item-right col-xs-3">
          <Input innerRef={contaDvReg} value={paymentData.conta_dv} noGroup onChange={handleFormFill('conta_dv')} />
        </div>
      </WrapTwoField>

      <OpenRegulamento onClick={() => setLightboxOpen(true)}>Ler Termos de uso</OpenRegulamento>
      <InputCheckbox
        label="Li e aceito os termos de uso da Plataforma Inova Online."
        value="termos v2"
        onChange={handleFormFill('termos')} />

      {
        resCreatePaymentProfile.loading || resUserProfile.loading || resChangePaymentProfile.loading
          ?
          <MsgResponse msg='Enviando...' width="100%" />
          :
          resCreatePaymentProfile.success || resUserProfile.success || resChangePaymentProfile.success
            ?
            <></>
            :
            auth.user.perfis_pagamento_id
              ?
              <Button onClick={() => handleUpdate()}>Atualizar</Button>
              :
              <Button onClick={() => handleUpdate()} style={{ float: 'right' }}>Avançar</Button>
      }

      <div className="clearfix"></div>

      {error && <MsgResponse msg={error} theme="error" />}
      {success && <MsgResponse msg={success} theme="success" />}

      <LightboxIframe open={lightboxOpen} iframe={regulameto} closeHandler={setLightboxOpen} />
    </WrapForm>
  )
}
export default FormDataPayments