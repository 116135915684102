import styled from 'styled-components'
import { media } from '~/styles/variables'

export const Container = styled.div`
  width: 100%;
  text-align: center;

  ${media.sm}{
    text-align: left;
  }
`

export const WrapBaseCertificate = styled.div`
  display: inline-block;
  text-align: center;
  position: relative;

  .wrap-content-certificate{
    display: flex;
    justify-content: space-between;
    
    .left-detail, .right-detail{
      width: 6.55%;
      background-position: top left;
      background-size: contain;
    }
  }

  .box-content{
    padding: 5% 0 3% 0;
    width: 64%;
    display: flex;
    justify-content: center;
    align-items: center;

    .col-logo{
      flex: 1;
    }
    .col-desc{
      flex: 1.5;
      padding-left: 15px;
    }
  }
`
export const InitialsName = styled.div`
  text-align: center;
  font-weight: 800;
  color: #21211f;
  text-transform: uppercase;
  font-size: ${(5 * 2.2)}vw;

  ${media.sm}{
    font-size: ${(5 * 1.6)}vw;
  }
  ${media.md}{
    font-size: ${(5 * 1.8)}vw;
  }
  
  ${media.xl}{
    font-size: 5vw;
  }
`
export const ContentItem = styled.div`
  margin-bottom: .8vw;
`
export const LabelC = styled.span`
  display: block;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0.1vw;
  color: #5a5a5a;
  font-size: ${(0.73 * 2.2)}vw;

  ${media.sm}{
    font-size: ${(0.73 * 1.6)}vw;
}
  ${media.md}{
    font-size: ${(0.73 * 1.8)}vw;
  }
  
  ${media.xl}{
    font-size: 0.73vw;
  }
`
export const TitleC = styled.h2`
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
  margin-bottom: 0;
  color: #5a5a5a !important;
  font-size: ${(1.7 * 2.2)}vw;
  text-transform: uppercase;

  ${media.sm}{
    font-size: ${(1.7 * 1.6)}vw;
  }
  ${media.md}{
    font-size: ${(1.7 * 1.8)}vw;
  }
  
  ${media.xl}{
    font-size: 1.7vw;
  }
`
export const SubTitleC = styled.h3`
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
  margin-bottom: 0;
  color: #5a5a5a;
  font-size: ${(1.2 * 2.2)}vw;
  text-transform: uppercase;

  ${media.sm}{
    font-size: ${(1.2 * 1.6)}vw;
}
  ${media.md}{
    font-size: ${(1.2 * 1.8)}vw;
  }
  
  ${media.xl}{
    font-size: 1.2vw;
  }
`