import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// components
import MenuListActions from '~/components/MenuListActions'
import ItemListActions from '~/components/MenuListActions/Item'

// styles
import { Legend, Section, WrapTitle } from './style';

// rest
import { useDelete, useGet, usePut } from '~/services/rest'

// icons
import { BsPencilSquare, BsBackspace } from 'react-icons/bs'
import { LoadingMenuListActions } from '~/components/Loading';
import { stripSlashes } from '~/services/functions';
import MsgResponse from '~/components/MsgResponse';

const initialStateEditForm = {
  id: '',
  title: '',
}

const ListMaterialMaintenance = ({ refetch, numFilesUploading }) => {

  // parametros de rota
  const { idClass } = useParams()

  // controle de id para atualizar e deletar
  const [currentFile, setCurrentFile] = useState(initialStateEditForm)

  // controle de mensagens de resposta
  const [error, setError] = useState('')

  // controle de requisições
  const dataMaterial = useGet(idClass ? `aulasarquivos/aula/${idClass}` : false)
  const [resUpdate, update] = usePut(`aulasarquivos/${currentFile.id}`)
  const [, deleteFile] = useDelete()

  // deleta arquivo e atualiza lista
  const handleDelete = async (idFile) => {
    await deleteFile(`aulasarquivos/${idFile}`)
    dataMaterial.refetch(dataMaterial.refetchCancelToken)
  }

  // deleta arquivo e atualiza lista
  const handleEdit = async () => {

    if (currentFile.title === '') {
      setCurrentFile(initialStateEditForm)
      return false;
    }

    const dataSend = {
      idaulas_arquivos: currentFile.id,
      titulo: currentFile.title,
    }

    await update(dataSend)
    dataMaterial.refetch(dataMaterial.refetchCancelToken)
    setCurrentFile(initialStateEditForm)
  }

  // RESPONSE UPDATE  -----
  useEffect(() => {
    setError('')
    if (resUpdate.error) {
      setError(resUpdate.error)
    }
  }, [resUpdate])

  // REFEATCH LIST
  useEffect(() => {
    if (refetch.do) {
      refetch.set(false)
      dataMaterial.refetch(dataMaterial.refetchCancelToken)
    }
  }, [refetch, dataMaterial])

  return (
    <Section>
      <WrapTitle>
        <h3>Clique no nome para visualizar:</h3>
        <div>
          <Legend><BsPencilSquare /> Editar nome</Legend>
          <Legend><BsBackspace /> Excluir</Legend>
        </div>
      </WrapTitle>

      {error && <MsgResponse msg={error} theme="error" />}

      {
        numFilesUploading > 0 &&
        <LoadingMenuListActions num={numFilesUploading} />
      }
      {
        // dataMaterial.loading ? <LoadingMenuListActions /> :
        dataMaterial.data?.aulasArquivo && dataMaterial.data.aulasArquivo.length > 0 ?
          <MenuListActions>
            {
              dataMaterial.data.aulasArquivo.map(
                material => (
                  <ItemListActions
                    key={material.idaulas_arquivos}
                    id={material.idaulas_arquivos}
                    link={material.arquivo_url}
                    title={stripSlashes(material.titulo || material.arquivo)}
                    idCurrent={currentFile.id}
                    titleCurrent={currentFile.title}
                    buttonDelete={handleDelete}
                    buttonEdit={setCurrentFile}
                    handleEdit={handleEdit} />
                )
              )
            }
          </MenuListActions>
          :
          <p className="msg-no-files">Seus arquivos aparecerão aqui</p>
      }
    </Section>
  )
}
export default ListMaterialMaintenance