import React from 'react'

// style
import { Container } from './style'

const Menu = ({ children }) => {

  return (
    <Container>
      {children}
    </Container>
  )
}
export default Menu