import React from 'react'

// style
import { Container, Logo, Box } from './style'

// internal component
import MaterialMenu from './MaterialMenu'
import MaterialItem from './MaterialMenu/MaterialItem'

// components
import { LoadingMaterialList } from '~/components/Loading'
import NextClassOrTest from '~/components/NextClassOrTest'

// images
import logo from '~/assets/logo/inova-online.v2.svg'

// rest
import { useGet } from '~/services/rest'

const RightSidebar = ({ idHistoricClass, type, testSended, handleTestContent, handleClassContent, setUpdateCourseSidebar, minPercentagePlayed }) => {

  const classContent = useGet(idHistoricClass ? `historicoaulas/${idHistoricClass}` : false)

  return (
    <Container>
      <Logo src={logo} alt="Inova Online" width={177} />

      {
        type === 'class' &&
        <Box>
          {classContent.data === 0 || classContent.loading
            ?
            <LoadingMaterialList num={3} />
            :
            classContent.data.length > 0 &&
            classContent.data[0].aulas.aulas_arquivos.length > 0 &&
            <MaterialMenu items={
              classContent.data[0].aulas.aulas_arquivos.map(item => {
                return <MaterialItem key={item.idaulas_arquivos} dataMaterial={item} dataCourse={classContent} />
              })
            } />
          }
          {minPercentagePlayed &&
            <NextClassOrTest
              classData={classContent.data[0]}
              handleClassContent={handleClassContent}
              handleTestContent={handleTestContent}
              setUpdateCourseSidebar={setUpdateCourseSidebar} />
          }
        </Box>
      }

      {type === 'test' && !testSended && classContent.teste_resultado === 0 &&
        <Box>
          <p>É a primeira vez que está realizando este teste.</p>
          <h5><strong>Boa sorte!</strong></h5>
        </Box>
      }

    </Container>
  )
}
export default RightSidebar