import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router'

// style
import { Container } from './style'

// components
import { LoadingCreatingCourseTitle } from '~/components/Loading'
import HeaderUser from '~/components/HeaderUser'

// rest
import { useGet } from '~/services/rest'

// functions
import { stripSlashes } from '~/services/functions'
import { BASE_ALIAS_TEACHER } from '~/services/conf'

const Header = ({ refetch }) => {

  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [titleCourse, setTitleCourse] = useState(true)
  const [courseExist, setCourseExist] = useState(true)

  const courseData = useGet(params?.idCourse ? `cursosprofessores/${params.idCourse}` : false)

  useEffect(() => {
    if (courseData.success && courseData.data) {
      setLoading(false)
      setTitleCourse(`Editando curso: ${courseData.data.curso[0].titulo}`)
    } else if (courseData.error) {
      setLoading(false)
      setCourseExist(false)
    }
  }, [courseData])

  useEffect(() => {
    if (refetch.do) {
      refetch.set(false)
      courseData.refetch(courseData.refetchCancelToken)
    }
  }, [refetch, courseData])

  if (!courseExist) {
    // return <Redirect to={`${BASE_ALIAS_TEACHER}/curso/404`} />
    return <Redirect to={{
      pathname: `${BASE_ALIAS_TEACHER}/curso/404`,
      state: { access: true }
    }} />
  }

  return (
    <Container>
      <HeaderUser />
      {
        params?.idCourse
          ?
          loading
            ?
            <LoadingCreatingCourseTitle />
            :
            <h2>{stripSlashes(titleCourse)}</h2>
          :
          <h2>Novo curso</h2>
      }
    </Container>
  )
}
export default Header