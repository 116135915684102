import styled from 'styled-components'
import { color, media } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const Menu = styled.ul`
  padding: 15px 0;
  margin: 0;
  font-size: 0;
  list-style-type: none;
  counter-reset: item;
`
export const Item = styled.li`
  counter-increment: item;
  position: relative;
  padding-left: 15px;

  ${media.lg}{
    padding-left: 20px;
  }

  .icon-finished{
    position: absolute;
    top: 50%;
    left: 3px;
    ${mixin.transform('translate(0,-50%)')}
  }

  button,a{
    display: inline-block;
    border: none;
    outline: none;
    background: none;
    font-weight: 700;
    color: ${props => props.active ? color.third : props.status === 11 ? '#D5D5D5' : color.second};
    padding: 5px;
    line-height: 1;
    font-size: 12px;
    text-align: left;
    cursor: ${props => props.blocked ? 'not-allowed' : 'pointer'};

    ${media.lg}{
      font-size: 13px;
      padding: 8px;
    }
    ${media.xl}{
      font-size: 14px;
      padding: 10px;
    }

    svg{
      ${media.max_md}{
        width: 13px;
      }
    }

    &:before{
      content: counters(item, ".") ".";
      margin-right: 5px;
    }

    &:hover{
      color: ${color.third}
    }
  }
`