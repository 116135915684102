import styled from 'styled-components'
import { color } from '~/styles/variables'

// // components
import InputRadio from '~/components/form/InputRadio'

// question
export const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
  counter-increment: itemCounter;

  h4{
    font-weight: 700;
    margin-bottom: 25px;
    text-align: left;
    color: ${props => props.answeredQuestion ? color.second : color.errorColor};

    &:before{
      content: counter(itemCounter) ". ";
    }

    >div{
      display: inline;
    }
  }

  .wrap-answers{
    >div{
      &:nth-child(1){
        .circle-check:before{
          content: "A";
        }
      }
      &:nth-child(2){
        .circle-check:before{
          content: "B";
        }
      }
      &:nth-child(3){
        .circle-check:before{
          content: "C";
        }
      }
      &:nth-child(4){
        .circle-check:before{
          content: "D";
        }
      }
      &:nth-child(5){
        .circle-check:before{
          content: "E";
        }
      }
    }
  }
`

// answer item
export const WrapInputAnswered = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  
  >div{
    margin: 0;
  }

  .wrap-correct-answer{
    display: flex;
    align-items: center;

    span{
      color: ${color.successColor};
      font-weight: 700;
      padding-left: 5px;
      padding-right: 15px;
    }

    svg{
      color: ${color.successColor};
      position: relative;
      top: -2px;
    }
  }
`
export const InputAnswered = styled(InputRadio)`
  label{
    cursor: default;
    color: ${props => props.rightAnswer ? color.successColor : props.correct ? color.successColor : props.userAnswer && color.errorColor} !important;
    font-weight: ${props => props.correct && '700'};

    .circle-check {
      background: ${props => props.userAnswer && color.primary} !important;
      border-color: ${props => props.userAnswer && color.primary} !important;
      color: ${props => props.userAnswer && '#FFFFFF'} !important;
    }

    &:hover{
      color: ${color.second};

    .circle-check{
      border-color: #707070;
      color: #707070;
     }
    }
  }
`