import React from 'react'
import { Link } from 'react-router-dom'

// style
import { Item } from './style'

// component
import SubMenu from '../SubMenu'
import SubMenuItem from '../SubMenu/SubItem'

// icons
// import { FaCheckCircle } from 'react-icons/fa'

const ModuleItem = (props) => {

  const { title, subTitle, active, linkActive, link, modules } = props

  const SubMenuItemsComponents = modules.length > 0 ? modules.map((item) => <SubMenuItem key={item.idmodulos} data={item} />) : ''
  const SubMenuComponent = (
    <SubMenu>
      {SubMenuItemsComponents}
    </SubMenu>
  )

  if (linkActive) {
    return (
      <Item active={active}>
        <Link to={link}>
          <span className='text'>
            {title}
            {subTitle && <span className='sub-title'>{subTitle}</span>}
          </span>

          {/* {dataModule.status === 11 && (
            <span className='icon-finished'>
              <FaCheckCircle />
            </span>
          )} */}
        </Link>
        {SubMenuComponent}
      </Item>
    )
  } else {
    return (
      <Item active={active}>
        <button type='button'>
          <span className='text'>
            {title}
            {subTitle && <span className='sub-title'>{subTitle}</span>}
          </span>

          {/* {dataModule.status === 11 && (
            <span className='icon-finished'>
              <FaCheckCircle />
            </span>
          )} */}
        </button>
        {SubMenuComponent}
      </Item>
    )

  }
}
export default ModuleItem