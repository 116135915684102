import styled from 'styled-components'
import { color } from '~/styles/variables'

export const Section = styled.section`
  width: 410px;
  max-width: 100%;
  margin-bottom: 30px;
  
  p.msg-no-files{
    font-size: 12px;
    color: ${color.third};
  }
`
export const WrapTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin-bottom: 10px;

  &.wrap-tests{
    margin-top: 30px;

    h4{
      margin-right: 10px;
    }
  }

  div{
    line-height: 1;
  }
  h3{
    font-size: 14px;
    margin-bottom: 0;
  }

`
export const Legend = styled.span`
  display: inline-flex;
  font-size: 10px;
  color: ${color.third};
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  line-height: 1;

  svg{
    font-size: 15px;
    margin-right: 5px;
  }
`