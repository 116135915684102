import React, { useContext, useEffect, useState } from 'react';

// conf
import { BASE_ALIAS_TEACHER } from '~/services/conf'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { OpenRegulamento, Section } from './style';

// components
import HeaderUser from '~/components/HeaderUser'
import Statistics from '~/components/Statistics'
import CourseItem from '~/components/CourseItem'
import { LoadingCourseItem } from '~/components/Loading'
import Button from '~/components/form/Button'
import HeadButton from '~/components/HeadButton';

// elements
import SectionNoCourse from '~/elements/SectionNoCourse'

// rest
import { useGet } from '~/services/rest'
import { AuthContext } from '~/hooks/auth';
import LightboxAlert from '~/components/LightboxAlert';
import LightboxIframe from '~/components/LightboxIframe';

const HomeTeacher = ({ history }) => {

  const auth = useContext(AuthContext);

  const [lightboxAlert, setLightboxAlert] = useState(false);
  const buttonsLightbox = [
    <Button key={1} onClick={() => handdleCloseModalAlert(true)} width={300}>Preencher mini currículo!</Button>
  ]

  const handdleCloseModalAlert = (redir) => {
    setLightboxAlert(false);

    if (redir) {
      history.push({
        pathname: '/perfil',
        state: {
          theme: 'teacher'
        }
      })
    }
  }

  useEffect(() => {
    if (auth.user) {
      setLightboxAlert(!auth.user.resumo_curriculo ? true : false)
      setRegulamento(auth.user.v_termos_aceite_professor && require(`~/assets/regulamentos/${auth.user.v_termos_aceite_professor}`))
    }
  }, [auth.user])

  const [regulamento, setRegulamento] = useState(null)
  const [lightboxOpen, setLightboxOpen] = useState(false)

  const lsitCourses = useGet('cursosprofessores')
  return (
    <TemplateHome template="teacher">
      <HeaderUser />

      {
        lsitCourses.loading ?
          <LoadingCourseItem num={3} />
          :
          lsitCourses.success
            ?
            <>
              <Section>
                <h2 className="tts">Vendas</h2>
                <Statistics />
                <p className="warning-withdraw">
                  A cada saque é descontado R$ 3,67 do valor disponível.
                </p>
                {
                  regulamento &&
                  <>
                    <p className='warning'>Os valores ficarão disponíveis para saque após 30 dias e os saques ocorrem de forma automática todo 15 dia do mês vigente - conforme descrito nos termos de uso, <OpenRegulamento onClick={() => setLightboxOpen(true)}>clique aqui para ler</OpenRegulamento>.</p>
                    <LightboxIframe open={lightboxOpen} iframe={regulamento} closeHandler={setLightboxOpen} />
                  </>
                }
              </Section>
              <Section>
                <HeadButton>
                  <h2 className="tts">Meus Cursos</h2>
                  <Button link to={`${BASE_ALIAS_TEACHER}/curso`} className="smaller" title="Novo">Novo</Button>
                </HeadButton>

                <div className="row">
                  {
                    lsitCourses.data?.curso.length > 0 && lsitCourses.data.curso.map((item, index) => {
                      if (index <= 6) {
                        return (
                          <CourseItem key={item.idcursos} data={item} type="owner" history={history} />
                        )
                      }
                      return null
                    })
                  }
                </div>
              </Section>
            </>
            :
            <SectionNoCourse />
      }

      {
        lightboxAlert &&
        <LightboxAlert closeLightbox={handdleCloseModalAlert} buttons={buttonsLightbox}>
          <h2 className="tts-alert">Aviso!</h2>
          <p>Olá, estamos felizes com seu acesso na área de professores. Por favor, preencha seu mini currículo na tela de perfil.</p><p><strong>{auth.user.nome}</strong></p>
        </LightboxAlert>
      }

    </TemplateHome>
  )
}
export default HomeTeacher