import React from 'react'

// style
import { Container, Close } from './style'

// image
import close from '~/assets/icons/close.svg'

const LightboxForm = ({ open, closeHandler, children }) => {

  if (open) {
    return (
      <Container>
        <div className="overlay-opacity" onClick={() => closeHandler()}></div>

        <div className="box">
          <Close onClick={() => closeHandler(false)}>
            <img src={close} alt="Fechar" />
          </Close>
          {
            children &&
            <>
              <div className="content">
                {children}
              </div>
            </>
          }
        </div>
      </Container>
    )
  } else {
    return false;
  }
}
export default LightboxForm