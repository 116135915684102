import styled from 'styled-components'
import { media } from '~/styles/variables'

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  ${media.xl}{
    flex-direction: row;
  }

  p{
    &.msg-curso-marcelo{
      margin-top: 30px;
      text-align: left;

      ${media.xl}{
        margin-left: 5%;
        margin-top: 0;
      }
    }
  }
`