import React, { useEffect, useState } from "react";
import moment from "moment";
import 'moment/locale/pt-br'

import IconCalendar from "~/assets/icons/date-range.svg";

// styles
import { Container, WrapContent, WrapSalesList } from "./style";

// components
import ItemStatistic from "~/components/Statistics/ItemStatistic";
import ItemSale from "./ItemSale";
import Label from "../form/Label";
import Input from "../form/Input";
import { LoadingMaterialList, LoadingSquare } from "~/components/Loading/";

// res
import { apiAxio } from "~/services/api";
import { BASE_URL_API } from "~/services/conf";

const SalesPerPeriod = () => {
  const [filterFormDatas, setFilterFormDatas] = useState({
    inicial: moment().subtract(1, 'months').format('L'),
    final: moment().format("L"),
  });

  const [salesPerPeriod, setSalesPerPeriod] = useState("");

  const handleFormFill = (field) => (evt) => {
    setFilterFormDatas({
      ...filterFormDatas,
      [field]: evt.target.value,
    });
  };
  const handleUpdateSales = async () => {
    setSalesPerPeriod("loading");

    let filterDatas = {
      dateFrom: moment().format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    };

    if (moment(filterFormDatas.inicial, "L", true).isValid()) {
      filterDatas.dateFrom = moment(
        filterFormDatas.inicial,
        "L"
      ).format("YYYY-MM-DD");
    }
    if (moment(filterFormDatas.final, "L", true).isValid()) {
      filterDatas.dateTo = moment(filterFormDatas.final, "L").format(
        "YYYY-MM-DD"
      );
    }

    const res = await apiAxio.get(`${BASE_URL_API}relatorios/dashboard/list`, {
      params: filterDatas,
    });

    if (res.data.erro && Object.keys(res.data.erro).length > 0) {
      setSalesPerPeriod("error");
    } else {
      setSalesPerPeriod(res.data);
    }
  };

  useEffect(() => {
    handleUpdateSales()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <h4 className="tts">
        <img src={IconCalendar} alt="Icone Calendário" />
        <span>Vendas por período</span>
      </h4>

      <WrapContent>
        <div className="col-filter">
          <div className="row">
            <div className="col-sm-6">
              <Label htmlFor="fm_dt_inicio">Data inicial:</Label>
              <Input
                value={filterFormDatas.inicial}
                id="fm_dt_inicio"
                onChange={handleFormFill("inicial")}
                onBlur={() => handleUpdateSales()}
                onKeyDown={(e) => e.key === "Enter" && handleUpdateSales()}
                mask="99/99/9999"
              />
            </div>
            <div className="col-sm-6">
              <Label htmlFor="fm_dt_final">Data final:</Label>
              <Input
                value={filterFormDatas.final}
                id="fm_dt_final"
                onChange={handleFormFill("final")}
                onBlur={() => handleUpdateSales()}
                onKeyDown={(e) => e.key === "Enter" && handleUpdateSales()}
                mask="99/99/9999"
              />
            </div>
          </div>

          <WrapSalesList>
            {salesPerPeriod !== "" ? (
              salesPerPeriod === "loading" ? (
                <LoadingMaterialList num={5} />
              ) : salesPerPeriod !== "error" ? (
                salesPerPeriod.relatorios.cursos.length > 0
                  ?
                  salesPerPeriod.relatorios.cursos.map((item, index) => (
                    <ItemSale
                      key={index}
                      desc={`(${item["historico_cursos.idhistorico_cursos"]}) ${item["historico_cursos.cursos.titulo"]}`}
                      value={item["valor_curso"]}
                    />
                  ))
                  :
                  <p>Nenhuma venda registrada neste período!</p>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </WrapSalesList>
        </div>
        <div className="col-resume">
          {salesPerPeriod !== "" ? (
            salesPerPeriod === "loading" ? (
              <LoadingSquare
                style={{ width: 250, marginRight: 30, marginLeft: 30 }}
              />
            ) : salesPerPeriod !== "error" ? (
              <ItemStatistic
                liquidValue={salesPerPeriod.relatorios.total.liquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                grossValue={salesPerPeriod.relatorios.total.bruto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                courseSold={salesPerPeriod.relatorios.total.cursos}
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </WrapContent>
    </Container>
  );
};
export default SalesPerPeriod;
