import React from 'react';

// styles
import { WrapForm } from './style';

// components
import Input from '~/components/form/Input';
import EditorWYSIWYG from '~/components/form/EditorWYSIWYG';
import Label from '~/components/form/Label';
import MsgResponse from '~/components/MsgResponse';
import Button from '~/components/form/Button';
import InputFileVideo from '~/components/form/InputFileVideo'
import Video from '~/components/Video';
import ButtonDelete from '~/components/form/ButtonDelete';
import SubLabel from '~/components/form/SubLabel';

const FormClass = (props) => {

  const {
    handleFormFill,
    handleSend,
    handleDeleteVideo,
    handleDeleteClass,
    deleteVideoStatus,
    data,
    refetchData,
    idClass,
    error,
    success,
    type
  } = props

  return (
    <WrapForm>

      <Label htmlFor='fm_titulo'>Título da aula:</Label>
      <Input value={data.titulo} onChange={handleFormFill('titulo')} id='fm_titulo' />

      {/* <Label htmlFor='fm_subtitulo'>Subtítulo:</Label>
      <Input value={data.subtitulo} onChange={handleFormFill('subtitulo')} id='fm_subtitulo' /> */}

      <Label htmlFor='fm_descricao'>Texto de suporte da aula:</Label>
      <SubLabel>Opcional</SubLabel>
      <EditorWYSIWYG theme="bubble" value={data.descricao} onChange={handleFormFill('descricao')} id='fm_descricao' />

      {
        type === 'update' ?
          data.video_id && !data?.vimeo_upload?.erro
            ?
            <>
              <Label>Vídeo:</Label>
              {
                data?.vimeo_upload?.disponivel
                  ?
                  deleteVideoStatus
                    ?
                    <div className="form-group">
                      <ButtonDelete type="button">Excluindo...</ButtonDelete>
                    </div>
                    :
                    <div className="form-group">
                      <Video idVideo={data.video_id} />
                      <ButtonDelete type="button" onClick={() => handleDeleteVideo()}>Excluir vídeo</ButtonDelete>
                    </div>
                  :
                  <MsgResponse msg='Seu vídeo foi enviado e esta sendo processado! Em breve ele estará disponível nesta área!' theme="success" />
              }
            </>
            :
            <>
              <InputFileVideo id='fm_video' label="Vídeo" rota={`aulas/${idClass}`} refetchData={refetchData}>Vídeos de no máximo 2GB</InputFileVideo>
              {
                data?.vimeo_upload?.erro &&
                <MsgResponse msg='Houve um problema ao processar o último vídeo! Tente novamente por favor!' theme="alert" />
              }
            </>
          :
          <div>
            <InputFileVideo label="Vídeo" disabledUpload>Você deve criar a aula antes de enviar o vídeo</InputFileVideo>
          </div>
      }

      <hr />

      <div className="row">
        <div className="col-xs-6">
          {
            idClass &&
            <ButtonDelete onClick={() => handleDeleteClass()}>Excluir Aula</ButtonDelete>
          }
        </div>
        <div className="col-xs-6">
          <Button className="pull-right" onClick={() => handleSend()}>{type === 'create' ? 'Criar aula' : 'Salvar'}</Button>
        </div>
      </div>

      {error && <MsgResponse msg={error} theme="error" />}
      {success && <MsgResponse msg={success} theme="success" />}

    </WrapForm >
  )
}
export default FormClass