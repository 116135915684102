import React from 'react'

// style
import { Item, WarningIcon } from './style'

// component
import Button from '../form/Button';
import Input from '../form/Input';
import InputGroupAddon from '../form/InputGroupAddon';

// icons
import { BsPencilSquare, BsBackspace, BsCheck, BsGear, BsExclamationTriangle } from 'react-icons/bs'

// functions
import { color } from '~/styles/variables';

const ItemMenuClass = (props) => {

  const {
    id,
    title,
    idCurrent,
    link,
    titleCurrent,
    warning,
    buttonDelete,
    buttonConfig,
    buttonEdit,
    handleEdit,
  } = props

  const theme = {
    bg: color.primary,
    fc: color.second,
    bdc: color.second,
  }

  return (
    <Item>
      {
        buttonConfig &&
        <button type="button" className="action" onClick={() => buttonConfig(id, title)}>
          <div><BsGear color='#2B852B' /></div>
        </button>
      }
      {
        buttonEdit &&
        <button type="button" className="action" onClick={() => buttonEdit({ id, title })}>
          <div><BsPencilSquare color='#00B0EA' /></div>
        </button>
      }
      {
        buttonDelete &&
        <button type="button" className="action" onClick={() => buttonDelete(id)}>
          <div><BsBackspace color='#A00909' /></div>
        </button>
      }
      {
        idCurrent === id
          ?
          <div className="title">
            <InputGroupAddon className="input-group">
              <Input
                noGroup
                value={titleCurrent}
                onChange={e => buttonEdit({ id, title: e.target.value })}
                onKeyDown={e => e.key === 'Enter' && handleEdit()} />
              <Button type="button" theme={theme} onClick={() => handleEdit()}>
                <BsCheck size={20} />
              </Button>
            </InputGroupAddon>
          </div>
          :
          <div className="title">
            {
              link
                ?
                <a href={link} title="Visualizar" target='_blank' rel="noopener noreferrer"><span className="text">{title}</span></a>
                :
                <span className="text">{title}</span>
            }
            {
              warning &&
              <WarningIcon>
                <BsExclamationTriangle color='orange' />
              </WarningIcon>
            }
          </div>
      }
    </Item>
  )

}
export default ItemMenuClass
