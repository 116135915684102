import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;

  input{
    width: 100%;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  button{
    width: auto;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none;
  }
`
