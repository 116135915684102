import React from 'react'
import { TextareaStyled } from './style'

const Textarea = ({ refAttr, ...rest }) => {
  return (
    <div className="form-group">
      <TextareaStyled ref={refAttr} {...rest} />
    </div>
  )
}
export default Textarea