import React from 'react';

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section } from './style';

// components
import HeaderUser from '~/components/HeaderUser'
import CourseItem from '~/components/CourseItem'
import { LoadingCourseItem } from '~/components/Loading'
import Button from '~/components/form/Button'

// rest
import { useGet } from '~/services/rest'

// conf
import { BASE_ALIAS_TEACHER } from '~/services/conf'
import HeadButton from '~/components/HeadButton';
import SectionNoCourse from '~/elements/SectionNoCourse';

const CoursesTeacher = ({ history }) => {

  const lsitCourses = useGet('cursosprofessores')

  return (
    <TemplateHome template="teacher">
      <HeaderUser />

      {
        lsitCourses.loading ?
          <LoadingCourseItem num={3} />
          :
          lsitCourses.success
            ?
            <Section>
              <HeadButton>
                <h2 className="tts">Meus Cursos</h2>
                <Button link to={`${BASE_ALIAS_TEACHER}/curso`} className="smaller" title="Novo">Novo</Button>
              </HeadButton>

              <div className="row">
                {
                  lsitCourses.data?.curso.length > 0 && lsitCourses.data.curso.map((item, index) => {
                    if (index <= 6) {
                      return (
                        <CourseItem key={item.idcursos} data={item} type="owner" history={history} />
                      )
                    }
                    return null
                  })
                }
              </div>
            </Section>
            :
            <SectionNoCourse />
      }

    </TemplateHome>
  )
}
export default CoursesTeacher