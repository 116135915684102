import React, { useState } from 'react'
import Label from '~/components/form/Label'
import { Container, FakeInput, InputFileStyled, AcceptedFileMsg, ProgressBar } from './style'

// upload
import { BASE_URL_API } from '~/services/conf';
import { apiAxio } from '~/services/api';

// upload ---
import FineUploaderTraditional from 'fine-uploader-wrappers'

// ...or load this specific CSS file using a <link> tag in your document
import MsgResponse from '~/components/MsgResponse';

import loadingGif from '~/assets/icons/loading.svg';
import SubLabel from '../SubLabel';

const InputFileVideo = (props) => {

  const [perUpload, setPerUpload] = useState(0)
  const [videoSendedStatus, setVideosSendedStatus] = useState('notsended')
  const [error, setError] = useState(null)

  const {
    label,
    sublabel,
    fileName,
    id,
    accept,
    refetchData,
    children,
    onChange,
    rota,
    disabledUpload,
    ...rest
  } = props

  const uploader = new FineUploaderTraditional({
    options: {
      chunking: {
        enabled: true
      },
      deleteFile: {
        enabled: true,
        endpoint: `${BASE_URL_API + rota}/video/uploads`
      },
      request: {
        endpoint: `${BASE_URL_API + rota}/video/uploads`
      },
      retry: {
        enableAuto: true
      },
      validation: {
        sizeLimit: 31138512896, // 2.5GB
        allowedExtensions: ['mp4', 'mkv', 'mov', 'wmv', 'flv', 'avi', 'avchd', 'webm']
      },
      messages: {
        sizeError: 'O vídeo excedeu o limite de {sizeLimit}.',
        typeError: 'Arquivo não permitido! Extenções válidas: {extensions}',
      },
      callbacks: {
        onSubmit: function () {
          setVideosSendedStatus('upload-chunk')
        },
        onProgress: function (id, name, uploadedBytes, totalBytes) {
          const percentage = Math.round((uploadedBytes * 100) / totalBytes)
          setPerUpload(percentage)
        },
        onError: function (id, name, errorReason) {
          setError(errorReason)
          setVideosSendedStatus('notsended')
        },
        onComplete: async function (id, name, responseJSON, xhr) {
          // uploader.methods.getName(id);
          // const size = uploader.methods.getSize(id);
          // const blobName = uploader.methods.getBlobName(id);

          // success
          // filename
          // filepath
          // path

          if (responseJSON.success) {
            setVideosSendedStatus('upload-vimeo')
            await apiAxio.post(`${BASE_URL_API + rota}/video`, responseJSON);
            setVideosSendedStatus('finished')
          }
        }
      }
    }
  })

  if (videoSendedStatus === 'finished') {
    return (
      <MsgResponse msg='Seu vídeo foi enviado e esta sendo processado! Em breve ele estará disponível nesta área!' theme="success" />
    )
  } else if (videoSendedStatus === 'upload-vimeo') {
    return (
      <div style={{ display: 'flex' }}>
        <MsgResponse msg='Processando vídeo, aguarde por favor...' />
        <img src={loadingGif} width={35} alt="Loading" />
      </div>
    )
  } else if (videoSendedStatus === 'upload-chunk') {
    return (
      <>
        <Label>Enviando vídeo...</Label>
        <ProgressBar percentage={perUpload}>
          <span>{perUpload}%</span>
        </ProgressBar>
      </>
    )
  } else {

    return (
      <>
        <Container disabled={disabledUpload}>
          {
            label &&
            <Label htmlFor={id}>{label}</Label>
          }
          {
            sublabel &&
            <SubLabel>{sublabel}</SubLabel>
          }
          <div className="clearfix"></div>
          <div className="form-group">
            <InputFileStyled type="file" id={id} accept="video/*" uploader={uploader} {...rest} disabled={disabledUpload} />
            <FakeInput className="fake-file-input">
              <span className="file-name">{fileName}</span>
              <span className="bt-select">Selecionar</span>
            </FakeInput>
            {
              children &&
              <AcceptedFileMsg>{children}</AcceptedFileMsg>
            }
          </div>
        </Container>
        {error && <MsgResponse msg={error} theme="error" />}
      </>
    )
  }
}

export default InputFileVideo