import { createGlobalStyle } from 'styled-components'
import * as mixin from './mixins'
import { color, media, font } from './variables'
import { darken, lighten } from 'polished'

// grid
import { gridMainContentOffset, gridMainContentCourseOffset, gridMainContent, gridMainContentCreateCourseOffset } from './gridSystem'

export default createGlobalStyle`
  html,
  body {
    height: 100%;
  }
  body {
    font-family: ${font.primary};

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${darken(0.8, color.primary)};
    }

    &.menu-m-active,
    &.lightbox-active {
      overflow: hidden;
    }
  }

  ::-moz-selection {
    color: #FFFFFF;
    background: ${color.second};
  }
  ::selection {
    color: #FFFFFF;
    background: ${color.second};
  }

  .container {
    ${media.xl} {
      width: 1320px;
    }
  }
  .pdd-mobile {
    ${media.max_xs} {
      padding: 0 5%;
    }
  }

  a {
    color: initial;
    outline: none;

    &:focus{
      text-decoration: none;

    }

    &:hover {
      text-decoration: none;
      outline: none;
    }
  } 
  strong{
    font-weight: 700;
  }

  /* content ---- */
  .full-container {
    width: 100%;
    height: 100vh;
    background: #FFFFFF;
  }
  .main-content-offset {
    width: 100%;
    ${gridMainContentOffset}

    ${media.sm}{
      height: 100vh;
    }
  }
  .main-content-offset-course{
    width: 100%;
    ${gridMainContentCourseOffset}

    ${media.sm}{
      height: 100vh;
    }

    .main-content{

      ${media.sm}{
        padding-top: 0;
      }
    }
  }
  .main-content-offset-create-course{
    width: 100%;
    ${gridMainContentCreateCourseOffset}

    ${media.sm}{
      height: 100vh;
    }

    .main-content{

      ${media.sm}{
        padding-top: 6px;
      }
    }
  }
  .main-content{
    ${gridMainContent}
  }
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .form-group{
    position: relative;
  }
  h1 {
    ${mixin.fs_head(40)};
    font-weight: 900;
    text-align: center;
    color: ${color.title};
    margin: 0 0 30px;
  }

  h2 {
    ${mixin.fs_head(30)};
    font-weight: 700;
    text-align: center;
    color: ${color.title};
    margin: 0 0 30px 0;
  }

  h3 {
    ${mixin.fs_head(23)};
    font-weight: 700;
    text-align: center;
    color: ${color.title};
    margin: 0 0 15px;
  }

  h4 {
    ${mixin.fs_head(18)};
    text-align: center;
    color: ${color.title};
    margin: 0 0 15px;
  }

  h5 {
    ${mixin.fs_head(16)};
    text-align: center;
    color: ${color.title};
    margin: 0 0 15px;
  }

  h6 {
    ${mixin.fs_head(15)};
    text-align: center;
    color: ${color.title};
    margin: 0 0 15px;
  }

  p {
    ${mixin.text};
    font-family: ${font.second};
    color: ${color.text};
    text-align: center;
    margin: 0 0 15px;

    ${media.sm} {
      text-align: left;
    }

    a {
      font-weight: 700;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  ${media.sm}{
    h1,h2,h3,h4,h5,h6{
      text-align: left;
    }
  }

  .wrap-txt {
    ul,
    ol {
      padding: 0 0 15px 27px;
      font-size: 0;
      margin: 0;
      margin-bottom: -15px;
      display: inline-block;

      ${media.md} {
        padding: 0 0 15px 40px;
      }

      &.two-col-sm {
        ${media.sm} {
          overflow: hidden;
          padding: 0 0 15px 18px;
          display: flex;
          flex-wrap: wrap;

          li {
            width: 50%;
            padding-right: 30px;
          }
        }
      }
      &.two-col-md {
        ${media.md} {
          overflow: hidden;
          padding: 0 0 15px 18px;
          display: flex;
          flex-wrap: wrap;

          li {
            width: 50%;
            padding-right: 30px;
          }
        }
      }

      &.featured {
        margin-bottom: -20px;
        padding: 0 0 15px 0;

        li {
          font-family: ${font.primary};
          font-size: 16px;
          margin-bottom: 20px;
          list-style: none;
          position: relative;
          padding-right: 15px;
          padding-left: 45px;
          text-align: left;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #959595;
          }
        }
      }

      li {
        ${mixin.text}
        font-family: ${font.second};
        color: ${color.text};
        margin: 0 0 15px 0;
        line-height: 20px;
      }

      + h2,
      + h3 {
        margin-top: 30px;
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 15px;

      ${media.sm} {
        &:not(.aligncenter) {
          margin: 0;
        }
        &.alignright {
          float: right;
          margin: 10px 0px 15px 15px;
        }
        &.alignleft {
          float: left;
          margin: 10px 15px 15px 0px;
        }
      }
    }
  }

  .toolTypeWarning{
    max-width: 200px;
  }
  .fisrt-uppercase{
    text-transform: capitalize;
  }

  @keyframes pulseBgPrimaryColor {
    0% {
      background: ${color.primary};
    }
    50% {
      background: ${lighten(0.15, color.primary)};
    }
    100% {
      background:${color.primary};
    }
  }

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
`;
