import React, { useState, useEffect } from 'react'

// internal components
import NextClassButton from './NextClassButton'

// components
import Button from '~/components/form/Button';

// style
import { AlertRepeatTestTest } from './style'

const NextClassOrTestButton = ({ classData, handleTestContent, handleClassContent, setUpdateCourseSidebar }) => {

  const [needToTest, setNeedToTest] = useState(true)
  const [repeatTest, setRepeatTest] = useState(false)

  // verifica se usuário ja passou no teste se houver
  useEffect(() => {
    if (classData && classData.teste_resultado.length > 0) {
      setRepeatTest(true);
      for (let i = 0; classData.teste_resultado.length > i; i++) {
        if (classData.teste_resultado[i].aprovado) {
          setNeedToTest(false)
          break
        }
      }
    }
  }, [classData])

  if (classData && classData.aulas.habilita_teste && needToTest) {
    return (
      <>
        <div className="desc-content-course">
          <h3 className='fw-300 thirdColor'>Realize agora o teste de aprendizado</h3>
          <p>Agora é necessário que realize um rápido teste para validar seu aprendizado.</p>
          <AlertRepeatTestTest>Será necessário 80% de acerto para conseguir avançar.</AlertRepeatTestTest>
          {repeatTest &&
            <>
              <br />
              <AlertRepeatTestTest>Faça novamente o teste para ser aprovado(a)</AlertRepeatTestTest>
            </>
          }
        </div>
        <div className="desc-right-sidebar">
          <h3 className='fw-700 thirdColor'>Teste de aprendizado</h3>
        </div>
        <Button fullXs width='200px' onClick={() => handleTestContent(classData)}>Iniciar</Button>
      </>
    )
  } else {
    return <NextClassButton
      classData={classData}
      handleClassContent={handleClassContent}
      setUpdateCourseSidebar={setUpdateCourseSidebar}>Avançar</NextClassButton>
  }
}

export default NextClassOrTestButton