import React from 'react';
import { BsPencilSquare } from 'react-icons/bs'
import { useParams } from 'react-router';

// service
import { BASE_ALIAS_TEACHER } from '~/services/conf';

// styles
import { Section } from './style';

//components
import HeadButton from '~/components/HeadButton';
import Button from '~/components/form/Button';
import MenuClasses from './MenuClasses';
import ItemMenuClasses from './MenuClasses/Item';

// loading
import { LoadingMenuItem } from '~/components/Loading';

// rest
import { useGet } from '~/services/rest';
import { stripSlashes } from '~/services/functions';

const ModuleContent = ({ data, editHandler }) => {

  const { idCourse, idModule } = useParams();

  const dataClass = useGet(idModule ? `aulas/modulo/${idModule}` : false)

  return (
    <Section>
      <HeadButton style={{ marginBottom: 15 }}>
        <h3>{stripSlashes(data.titulo)}</h3>
        <Button type="button" className="smaller" onClick={() => editHandler('visible')}>Editar</Button>
      </HeadButton>

      {
        data.subtitulo &&
        <h4>{stripSlashes(data.subtitulo)}</h4>
      }
      {
        data.descricao &&
        <p>{stripSlashes(data.descricao)}</p>
      }

      <hr />

      <div className="wrap-head-new-class">
        <HeadButton style={{ marginBottom: 15 }}>
          <h5>Aulas</h5>
          <Button link to={`${BASE_ALIAS_TEACHER}/curso/${idCourse}/modulo/${idModule}/aula`} className="smaller" onClick={() => editHandler('visible')}>Nova</Button>
        </HeadButton>
        <p>Para editar clique no título da aula ou no ícone editar <BsPencilSquare /></p>
      </div>

      {
        dataClass.loading ?
          <LoadingMenuItem num={3} />
          :
          dataClass.success && dataClass.data.length > 0 ?
            <MenuClasses>
              {dataClass.data.map(itemClass => <ItemMenuClasses key={itemClass.idaulas} data={itemClass} />)}
            </MenuClasses>
            :
            <p className="msg-no-files">Suas aulas aparecerão aqui</p>
      }

    </Section>
  )
}
export default ModuleContent