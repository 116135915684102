import React from 'react'

// style
import { Section } from './style'

// components
import Button from '~/components/form/Button'

// config
import { BASE_ALIAS_TEACHER } from '~/services/conf'
import { color } from '~/styles/variables'

const SectionNoCourse = () => {

  const themeButton = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: color.primary
  }

  return (
    <Section>
      <h2>Publique seu primeiro curso.</h2>
      {/* <p className="msg-curso-marcelo">Caso você queira se preparar antes, <a href="https://inovaol.com/curso/meu-primeiro-curso-na-inova-online" title="clique aqui" target="_blank" rel="noopener noreferrer">clique aqui</a> e faça nosso curso de treinamento gratuitamente.</p> */}
      <p className="msg-curso-marcelo">Caso você nunca tenha elaborado um curso, <a href="https://inovaol.com/meuprimeirocurso" title="clique aqui" target="_blank" rel="noopener noreferrer">clique aqui</a> e faça o curso gratuito do Prof. Marcelo Veras chamado "Meu Primeiro Curso na Inova Online".</p>
      <Button link to={`${BASE_ALIAS_TEACHER}/curso`} title="Começar" theme={themeButton}>Começar</Button>
    </Section>
  )
}
export default SectionNoCourse