import React, { useState, useEffect, useCallback } from 'react'
import { format } from 'date-fns'

// style
import { Container, WrapQuestions, WrapResults, WrapNextClass } from './style'

// internal components
import Questions from './Questions'

// components
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'
import { LoadingTestContent } from '~/components/Loading'
import NextClassButton from '~/components/NextClassOrTest/NextClassButton'

// rest
import { useGet, usePost, usePut } from '~/services/rest'

// functions
import { stripSlashes } from '~/services/functions'

const TestContent = (props) => {

  const {
    idClass,
    idHistoricClass,
    setTestSended,
    classHistoricData,
    handleClassContent,
    setUpdateCourseSidebar
  } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // chamadas api
  const listTest = useGet(`testes/aula/${idClass}`); // pega teste
  const [resAnswerTest, setAnswerTest] = usePost(`historicotesteresposta`) // reponde teste
  const [, setChangeStatus] = usePut(`historicoaulas/${classHistoricData.idhistorico_aulas}`) // atualiza status da aula

  // controle das questões
  const [questions, setQuestions] = useState(null)
  const [questionsValidation, setQuestionsValidation] = useState(null)
  const [validationActive, setValidationActive] = useState(false)
  const [userAnswers, setUserAnswers] = useState({ historico_aulas_id: idHistoricClass, respostas: [] })

  useEffect(() => {
    if (listTest.data.length > 0) {

      setQuestions(listTest.data)

      const listQuestionsValidate = listTest.data.map(item => {
        const newItem = { ...item }
        newItem.answered = false
        return newItem;
      })
      setQuestionsValidation(listQuestionsValidate)
    }
  }, [listTest.data])

  // controle dos resultados de envio
  const [testResult, setTestResult] = useState(null)
  const [msgAlert, setMsgAlert] = useState(false)

  useEffect(() => {
    if (Object.keys(resAnswerTest.data).length > 0) {
      setTestResult({
        percentage: resAnswerTest.data.resultado.resultado,
        approved: resAnswerTest.data.resultado.aprovado
      }) // resposta do post do teste

      // atualiza status da aula para concluído
      // atualiza a sidebarCourse se for aprovado para liberar as aulas
      if (resAnswerTest.data.resultado.aprovado) {

        const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")

        setChangeStatus({
          status: 11,
          concluido_em: now
        })

        setUpdateCourseSidebar(true)
      }
    }
  }, [resAnswerTest])

  // handlers
  const handleUserAnswer = useCallback((idQuestion, idAnswer) => {

    setMsgAlert(false)

    try {
      const newListQuestionValidated = questionsValidation.map(item => {
        const newItem = { ...item }
        if (item.idtestes === idQuestion) {
          newItem.answered = true
        }
        return newItem
      })

      setQuestionsValidation(newListQuestionValidated)
      if (validationActive) setQuestions(newListQuestionValidated)

    } catch (err) {
    }


    // se array estiver vazia ainda só adiciona um item
    if (userAnswers.respostas.length === 0) {
      setUserAnswers({ ...userAnswers, respostas: [{ testes_id: idQuestion, respostas_id: idAnswer }] })
      return false
    }

    // copia array sem as referencias do original
    const copyUserAnswer = JSON.parse(JSON.stringify(userAnswers)) // (código bão)
    const numQuestions = copyUserAnswer.respostas.length

    // foi usado FOR por que não tem como parar o loop do FOREACH
    for (let i = 0; i < numQuestions; i++) {

      // verifica se a array ja possui id dessa pergunta, se sim, altera a resposta e para o loop
      if (copyUserAnswer.respostas[i].testes_id === idQuestion) {
        copyUserAnswer.respostas[i].respostas_id = idAnswer
        break
      }

      if (i === numQuestions - 1) {
        //se chegar no ultimo índice do array e não encontrar o id da pergunta, cria uma nova resposta e inclui na cópia do array
        copyUserAnswer.respostas.push({ testes_id: idQuestion, respostas_id: idAnswer })
      }
    }
    // joga a cópia com os valores atualizados para o estado
    setUserAnswers(copyUserAnswer)
  }, [userAnswers])

  const handlePostAnswers = async () => {

    // verifica se usuário respondeu tudo
    if (userAnswers.respostas.length < questions.length) {
      setQuestions(questionsValidation)
      setValidationActive(true)
      setMsgAlert(true)
      return false
    }

    // cria nova array de perguntas inserindo um novo índice 'resposta_usuario' para a resposta do usuario
    const questionWithAnswers = questions.map(itemQuestion => {
      itemQuestion.respostas.map(itemAnswer => {

        userAnswers.respostas.forEach(itemUserAnswer => {
          // verifica se o id da questao é igual o id da questao que o usuario respondeu
          if (itemAnswer.testes_id === itemUserAnswer.testes_id) {
            // verifica se o id da resposta é igual a que o usuário respondeu e seta true ou false pra essa resposta
            if (itemAnswer.idrespostas === itemUserAnswer.respostas_id) {
              itemAnswer.resposta_usuario = 1
            } else {
              itemAnswer.resposta_usuario = 0
            }
          }
        })

        return itemAnswer
      })

      return itemQuestion
    })

    window.scrollTo(0, 0)
    setQuestions(questionWithAnswers)
    setTestSended(true) // esse state vem da página principal do curso

    await setAnswerTest(userAnswers)
  }

  const handleRemakeTest = () => {
    // reseta valores
    setUserAnswers({ historico_aulas_id: idHistoricClass, respostas: [] })
    setQuestions(null)
    setQuestionsValidation(null)
    setTestResult(null)
    setTestSended(false)
    setValidationActive(false)

    // recarrega perguntas
    listTest.refetch(listTest.refetchCancelToken)
  }

  if (listTest.data.length === 0 || listTest.loading || resAnswerTest.loading) {
    return <LoadingTestContent />
  }

  if (resAnswerTest.error || listTest.error) {
    return (
      <>
        <h2>Ops, algo deu errado!</h2>
        <p><strong>
          {resAnswerTest.error && resAnswerTest.error}
          {listTest.error && listTest.error}
        </strong></p>
        <p>Por gentileza, tente novamente</p>
      </>
    )
  }

  if (questions) {
    return (
      <Container>
        <h2 className='primaryColor'>Teste: {stripSlashes(questions[0].aulas.titulo)} / {stripSlashes(questions[0].aulas.modulos.titulo)}</h2>

        {
          !testResult
            ?
            <div dangerouslySetInnerHTML={{ __html: stripSlashes(questions[0].aulas.testes_descricao) }} />
            :
            !testResult.approved
              ?
              <WrapResults>
                <h4>Você obteve <span>{`${testResult.percentage}%`}</span> de acerto</h4>
                <Button fullXs width='200px' onClick={() => handleRemakeTest()}>Refazer teste</Button>
              </WrapResults>
              :
              <WrapResults>
                <h3>Parabéns!</h3>
                <h4>Você obteve <span>{`${testResult.percentage}%`}</span> de acerto</h4>

                <WrapNextClass>
                  <NextClassButton
                    classData={classHistoricData}
                    handleClassContent={handleClassContent}
                    setUpdateCourseSidebar={setUpdateCourseSidebar}>Avançar</NextClassButton>
                </WrapNextClass>
              </WrapResults>
        }

        <WrapQuestions>
          <Questions questionsData={questions} handleUserAnswer={handleUserAnswer} />
        </WrapQuestions>

        {msgAlert && <MsgResponse msg='Por favor, responda todas as perguntas antes de enviar' theme='error' />}
        {!testResult && <Button fullXs onClick={() => handlePostAnswers()} width='220px'>Enviar minhas respostas</Button>}
      </Container >
    )
  }

  return null
}

export default TestContent