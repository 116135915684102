import styled from 'styled-components'

import { color } from '~/styles/variables'

export const Wrap = styled.div`
  display: block;
  margin: 10px 0;
  opacity: ${props => props.disabled ? .5 : 1};

  label{
    display: inline-flex;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
    margin: 0;
    padding: 10px 0;
    position: relative;

    span{
      &:last-child{
        padding-left: 10px;
        word-wrap: break-word;
        max-width: 100%;
        line-height: 1;
      }
    }

    .circle-check{
      width: 27px;
      height: 27px;
      border: 1px solid #707070;
      color: #707070;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      
      &:before{
        font-weight: 700;
        color: inherit;
        line-height: 1;
        font-size: 13px;
      }
    }

    input:checked + .circle-check {
      background: ${color.primary};
      border-color: ${color.primary};

      &:before{
        color: #FFFFFF;
      }
    }

    input{
      display: none;
    }

    &:hover{
     color: ${props => !props.disabled && color.primary};

     .circle-check{
      border-color: ${color.primary};
      color: ${color.primary};
     }
    }
  }
`