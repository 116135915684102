import styled from 'styled-components'
import { color, font } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const Section = styled.div`

  h3{
     ${mixin.fs_head(30)};
    color: ${color.primary};
    margin-bottom: 0;
  }
  h4{
     ${mixin.fs_head(25)};
    font-weight: 300;
    color: ${color.third};
    margin-bottom: 10px;
  }

  h5{
     ${mixin.fs_head(18)};
    font-weight: 700;
  }

  p.msg-no-files{
    font-size: 12px;
    color: ${color.third};
  }

  .wrap-head-new-class{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;

    p{
      font-family: ${font.second};
      font-size: 10px;
      margin-left: 20px;
      line-height: normal;
      
      svg{
        color: #959595;
        font-size: 14px;
        position: relative;
        top: 2px;
        max-lines: 2px;
      }
    }
  }
`