import React from 'react';

// styles
import { WrapAnswer, WrapForm } from './style';

// components
import Input from '~/components/form/Input';
import Label from '~/components/form/Label';
import MsgResponse from '~/components/MsgResponse';
import InputRadio from '~/components/form/InputRadioAnswerCreate';

// arrays
import Button from '~/components/form/Button';

const FormTestDescription = (props) => {

  const {
    handleSend,
    setDataAnswer1,
    setDataAnswer2,
    setDataAnswer3,
    setDataAnswer4,
    setDataAnswer5,
    dataAnswer1,
    dataAnswer2,
    dataAnswer3,
    dataAnswer4,
    dataAnswer5,
    error,
    success,
    buttonSendStatus,
  } = props

  const handlerRadio = (e, set, data) => {
    // reseta valores para 0
    setDataAnswer1({ ...dataAnswer1, correta: 0 })
    setDataAnswer2({ ...dataAnswer2, correta: 0 })
    setDataAnswer3({ ...dataAnswer3, correta: 0 })
    setDataAnswer4({ ...dataAnswer4, correta: 0 })
    setDataAnswer5({ ...dataAnswer5, correta: 0 })

    //seta 1 para a resposta correta
    if (e.target.checked) {
      set({ ...data, correta: 1 })
    }
  }

  return (
    <WrapForm>

      <Label htmlFor='fm_titulo'>Respostas (selecione uma correta):</Label>

      <WrapAnswer>
        <InputRadio name="correct" onChange={(e) => handlerRadio(e, setDataAnswer1, dataAnswer1)} checked={dataAnswer1.correta === 1} />
        <Input value={dataAnswer1.resposta} noGroup onChange={e => setDataAnswer1({ ...dataAnswer1, resposta: e.target.value })} />
      </WrapAnswer>

      <WrapAnswer>
        <InputRadio name="correct" onChange={(e) => handlerRadio(e, setDataAnswer2, dataAnswer2)} checked={dataAnswer2.correta === 1} />
        <Input value={dataAnswer2.resposta} noGroup onChange={e => setDataAnswer2({ ...dataAnswer2, resposta: e.target.value })} />
      </WrapAnswer>

      <WrapAnswer>
        <InputRadio name="correct" onChange={(e) => handlerRadio(e, setDataAnswer3, dataAnswer3)} checked={dataAnswer3.correta === 1} />
        <Input value={dataAnswer3.resposta} noGroup onChange={e => setDataAnswer3({ ...dataAnswer3, resposta: e.target.value })} />
      </WrapAnswer>

      <WrapAnswer>
        <InputRadio name="correct" onChange={(e) => handlerRadio(e, setDataAnswer4, dataAnswer4)} checked={dataAnswer4.correta === 1} />
        <Input value={dataAnswer4.resposta} noGroup onChange={e => setDataAnswer4({ ...dataAnswer4, resposta: e.target.value })} />
      </WrapAnswer>

      <WrapAnswer>
        <InputRadio name="correct" onChange={(e) => handlerRadio(e, setDataAnswer5, dataAnswer5)} checked={dataAnswer5.correta === 1} />
        <Input value={dataAnswer5.resposta} noGroup onChange={e => setDataAnswer5({ ...dataAnswer5, resposta: e.target.value })} />
      </WrapAnswer>

      <div className="row">
        <div className="col-sm-6">
          {success && <MsgResponse msg={success} theme="success" margin='0' />}
        </div>
        <div className="col-sm-6">
          <div className="text-right">
            {
              !success &&
              <Button onClick={() => handleSend()} disabled={buttonSendStatus}>{buttonSendStatus === 'disabled' ? 'Salvando...' : 'Salvar'}</Button>
            }
          </div>
        </div>
      </div>

      {error && <MsgResponse msg={error} theme="error" />}

    </WrapForm >
  )
}
export default FormTestDescription