import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Container = styled.div`
  .wrap-info{
    margin-bottom: 30px;
    margin-top: -15px;

    ${media.sm}{
      margin-top: -30px;

    }
  }
`
export const Content = styled.div`
  h2{
    color: ${color.primary};
  }
  
`