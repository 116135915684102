import styled from 'styled-components'
import Button from '~/components/form/Button';
import { color, media } from '~/styles/variables'
import { Link } from 'react-router-dom'

export const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;

  ${media.sm}{
    flex-direction: row;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px;
  background: ${props => props.bg ? props.bg : '#FFFFFF'};

  ${media.sm}{
    height: 100vh;
    flex: 1;
    padding: 0 15px;
  }

  h2.subTts{
    font-weight: 300;
    color: ${color.third};
    margin-top: -20px;
  }
`;
export const BackButton = styled(Link)`
  display: inline-flex;
  margin-bottom: 30px;
  color: #FFFFFF !important;
  font-size: 12px;
  font-weight: 700;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  line-height: 1;
  z-index: 2;

  ${media.sm}{
    position: absolute;
    top: 54px;
    left: 46px;
  }

  img{
    font-weight: 400;
    margin-right: 10px
  }

  &:hover{
    text-decoration: underline;
  }
`;
export const Logo = styled.img`
  max-width: 100%;
  
  ${media.max_xs}{
    width: 200px;
  }
`;
export const WrapForm = styled.div`
  width: 410px;
  max-width: 100%;
  margin-top: 36px;
  
  p{
    margin-bottom: 32px;
  }
`;

export const ButtonBigger = styled(Button)`
  height: 57px;
  width: 178px;
  margin-top: 70px;
  float: right;
  font-size: 16px;
  background: ${color.primary};
  border-color: ${color.primary};
`;
